/* eslint-disable no-param-reassign */
import { debounce, isEmpty } from "lodash";
import { persist } from "mobx-persist";
import {
  DEFAULT_DATA,
  MODAL,
  STATUS_ADDED_EXIST,
  STATUS_ADDED_REQUIRED,
  STATUS_ADDED_SUCCESS,
  TAB,
  action,
  formParseHeaders,
  makeObservable,
  observable,
  runInAction,
} from "~/utils";
import RootStore from ".";

export default class AppStore {
  @observable
  paramSelected = "";

  @observable
  modal = MODAL.NULL;

  @persist("object")
  @observable
  headers: Form.Header[] = [];

  @observable
  rules: Form.RuleParams[] = [];

  @persist("object")
  @observable
  data: Partial<Form.Data> = DEFAULT_DATA;

  @persist("object")
  @observable
  params: Partial<Form.ParamsData[]> = [];

  @persist("object")
  @observable
  isBody = true;

  root: typeof RootStore;

  constructor(root: typeof RootStore) {
    this.root = root;
    makeObservable(this);
  }

  @action
  onToggleBody = () => {
    runInAction(() => {
      this.isBody = true;
    });
  };

  onToggleQuery = () => {
    runInAction(() => {
      this.isBody = false;
    });
  };

  @action
  onClearData = () => {
    runInAction(() => {
      this.data = DEFAULT_DATA;
      this.headers = [];
      this.rules = [];
      this.params = [];
    });
  };

  @action
  onChangeDetailsForm = ({ name, value }: Record<string, string>) => {
    runInAction(() => {
      this.data = {
        ...this.data,
        [name]: value,
      };
    });
  };

  @action
  onChangeDetails = (data: Partial<Form.Data>) => {
    runInAction(() => {
      this.data = {
        ...this.data,
        ...data,
      };
    });
  };

  @action
  onRemoveRulesDuplicated = () => {
    this.data = {
      ...this.data,
      rules: this.data.rules
        ?.reduce((save: any, current) => {
          const idx = save.findIndex(
            (sv: any) =>
              sv.name === current.name &&
              sv.config === current.config &&
              sv.reference === current.reference
          );
          if (idx > -1) {
            save[idx].parameters = [
              ...save[idx].parameters,
              ...current.parameters,
            ];
          } else {
            save = [...save, current];
          }
          return save;
        }, [])
        .filter(({ parameters }: any) => parameters.length > 0),
    };
  };

  @action
  onChangeDynamicValueHeaders = ({
    value = "",
    label = "",
    oldLabel = "",
  }: Form.HeaderDynamicParams) => {
    runInAction(() => {
      const idx = this.headers.findIndex((e) => e.label === oldLabel);

      this.headers[idx] = {
        ...this.headers[idx],
        label,
        value,
      };

      if (this.headers.filter((e) => e.label === label).length > 1) {
        const idx = this.headers.findIndex((e) => e.label === label);
        this.headers.splice(idx, 1);
      }

      this.data = {
        ...this.data,
        headers: formParseHeaders(this.headers),
      };
    });
  };

  @action
  onChangeValueHeaders = ({ value = "", label = "" }: Form.RuleParams) => {
    runInAction(() => {
      this.headers = this.headers.map((header) => {
        if (header.label === label) {
          return {
            ...header,
            value,
          };
        }
        return header;
      });
      this.data = {
        ...this.data,
        headers: formParseHeaders(this.headers),
      };
    });
  };

  @action
  onChangeHeaders = ({
    value = "",
    label = "",
    type = "",
  }: Form.RuleParams) => {
    runInAction(() => {
      const headerExist = this.headers.some((e) => e.label === label);

      if (!headerExist) {
        this.headers = [...this.headers, { label, value, type }];
      } else {
        this.headers = this.headers.filter((e) => e.label !== label);
      }
      this.data = {
        ...this.data,
        headers: formParseHeaders(this.headers),
      };
    });
  };

  @action
  onChangeRules = ({ value = "", label = "", editable }: Form.RuleParams) => {
    runInAction(() => {
      const rExist = this.rules.some((e) => e.label === label);

      if (!rExist) {
        this.rules = [
          {
            label,
            value,
            reference: this.isBody ? TAB.BODY : TAB.QUERY,
            expected_code: "400",
            editable,
          },
          ...this.rules,
        ];
      } else {
        this.rules = this.rules.filter((e) => e.label !== label);
      }
    });
  };

  @action
  openModalRulesParams = () => {
    runInAction(() => {
      this.modal = MODAL.RULES_PARAMS;
    });
  };

  @action
  openModalHeaders = () => {
    runInAction(() => {
      this.modal = MODAL.HEADERS;
    });
  };

  @action
  openModalRules = () => {
    runInAction(() => {
      this.modal = MODAL.RULES;
    });
  };

  @action
  onCloseModal = () => {
    runInAction(() => {
      this.modal = MODAL.NULL;
    });
  };

  @action
  setParamSelected = (param: string) => {
    runInAction(() => {
      this.paramSelected = param;
    });
  };

  @action
  onUpdateRules = (data: Form.RuleParams) => {
    runInAction(() => {
      this.rules = this.rules.map((rule) => {
        if (rule.label === data.label) {
          return {
            ...rule,
            ...data,
            expected_code: data.expected_code,
          };
        }
        return rule;
      });
    });
  };

  @action
  onUpdateValueRules = (data: Form.UpdateRulesValueInParam) => {
    const ref = this.isBody ? TAB.BODY : TAB.QUERY;
    runInAction(() => {
      this.params = this.params.map((param): any => ({
        ...param,
        rules: param?.rules?.map((rule) => {
          if (rule.label === data.label && param?.param === data.param) {
            return {
              ...rule,
              expected_code: data.expected_code,
              value: data.value,
            };
          }
          return rule;
        }),
      }));

      const amountParams = this.data
        .rules!.filter(
          (rl) => rl.name === data.label && rl.parameters.includes(data.param)
        )
        .reduce((count, rule) => count + rule.parameters.length, 0);

      if (amountParams > 0) {
        this.data = {
          ...this.data,
          rules: this.data.rules
            ?.map((rule) => {
              if (
                rule.name === data.label &&
                rule.parameters.includes(data.param)
              ) {
                return {
                  ...rule,
                  reference: ref,
                  parameters: rule.parameters.filter(
                    (param) => param !== data.param
                  ),
                };
              }

              return rule;
            })
            .filter(({ parameters }) => parameters.length > 0),
        };

        this.data = {
          ...this.data,
          rules: [
            {
              name: data.label,
              config: data.value,
              reference: ref,
              expected_code: data.expected_code || "400",
              parameters: [data.param],
            },
            ...this.data.rules!,
          ],
        };
      }

      this.onRemoveRulesDuplicated();
    });
  };

  @action
  onAddRulesToParamsAdded = ({
    label,
    value,
    editable,
  }: Form.ParamsAddedRule) => {
    const ref = this.isBody ? TAB.BODY : TAB.QUERY;

    this.params = this.params.map((param) => {
      // filter by name param
      if (param?.param === this.paramSelected) {
        // filter by rule in param
        if (
          param?.rules.some((p) => p.label === label && p.reference === ref)
        ) {
          return {
            ...param,
            rules: param.rules.filter((p) => p.label !== label),
          };
        }

        return {
          ...param,
          rules: [
            { label, value, expected_code: "400", reference: ref, editable },
            ...param!.rules,
          ],
        };
      }
      return param;
    });

    if (
      this.data.rules?.some(
        (r) =>
          r.name === label &&
          r.parameters.includes(this.paramSelected) &&
          r.reference === ref
      )
    ) {
      const newRules = this.data.rules
        ?.map((rl) => {
          if (rl.name === label) {
            if (
              rl.parameters.includes(this.paramSelected) &&
              rl.reference === ref
            ) {
              return {
                ...rl,
                parameters: rl.parameters.filter(
                  (p: string) => p !== this.paramSelected
                ),
              };
            }
            return {
              ...rl,
              reference: rl.reference || ref,
              expected_code: rl.expected_code || "400",
              parameters: [...rl.parameters, this.paramSelected],
            };
          }
          return rl;
        })
        .filter(({ parameters }) => parameters.length > 0);

      runInAction(() => {
        this.data = {
          ...this.data,
          rules: newRules,
        };
      });
    } else {
      runInAction(() => {
        this.data = {
          ...this.data,
          rules: [
            {
              name: label,
              config: value,
              reference: ref,
              expected_code: "400",
              parameters: [this.paramSelected],
            },
            ...this.data.rules!,
          ],
        };
      });
    }

    this.onRemoveRulesDuplicated();
  };

  @action
  onRemoveRulesAddedByLabel = (data: Omit<Form.RemoveParamsAdded, "value">) => {
    runInAction(() => {
      this.params = this.params.map((p) => {
        if (p?.param === data.param) {
          return {
            ...p,
            rules: p?.rules?.filter((r) => r?.label !== data.label),
          };
        }
        return p;
      });

      const newRules = this.data.rules
        ?.map((rl) => {
          if (rl.name === data.label) {
            return {
              ...rl,
              parameters: rl.parameters.filter((p: string) => p !== data.param),
            };
          }
          return rl;
        })
        .filter(({ parameters }) => parameters.length > 0);

      this.data = {
        ...this.data,
        rules: newRules,
      };
    });
  };

  @action
  onRemoveParamAdded = (data: Omit<Form.ParamsAddedData, "value">) => {
    const objectOrigin = this.isBody ? this.data.body : this.data.query;

    const newData = Object.keys(objectOrigin!).reduce((acc: any, key: any) => {
      if (key !== data.param) {
        acc[key] = objectOrigin![key];
      }
      return acc;
    }, {});

    const ref = this.isBody ? TAB.BODY : TAB.QUERY;

    const newRules = this.data.rules
      ?.map((rl) => {
        if (rl.parameters.includes(data.param) && rl.reference === ref) {
          return {
            ...rl,
            parameters: rl.parameters.filter((p: string) => p !== data.param),
          };
        }

        return rl;
      })
      .filter(({ parameters }) => parameters.length > 0);

    runInAction(() => {
      this.params = this.params.filter((e) => e?.param !== data.param);

      const newBody = this.isBody ? { body: newData } : { query: newData };
      this.data = {
        ...this.data,
        ...newBody,
        rules: newRules,
      };
    });
  };

  @action
  onChangeParamsAdded = (data: Form.ParamsAddedData) => {
    if (!data.param) return;

    runInAction(() => {
      this.params = this.params.map((e: any) => {
        if (e?.param === data.param) {
          return {
            ...e,
            value: data.value!,
          };
        }
        return e;
      });

      // replace value in body
      this.data = {
        ...this.data,
        body: {
          ...this.data.body,
          [data.param]: data.value,
        },
      };
    });
  };

  @action
  onDownload = async () => {
    if (!this.root.app.link) return;
    window.open(this.root.app.link, "_blank");
  };

  @action
  onAddParams = ({
    param,
    type,
    value,
  }: Form.AddParams): Form.ResponseNewField => {
    const ref = this.isBody ? TAB.BODY : TAB.QUERY;

    if (isEmpty(param) || isEmpty(value) || isEmpty(type)) {
      return STATUS_ADDED_REQUIRED;
    }

    const exists = this.params.some((p: any) => p.param === param);
    if (exists) return STATUS_ADDED_EXIST;

    runInAction(() => {
      this.params = [
        {
          param,
          value,
          type,
          rules: this.rules,
          isBody: this.isBody,
        },
        ...this.params,
      ];
    });

    // Verifica se a regra nova ja existe no json final
    const rExist = this.data.rules?.some(
      (dt) =>
        this.rules.some(
          (rl) => rl.label === dt.name && rl.value === dt.config
        ) && dt.reference === ref
    );

    if (rExist) {
      const rList = this.data.rules?.map((dt) => {
        // Verifica se a regra nova ja existe no json final
        if (
          this.rules.some(
            (rl) =>
              rl.label === dt.name &&
              rl.value === dt.config &&
              dt.reference === ref
          )
        ) {
          return {
            ...dt,
            parameters: [param, ...dt.parameters],
          };
        }
        return dt;
      });

      runInAction(() => {
        this.data = {
          ...this.data,
          rules: rList,
        };
      });
    } else {
      const rList = this.rules.map((rl) => ({
        name: rl.label,
        config: rl.value,
        reference: rl.reference, // TODO: REFERENCE
        expected_code: rl.expected_code || "400",
        parameters: [param],
      }));

      runInAction(() => {
        this.data = {
          ...this.data,
          rules: [...rList, ...(this.data.rules || [])],
        };
      });
    }

    runInAction(() => {
      const {
        body = {},
        URL,
        headers,
        method,
        name,
        rules,
        query = {},
      } = this.data;

      const object = this.isBody
        ? { body: { ...body, [param]: value }, query }
        : { body, query: { ...query, [param]: value } };

      this.data = {
        name,
        method,
        URL,
        headers,
        ...object,
        rules,
      };

      this.rules = [];
    });
    return STATUS_ADDED_SUCCESS;
  };
}
