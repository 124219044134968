export const colors = {
  failure: "#F1948A",
  warning: "#F8C471",
  info: "#59ADFE",
  success: "#7DCEA0",

  colors: {
    primary: "#F58220",
    secondary: "#081E38",
    light: "#E4E3E3",
    dark: "#333333",
    contrast: "#183E62",
    // ..
    blacks: "#34495E",
    purple: "#8E44AD",
    greenPool: "#2980B9",
    grayLight: "#D6D7DC",
    disabled: "#BDC3C7",
    roxy: "#50578E",
    grayBg: "#345879",
    primeblue: '#183E62',
  },

  background: {
    primary: "#FD784E",
    secondary: "#252948",
    light: "#fff",
    dark: "#333333",
  },

  text: {
    light: "#fff",
    dark: "#333333",
  },
};
