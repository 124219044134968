import { pxToRem } from "~/utils";

export const spacings = {
  sceneSpacing: pxToRem(30),
  minimalSpacing: pxToRem(10),
  smallSpacing: pxToRem(12),
  mediumSpacing: pxToRem(40),
  largeSpacing: pxToRem(4),
  minSpacing: pxToRem(4),
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
};
