import React, { FC, useEffect, useState } from "react";
import { observe } from "mobx";
import { observer } from "mobx-react";
import toast from "react-hot-toast";
import { ArrowClose, Close, Done, Edit, Finish, Trash } from "~/assets/svg";
import { useStores } from "~/utils/helpers/store.helper";
import Button from "../Button";
import ConfirmDelete from "../ConfirmDelete";
import If from "../If";
import TextField from "../TextField";
import Typograph from "../Typograph";
import {
  ActionsWrapper,
  ButtonWrapper,
  CheckBoxSection,
  CheckboxField,
  Content,
  FlexDiv,
  IconWrapper,
  InputField,
  InputFieldMargin,
  PropertyInfo,
  PropertyInfoWrapper,
  SelectStyled,
  SelectedTitleWrapper,
} from "./styles";

interface TypeOption {
  value: string;
  label: string;
}

type Props = {
  onEditRules?: (path: string) => void;
};

const JsonEditorSelectedCard: FC<Props> = ({ onEditRules }) => {
  const { jsonEditor, formulation } = useStores();

  function getCurrentKey(path: string) {
    const paths = path.split(".");

    const currentKey = paths.splice(-1, 1)[0];

    const currentPath = paths.join(".");

    return [currentKey, currentPath];
  }

  const [currentKey, currentPath] = getCurrentKey(jsonEditor.jsonSearch);

  const [currentParentKey, currentParentPath] = getCurrentKey(currentPath);

  const currentJson = jsonEditor.getJsonByPath(currentPath);
  const currentParentJson = jsonEditor.getJsonByPath(currentParentPath);

  const typeOptions: TypeOption[] = [
    { value: "string", label: "Texto" },
    { value: "number", label: "Numérico" },
    { value: "boolean", label: "Booleano" },
    { value: "object", label: "Objeto" },
    { value: "array", label: "Lista" },
  ];

  function getPathCurrentType(jsonObject: any, objectKey: string) {
    let pathCurrentType = String(typeof jsonObject[objectKey]);

    if (
      pathCurrentType === "object" &&
      jsonObject[objectKey] &&
      typeof jsonObject[objectKey].length === "number"
    ) {
      pathCurrentType = "array";
    }

    const objectType = typeOptions.find((x) => x.value === pathCurrentType);
    return objectType;
  }

  const currentType = getPathCurrentType(currentJson, currentKey);
  const currentParentType = getPathCurrentType(
    currentParentJson,
    currentParentKey
  );

  const currentValue = currentJson[currentKey];

  const [name, setName] = useState(currentKey);
  const [type, setType] = useState(currentType);
  const [value, setValue] = useState<any>();
  const [isEditMode, setEditMode] = useState(false);
  const [isTypeEditMode, setTypeEditMode] = useState(false);
  const [needClean, setNeedClean] = useState(false);

  useEffect(() => {
    const disposer = observe(jsonEditor, "jsonSearch", () => {
      const [currentKey, currentPath] = getCurrentKey(jsonEditor.jsonSearch);
      const currentJson = jsonEditor.getJsonByPath(currentPath);
      const currentType = getPathCurrentType(currentJson, currentKey);

      setName(currentKey);
      setType(currentType);
      setValue(currentJson[currentKey]);
      setEditMode(false);
      setTypeEditMode(false);
    });

    return disposer;
  });

  if (currentKey === "$") {
    return null;
  }

  const onChange = (event: any) => {
    setName(event.target?.value);
  };

  return (
    <Content>
      <If condition={!isEditMode}>
        <SelectedTitleWrapper>
          <Typograph>{currentKey}</Typograph>

          <If condition={currentParentType?.value !== "array"}>
            <IconWrapper>
              <ConfirmDelete
                key="deleteProperty"
                width={16}
                onDelete={() => {
                  jsonEditor.removeProperty(
                    currentJson,
                    currentKey,
                    currentParentType?.value === "array"
                  );
                  jsonEditor.removeHistory(jsonEditor.jsonSearch);
                  formulation.removeRulesPath(
                    jsonEditor.jsonSearch.replace("$.", "")
                  );
                  jsonEditor.goBackHistory(true);
                }}
              />
              <Edit width={18} onPress={() => setEditMode(true)} />
            </IconWrapper>
          </If>
        </SelectedTitleWrapper>
      </If>

      <If condition={isEditMode}>
        <SelectedTitleWrapper>
          <ArrowClose
            width={16}
            height={16}
            onPress={() => setEditMode(false)}
          />

          <InputField value={name} onChange={onChange} />

          <Done
            size={20}
            onPress={() => {
              try {
                jsonEditor.renameProperty(currentJson, currentKey, name);
                const paths = jsonEditor.jsonSearch.split(".");

                paths[paths.length - 1] = name;
                const newPath = paths.join(".");
                jsonEditor.updateHistory(jsonEditor.jsonSearch, newPath);
                formulation.updateRulesPath(
                  jsonEditor.jsonSearch.replace("$.", ""),
                  newPath.replace("$.", "")
                );
                jsonEditor.search(`${currentPath}.${name}`);
                setEditMode(false);
              } catch (e) {
                const error = e as Error;
                toast.error(error.message);
              }
            }}
          />
        </SelectedTitleWrapper>
      </If>

      <If condition={!isTypeEditMode}>
        <PropertyInfoWrapper>
          <FlexDiv>
            <PropertyInfo>
              Tipo da Variável: <b>{currentType?.label}</b>
            </PropertyInfo>

            <Edit width={18} onPress={() => setTypeEditMode(true)} />
          </FlexDiv>
        </PropertyInfoWrapper>

        <If
          condition={
            currentType?.value !== "object" && currentType?.value !== "array"
          }
        >
          <PropertyInfo>
            Valor da Variável: <b>{currentValue}</b>
          </PropertyInfo>
        </If>
      </If>

      <If condition={isTypeEditMode}>
        <ActionsWrapper>
          <ArrowClose
            width={16}
            height={16}
            onPress={() => setTypeEditMode(false)}
          />

          <SelectStyled
            options={typeOptions}
            value={type}
            onChange={(e: any) => {
              setType(e);
              if (e.value !== type && e.value !== "object") {
                setNeedClean(true);
              } else {
                setNeedClean(false);
              }
              switch (e.value) {
                case "object":
                  setValue({});
                  break;
                case "array":
                  setValue([]);
                  break;
                case "string":
                  setValue("");
                  break;
                case "number":
                  setValue(0);
                  break;
                case "boolean":
                  setValue(false);
                  break;
                default:
                  break;
              }
            }}
          />

          <Done
            size={20}
            onPress={() => {
              if (needClean) {
                const childPath = `${jsonEditor.jsonSearch}.`;
                jsonEditor.removeHistory(childPath);
                formulation.removeRulesPath(childPath.replace("$.", ""));
              }
              jsonEditor.setPropertyValue(currentJson, currentKey, value);
              setTypeEditMode(false);
            }}
          />
        </ActionsWrapper>

        <If condition={type?.value === "string"}>
          <TextField
            enableVariables
            fullWidth
            label="Digite um valor"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </If>

        <If condition={type?.value === "number"}>
          <InputFieldMargin
            placeholder="Digite um valor"
            type="number"
            value={value}
            onChange={(e) => setValue(Number(e.target.value))}
          />
        </If>

        <If condition={type?.value === "boolean"}>
          <CheckBoxSection>
            <CheckboxField
              placeholder="Digite um valor"
              type="checkbox"
              value={value}
              onChange={(e) => setValue(e.target.checked)}
            />
            <PropertyInfo>
              - Valor inicial: {value ? "True" : "False"}
            </PropertyInfo>
          </CheckBoxSection>
        </If>
      </If>

      <ButtonWrapper>
        <Button
          icon="list"
          primary
          text="Gerenciar Regras"
          onPress={() => {
            const paths = jsonEditor.jsonSearch.split(".");
            paths.splice(0, 1);
            if (onEditRules) {
              onEditRules(
                paths
                  .map((path) => (path.match(/\d+/) ? `[${path}]` : path))
                  .join(".")
              );
            }
          }}
        />
      </ButtonWrapper>
    </Content>
  );
};

export default observer(JsonEditorSelectedCard);
