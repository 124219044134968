import { getTheme, ifStyle, pxToRem, styled } from "~/utils";

const contrast = getTheme("colors.contrast");
const grayLight = getTheme("colors.grayLight");
const light = getTheme("colors.light");
const minimalSpacing = getTheme("minimalSpacing");
const failure = getTheme("failure");

const isError = ifStyle("error");
const isMultiline = ifStyle("multiline");
const isFullWidth = ifStyle("fullWidth");
const isSmall = ifStyle("small");
const isMedium = ifStyle("medium");

type ContainerProps = {
  fullWidth: boolean;
  small?: boolean;
  medium?: boolean;
};

type FieldProps = {
  multiline: boolean;
  error?: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: ${isSmall("33%", isMedium("50%", isFullWidth("100%", "50%")))};
`;

export const Label = styled.h4`
  font-size: ${pxToRem(16)};
  color: ${light};
  margin-bottom: ${minimalSpacing};
  font-weight: 400;
`;

export const Field = styled.div<FieldProps>`
  max-width: 100%;
  min-width: 100%;
  width: 100%;

  height: ${isMultiline("200px", "22px")};
  max-height: ${isMultiline("200px", "22px")};
  min-height: ${isMultiline("200px", "22px")};

  background-color: ${contrast};
  border-radius: ${pxToRem(3)};
  border: 1px solid ${isError(failure, "transparent")};
  font-size: ${pxToRem(18)};
  align-items: center;
  color: ${light};
  padding-bottom: auto;

  overflow: auto;

  padding: ${minimalSpacing};
  padding-top: ${isMultiline(pxToRem(5), pxToRem(15))};
  padding-bottom: ${isMultiline(pxToRem(5), pxToRem(15))};

  :focus {
    outline: none;
  }
`;
