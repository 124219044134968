import React, { FC } from "react";
// import { Wrapper } from './styles';

type Props = {
  done?: boolean;
  width?: number;
  onPress?: () => void;
  isDisabled?: boolean;
};

const Icon: FC<Props> = ({ width = 28, isDisabled = false, done, onPress, ...rest }) => (
  <svg
    onClick={() => !isDisabled && onPress && onPress()}
    width={width}
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    style={{ cursor: isDisabled ? "initial" : "pointer", outline: "none", marginBottom: 5 }}
    opacity={isDisabled ? 0.5 : 1}
    {...rest}
  >
    <path
      d="M26 13V23C26 23.7956 25.6839 24.5587 25.1213 25.1213C24.5587 25.6839 23.7956 26 23 26H5C4.20435 26 3.44129 25.6839 2.87868 25.1213C2.31607 24.5587 2 23.7956 2 23V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H16V0H5C3.6744 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.6744 0 5V23C0.00158786 24.3256 0.528882 25.5964 1.46622 26.5338C2.40356 27.4711 3.6744 27.9984 5 28H23C24.3256 27.9984 25.5964 27.4711 26.5338 26.5338C27.4711 25.5964 27.9984 24.3256 28 23V13H26Z"
      fill="#3498DB"
    />
    <path
      d="M10.0681 15.173C9.91402 15.5587 9.87649 15.981 9.9601 16.3878C10.0437 16.7946 10.2448 17.168 10.5384 17.4616C10.8321 17.7553 11.2054 17.9563 11.6122 18.04C12.019 18.1236 12.4414 18.086 12.8271 17.932L16.2001 16.583L27.0101 5.77199C27.643 5.13743 27.9984 4.27775 27.9984 3.38149C27.9984 2.48523 27.643 1.62555 27.0101 0.990988C26.3659 0.376137 25.5096 0.0330811 24.6191 0.0330811C23.7285 0.0330811 22.8723 0.376137 22.2281 0.990988L11.4171 11.8L10.0681 15.173ZM11.9261 15.916L13.1261 12.925L23.6461 2.40499C23.9093 2.15374 24.2592 2.01356 24.6231 2.01356C24.9869 2.01356 25.3368 2.15374 25.6001 2.40499C25.8585 2.66423 26.0037 3.01539 26.0037 3.38149C26.0037 3.74758 25.8585 4.09874 25.6001 4.35799L15.0801 14.878L12.0881 16.078C12.0659 16.0867 12.0416 16.0888 12.0183 16.0839C11.9949 16.079 11.9735 16.0674 11.9567 16.0505C11.9399 16.0336 11.9284 16.0121 11.9237 15.9887C11.9189 15.9654 11.9212 15.9411 11.9301 15.919L11.9261 15.916Z"
      fill="#3498DB"
    />
  </svg>
);

export default Icon;
