import React, { FC } from "react";
import ReactLoading from "react-loading";
import { icons } from "~/assets/svg";
import { colors } from "~/theme";
import If from "../If";
import { Content, Text } from "./styles";

type Props = {
  text?: string | number;
  onPress?: () => void;
  disabled?: boolean;
  outline?: boolean;
  borderOutline?: boolean;
  light?: boolean;
  primary?: boolean;
  loading?: boolean;
  customColor?: string;
  icon?: keyof typeof icons;
};

const Button: FC<Props> = ({
  text = "⠀",
  onPress,
  disabled = false,
  outline = false,
  light = false,
  primary = false,
  loading = false,
  icon = "",
  borderOutline = false,
  customColor = "",
}) => {
  const idx = icon as any;
  const Icon = (icons as any)[idx];

  return (
    <Content
      disabled={disabled}
      onClick={onPress}
      primary={primary}
      light={light}
      outline={outline}
      borderOutline={borderOutline}
      customColor={customColor}
    >
      <If condition={loading}>
        <ReactLoading
          type="spinningBubbles"
          width={25}
          height={25}
          color={light ? colors.colors.primary : colors.colors.light}
        />
      </If>

      <If condition={!loading}>
        <>
          <If condition={!!icon}>
            <Icon
              width={25}
              color={light ? colors.colors.primary : colors.colors.light}
            />
          </If>

          <Text
            primary={primary}
            outline={outline}
            light={light}
            disabled={disabled}
          >
            {text}
          </Text>
        </>
      </If>
    </Content>
  );
};

export default Button;
