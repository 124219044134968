/* eslint-disable react/destructuring-assignment */
import React, { FC } from "react";
import { observer, parsedHeaderToTextArea, useStores } from "~/utils";
import TextFieldVariable from "../TextFieldVariable";
import { Content } from "./styles";
import "react-tabs/style/react-tabs.css";

type Props = {
  id: number;
  name: string;
  method: string;
  URL: string;
  headers: Record<string, string>;
};

const HeadersTabResponse: FC<Props> = ({ id, URL, method, name, headers }) => {
  const { formulation } = useStores();

  const onChangeHeaders = (event: any) => {
    const result: Record<string, string> = {};

    const { value = "" } = event;
    const parsedValue = value.trim();

    if (parsedValue) {
      parsedValue.split("\n").forEach((header: any) => {
        const [key, value] = header.split(":");
        result[key?.trim()] = value?.trim();
      });
    }

    formulation.onChangeMethodsData({
      id,
      response_params: {
        header_response: result,
      },
    });

    return result;
  };

  return (
    <Content>
      <TextFieldVariable
        name="headers"
        initialValue={parsedHeaderToTextArea(headers)}
        onChange={onChangeHeaders}
        multiline
        fullWidth
      />
    </Content>
  );
};
export default observer(HeadersTabResponse);
