/* eslint-disable no-param-reassign */
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { persist } from "mobx-persist";
import { makePersistable } from "mobx-persist-store";
import { toast } from "react-hot-toast";
import { AppAPI } from "~/services/api";
import {
  MOCK_DATA,
  generateIdCaseTest,
  isValidBodyRequestMethod,
  onReviewValues,
  parseContentToSendAPI,
  parseFileResponse,
  parseSetupVariables,
  parsedURLToPathParams,
} from "~/utils";
import Root from "./index";

export default class FormulationStore {
  @observable
  methodActive: Partial<Formulation.TabsMethod> = {
    header: 0,
    body: 0,
    query: 0,
    form_data: 0,
  };

  @persist
  @observable
  methods: Formulation.Data[] = [];

  @observable
  idAttachment = "";

  @observable
  fileId = "";

  @persist
  @observable
  data: Formulation.Data[] = [];

  @observable
  selected: number[] = [];

  @persist
  @observable
  idMethodSelected: number = 0;

  @persist
  @observable
  headers: Formulation.Headers[] = [];

  @persist
  @observable
  headersV2: Formulation.Headers[] = [];

  @persist
  @observable
  body: Formulation.Body[] = [];

  @persist
  @observable
  rules: Formulation.RulesParams[] = [];

  @observable
  parameterInEdition: Formulation.ParameterInEditionParams = {};

  @persist
  @observable
  query: Formulation.Query[] = [];

  @observable
  methodsLoading: number[] = [];

  root: typeof Root;

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);

    makePersistable(this, {
      name: "Formulation",
      properties: ["data", "headers", "idAttachment", "body", "methods", "query", "rules"],
      storage: window.localStorage,
    });
  }

  onGetMethod = (id = 0): Formulation.Data | undefined => this.methods.find((item) => item.id === id);

  @computed get parsedRules(): Formulation.RulesParams[] | [] {
    const method = this.methods.find(({ id }) => id === this.idMethodSelected);
    if (!method) return [];

    const rules = method.rules?.filter(
      (r) => r.parameters.includes(this.parameterInEdition.name!) && r.reference === this.root.app.reference
    );
    if (!rules) return [];

    return (
      rules.map((rl) => ({
        name: rl.name,
        value: rl.config,
        editable: true,
        code: rl.expected_code || "200",
      })) || []
    );
  }

  @action
  onSetIDMethodSelected = (id: number): void => {
    runInAction(() => {
      this.idMethodSelected = id;
    });
  };

  @action
  onSelect = (id: number): void => {
    const exist = this.selected.includes(id);

    if (exist) {
      this.selected = this.selected.filter((item) => item !== id);
      return;
    }

    this.selected.push(id);
  };

  @action
  onSetTabsMethod = (method: Partial<Formulation.TabsMethod>) => {
    runInAction(() => {
      if (!method) return;

      this.methodActive = method!;
    });
  };

  @action
  onNewMethodBlank = (): number => {
    const id = Math.floor(Math.random() * new Date().getTime());

    this.methods = [{ ...MOCK_DATA, id }, ...this.methods];

    return id;
  };

  @action
  onRemoveMethod = (id: number): void => {
    this.methods = this.methods.filter((item) => item.id !== id);
  };

  @action
  onClearMethods = () => {
    runInAction(() => {
      this.methods = this.methods.filter((item) => !this.selected.includes(item.id));
    });

    this.selected = [];
  };

  @action
  onChangeMethodsData = (data: Partial<Formulation.Data>) => {
    const copyMethods = this.methods;

    const idx = copyMethods.findIndex((item) => item.id === data.id);

    if (idx <= -1) return;

    const paramObject = data.path_param || copyMethods[idx].path_param;
    const url = data.URL || copyMethods[idx].URL;

    runInAction(() => {
      copyMethods[idx] = {
        ...copyMethods[idx],
        ...data,
        path_param: parsedURLToPathParams(url, paramObject),
      };

      this.methods = copyMethods;
    });
  };

  // HEADERS

  @action
  onSetParameterInEdition = (data: Formulation.ParameterInEditionParams) => {
    this.parameterInEdition = data;
  };

  @action
  onChangeRulesInMethod = ({ code, name, value }: Formulation.RulesParams) => {
    const { reference } = this.root.app;
    const { name: parameter } = this.parameterInEdition;

    const method = this.methods.find((mt) => mt.id === this.idMethodSelected)!;
    if (!method) return;

    const object = {
      name,
      expected_code: code || "200",
      reference,
      config: value,
      parameters: [parameter!],
    };

    const ruleExist = method.rules?.some(
      (r) => r.reference === reference && r.name === name && r.config === value && r.parameters.includes(parameter!)
    );

    if (ruleExist) {
      this.onChangeMethodsData({
        id: this.idMethodSelected,
        rules: method.rules.map((r) => {
          if (r.reference === reference && r.name === name && r.config === value) {
            return {
              ...r,
              ...object,
              config: value || r.config,
            };
          }

          return r;
        }),
      });
    } else {
      this.onChangeMethodsData({
        id: this.idMethodSelected,
        rules: [object, ...method.rules],
      });
    }
  };

  @action
  onRemoveRulesInParameter = ({ parameter, rule, value }: Formulation.RemoveRuleFromParameter): void => {
    const { reference } = this.root.app;
    const method = this.methods.find((mt) => mt.id === this.idMethodSelected)!;
    if (!method) return;

    const isUnique = method.rules?.some(
      (r) =>
        r.name === rule &&
        r.config === value &&
        r.reference === reference &&
        r.parameters.includes(parameter) &&
        r.parameters.length === 1
    );

    const object = isUnique
      ? method.rules.filter(
          (r) =>
            (r.reference === reference && r.name !== rule) || r.config !== value || !r.parameters.includes(parameter)
        )
      : method.rules.map((r) => {
          if (r.name === rule && r.config === value && r.parameters.includes(parameter)) {
            return {
              ...r,
              parameters: r.parameters.filter((p) => p !== parameter),
            };
          }

          return r;
        });

    this.onChangeMethodsData({
      id: this.idMethodSelected,
      rules: object.filter((r) => r.parameters.length > 0),
    });
  };

  @action
  onRemoveQuery = ({ name }: Partial<Formulation.Body>) => {
    const { reference } = this.root.app;

    const method = this.methods.find((mt) => mt.id === this.idMethodSelected)!;
    if (!method) return;

    const isUnique = method.rules?.some((r) => r.parameters.includes(name!) && r.parameters.length === 1);

    const rules = isUnique
      ? method.rules.filter((r) => r.reference === reference && !r.parameters.includes(name!))
      : method.rules.map((r) => {
          if (r.parameters.includes(name!)) {
            return {
              ...r,
              parameters: r.parameters.filter((p) => p !== name!),
            };
          }

          return r;
        });

    this.onChangeMethodsData({
      id: this.idMethodSelected,
      query: method.query.filter((b) => b.name !== name),
      rules,
    });
  };

  @action
  onRemoveFormDataField = ({ name, id }: Formulation.FormDataRemove) => {
    const { reference } = this.root.app;

    const method = this.methods.find((mt) => mt.id === id)!;

    if (!method) return;

    const isUnique = method.rules?.some((r) => r.parameters.includes(name!) && r.parameters.length === 1);

    const rules = isUnique
      ? method.rules.filter((r) => r.reference === reference && !r.parameters.includes(name!))
      : method.rules.map((r) => {
          if (r.parameters.includes(name!)) {
            return {
              ...r,
              parameters: r.parameters.filter((p) => p !== name!),
            };
          }

          return r;
        });

    this.onChangeMethodsData({
      id,
      formData: method.formData.filter((b) => b.name !== name),
      rules,
    });
  };

  @action
  onSendCurl = async (model: string) => {
    try {
      this.methodsLoading = [1];

      const fileId = await AppAPI.onSendCurl(model);
      if (!fileId) return;

      runInAction(() => {
        this.fileId = fileId;
      });

      const ended = await this.onContentFile(fileId);
      return ended;
    } catch (error) {
      return false;
    } finally {
      this.methodsLoading = [];
    }
  };

  @action
  onSendContentFile = async (model: string) => {
    try {
      this.methodsLoading = [1];

      const fileId = await AppAPI.onSendContentFile(model);
      if (!fileId) return;

      runInAction(() => {
        this.fileId = fileId;
      });

      const ended = await this.onContentFile(fileId);

      return ended;
    } catch (error) {
      return false;
    } finally {
      this.methodsLoading = [];
    }
  };

  @action
  onContentFile = async (id: string): Promise<boolean> => {
    try {
      let instanceInterval: any;
      const data = await AppAPI.onFetchRequests(id);
      if (!data) return false;

      if (!data?.requests?.length) {
        instanceInterval = setInterval(async () => {
          const data = await AppAPI.onFetchRequests(id);
          if (!data) return false;

          if (data?.error_msg) {
            toast(data?.error_msg);
            clearInterval(instanceInterval);
            return false;
          }

          if (data?.requests?.length > 0) {
            clearInterval(instanceInterval);

            runInAction(() => {
              this.methods = parseFileResponse(data);
            });

            return true;
          }
        }, 2000);
      }

      runInAction(() => {
        this.methods = parseFileResponse(data);
      });

      this.methods.forEach((d) => {
        this.root.testCase.setReference(d.id.toString());

        this.root.testCase.onSetEditing({
          uid: generateIdCaseTest(this.root.testCase.data.map(({ uid }) => uid)),
          editMode: false,
          name: "Validar Status Code",
          description: "Validar Status code | 200",
          payload: "",
          field: "",
          testType: "Status Code",
          response: {},
          statusCode: "200",
          values_to_change: [],
        });

        this.root.testCase.addTest();
      });

      return !!data?.requests?.length;
    } catch (error) {
      return false;
    }
  };

  @action
  onSendJsonAPI = async (allData?: Formulation.Data[]) => {
    try {
      const object = allData?.length
        ? allData
        : this.selected.length
        ? this.methods.filter((item) => this.selected.includes(item.id))
        : this.methods;

      this.selected = [];

      const wizard_requests = object.map((data) => {
        const isValid = isValidBodyRequestMethod(data);
        if (!isValid) return [];

        runInAction(() => {
          this.methodsLoading = [...this.methodsLoading, data.id!];
        });

        const test_case =
          this.root.testCase.data.filter(({ referenceMethod }) => referenceMethod === data.id.toString()) || [];

        return parseContentToSendAPI({
          data: {
            ...data,
            test_case,
          },
        });
      }) as Formulation.DataSendToAPI[];

      const setup = this.root.variables.data.map((variables) => ({
        ...variables,
        value:
          variables.type === "Request"
            ? this.root.variables.configRequest.find(({ uid }) => uid === variables.uid)!
            : variables.value,
      }));

      const response = await AppAPI.onSendJSON({
        wizard_requests,
        setup: parseSetupVariables(setup).map(onReviewValues),
      });

      if (!response) return;

      this.idAttachment = response;
    } catch (error) {
      // ...
    }
  };

  @action
  onDownloadFile = async (): Promise<string> => {
    try {
      const data = await AppAPI.onFetchRobotFile({ id: this.idAttachment });

      if (data.error_msg) {
        toast(data.error_msg!, {
          position: "top-center",
        });
        return "";
      }

      if (!data.link_download) {
        toast("Arquivo ainda não esta disponível, tente novamente em 5 minutos!", {
          position: "top-center",
        });
        return "";
      }

      return data.link_download;
    } catch (error) {
      toast("Arquivo ainda não esta disponível, tente novamente em 5 minutos!", {
        position: "top-center",
      });
      return "";
    }
  };

  @action
  onRemoveHeaders = ({ id, label }: Partial<Formulation.ChangeHeaderParams>) => {
    const method = this.methods.find((item) => item.id === id)!;
    if (!method) return;

    const { headers } = method;

    const newHeaders = Object.keys(headers)
      .filter((h) => h !== label)
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: headers[cur],
        }),
        {}
      );

    this.onChangeMethodsData({
      id,
      headers: newHeaders,
    });
  };

  @action
  onChangeValueHeaders = ({ id, label, value, oldLabel }: Partial<Formulation.ChangeHeaderParams>) => {
    const method = this.methods.find((item) => item.id === id)!;
    if (!method) return;

    const { headers } = method;

    const headersKeys = Object.keys(method.headers).filter((h) => h !== oldLabel);

    const obj = headersKeys.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: headers[cur],
      }),
      {}
    );

    const newHeaders = {
      ...obj,
      [label!]: value,
    };

    this.onChangeMethodsData({
      id,
      headers: newHeaders,
    });
  };

  @action
  setJsonObject = (id: number, jsonObject: Record<string, string>) => {
    const method = this.methods.find((item) => item.id === id)!;
    if (!method) return;
    method.bodyJson = jsonObject?.toString();
  };

  @action
  updateRulesPath = (oldPath: string, newPath: string) => {
    const method = this.methods.find((item) => item.id === this.idMethodSelected)!;
    if (!method) return;
    method.rules.forEach((rule) => {
      rule.parameters = rule.parameters.map((parameter) => {
        if (parameter.startsWith(oldPath)) {
          return parameter.replace(new RegExp(`^${oldPath}`), newPath);
        }
        return parameter;
      });

      return rule;
    });
  };

  @action
  removeRulesPath = (path: string) => {
    const method = this.methods.find((item) => item.id === this.idMethodSelected)!;
    if (!method) return;
    method.rules = method.rules.filter((rule) => {
      rule.parameters = rule.parameters.filter((parameter) => !parameter.startsWith(path));
      return rule.parameters.length;
    });
  };
}
