import { getTheme, ifStyle, pxToRem, styled } from "~/utils";

const minSpacing = getTheme("minSpacing");
const primary = getTheme("colors.primary");
const secondary = getTheme("colors.secondary");
const failure = getTheme("failure");
const light = getTheme("colors.light");

const notExist = ifStyle("notExist");
const minimalSpacing = getTheme("minimalSpacing");

type PropsSpan = {
  notExist?: boolean;
};

export const Text = styled.h3`
  display: flex;
  font-weight: 400;
  font-size: ${pxToRem(20)};
  align-items: center;
  color: ${secondary};
`;

export const Span = styled.h3<PropsSpan>`
  background-color: ${notExist(failure, primary)};
  padding: ${minSpacing};
  font-size: ${pxToRem(20)};
  font-weight: 400;
  border-radius: ${minSpacing};
`;
