import React, { FC, useState } from "react";
import { Add, ArrowClose, Close, Edit } from "~/assets/svg";
import { STATUS_CODE, TEST_TYPE, generateIdCaseTest, observer, sanitizeJSON, useStores } from "~/utils";
import If from "../If";
import { colors } from "~/theme";
import {
  Block,
  Content,
  Field,
  Header,
  Label,
  Section,
  SectionFields,
  SectionSelect,
  SectionsAdded,
  Text,
  AutocompleteStyled,
  MUITextFieldStyled,
  Title,
  FieldFullWidth,
  Button,
  Paragraph,
  SectionBtns,
  SectionText,
  SectionResponseAdded,
  FieldSmall,
  SectionValuesToChangeAdded,
} from "./styles";
import ConfirmDelete from "../ConfirmDelete";
import { isEmpty } from "lodash";
import toast from "react-hot-toast";

type Props = {
  headers?: Record<string, string>;
  query?: Formulation.Query[];
  formData?: Formulation.FormData[];
  pathParam?: Record<string, string>;
  bodyJson?: string;
};

const CaseTestView: FC<Props> = ({ headers, pathParam, formData, query, bodyJson }) => {
  const { testCase } = useStores();
  const [valueToChange, setValueToChange] = useState<Omit<TestCase.ValuesToChange, "uid">>({
    payload_field: "",
    payload_reference: "",
    new_value: "",
  });

  const [formResponse, setFormResponse] = useState({
    name: "",
    value: "",
  });
  const [hiddenList, setHiddenList] = useState(false);

  const onPayloadMap = (): string[] => {
    const options = [];

    if (!isEmpty(formData)) options.push("Form-Data");
    if (!isEmpty(headers)) options.push("Header");
    if (!isEmpty(query)) options.push("Query");
    if (!isEmpty(pathParam)) options.push("Path Parameter");
    if (!isEmpty(bodyJson)) options.push("Body JSON");

    return options;
  };

  const onFieldMap = (payload: string): string[] => {
    switch (payload) {
      case "Header":
        return Object.keys(headers || {});
      case "Form-Data":
        return formData?.map((b) => b.name) || [];
      case "Query":
        return query?.map((q) => q.name) || [];
      case "Path Parameter":
        return Object.keys(pathParam || {});
      case "Body JSON":
        return Object.keys(sanitizeJSON(bodyJson || "{}"));
      default:
        return [];
    }
  };

  const onSubmit = () => {
    if (testCase.editing?.testType === "Status Code" && !testCase.editing?.statusCode) {
      toast.error("Status Code é obrigatório");

      return;
    }

    if (testCase.editing?.editMode) testCase.editTestData();

    if (!testCase.editing?.editMode) testCase.addTest();

    setHiddenList(false);
  };

  const onEdit = (dt: TestCase.Data) => {
    testCase.onSetEditing({
      ...dt,
      editMode: true,
    });

    setHiddenList(true);
  };

  const onNewTest = () => {
    testCase.onSetEditing(null);
    setHiddenList(true);

    testCase.onChange({
      value: generateIdCaseTest(testCase.data.map(({ uid }) => uid)),
      name: "uid",
    });
  };

  const onResponse = () => {
    if (!formResponse.name) return;

    testCase.onChangeResponse(formResponse.value, formResponse.name);

    setFormResponse({
      name: "",
      value: "",
    });
  };

  const onChangeValueToChange = (data: Record<string, string>) => {
    setValueToChange({
      ...valueToChange,
      ...data,
    });
  };

  const onEditValueToChange = (data: Partial<TestCase.ValuesToChange>) => {
    testCase.onValueToChangeEdit(data);
  };

  const onAddValueToChange = () => {
    const added = testCase.onValueToChangeAdd(valueToChange);

    if (added) {
      setValueToChange({
        payload_field: "",
        payload_reference: "",
        new_value: "",
      });
    }
  };

  return (
    <If condition={testCase.visibleModalTestCase}>
      <Content>
        <Section>
          <Header>
            <div style={{ display: "flex" }}>
              <If condition={hiddenList}>
                <ArrowClose
                  onPress={() => {
                    setHiddenList(false);
                  }}
                  color={colors.colors.light}
                  width={12}
                />
              </If>

              <Title
                iconText="faq"
                textDescribe={`
                   Crie casos de teste para seus parâmetros
              `}
              >
                Casos de teste
              </Title>
            </div>
            <Close
              onPress={() => {
                setHiddenList(false);
                testCase.setVisibleModalTestCase(false);
              }}
              color={colors.colors.light}
            />
          </Header>

          <If condition={hiddenList}>
            <SectionFields>
              <Label>Código: {testCase.editing?.uid}</Label>
              <Button onClick={onSubmit}>{testCase.editing?.editMode ? "Salvar Teste" : "Adicionar Teste"}</Button>
            </SectionFields>

            <SectionsAdded>
              <FieldFullWidth
                label="Nome"
                name="name"
                value={testCase.editing?.name}
                onChange={(event: any) => {
                  testCase.onChange(event.target);
                  return event;
                }}
              />

              <FieldFullWidth
                label="Descrição"
                name="description"
                value={testCase.editing?.description}
                onChange={(event: any) => {
                  testCase.onChange(event.target);

                  return event;
                }}
              />

              <SectionFields>
                <SectionSelect>
                  <Label>Payload</Label>

                  <AutocompleteStyled
                    freeSolo
                    disableClearable
                    value={valueToChange?.payload_reference}
                    onChangeCapture={(event: any) => {
                      const { value } = event.target;

                      onChangeValueToChange({
                        payload_reference: value,
                        field: "",
                      });
                    }}
                    onChange={(_, value) => {
                      onChangeValueToChange({
                        payload_reference: value as string,
                        field: "",
                      });
                    }}
                    options={onPayloadMap()}
                    renderInput={(params: any) => <MUITextFieldStyled {...params} />}
                  />
                </SectionSelect>

                <SectionSelect>
                  <Label>Campo</Label>

                  <AutocompleteStyled
                    freeSolo
                    disableClearable
                    value={valueToChange?.payload_field}
                    onChangeCapture={(event: any) => {
                      const { value } = event.target;

                      onChangeValueToChange({
                        payload_field: value,
                      });
                    }}
                    onChange={(_, value) => {
                      onChangeValueToChange({
                        payload_field: value as string,
                      });
                    }}
                    options={onFieldMap(valueToChange.payload_reference)}
                    renderInput={(params: any) => <MUITextFieldStyled {...params} />}
                  />
                </SectionSelect>

                <FieldSmall
                  label="Valor"
                  name="value"
                  value={valueToChange.new_value}
                  onChange={(event: any) => {
                    onChangeValueToChange({
                      new_value: event.target.value,
                    });
                    return event;
                  }}
                />

                <Add style={{ marginTop: 40, marginLeft: 10 }} onPress={onAddValueToChange} />
              </SectionFields>

              <If condition={!isEmpty(testCase.editing?.values_to_change)}>
                <SectionValuesToChangeAdded>
                  {testCase.editing?.values_to_change?.map((edit, idx) => (
                    <SectionFields>
                      <SectionSelect>
                        <Label>Payload</Label>

                        <AutocompleteStyled
                          freeSolo
                          disableClearable
                          value={edit?.payload_reference}
                          onChangeCapture={(event: any) => {
                            const { value } = event.target;

                            onEditValueToChange({
                              uid: edit.uid,
                              payload_reference: value,
                              payload_field: "",
                            });
                          }}
                          onChange={(_, value) => {
                            onEditValueToChange({
                              uid: edit.uid,
                              payload_reference: value as string,
                              payload_field: "",
                            });
                          }}
                          options={onPayloadMap()}
                          renderInput={(params: any) => <MUITextFieldStyled {...params} />}
                        />
                      </SectionSelect>

                      <SectionSelect>
                        <Label>Campo</Label>

                        <AutocompleteStyled
                          freeSolo
                          disableClearable
                          value={edit?.payload_field}
                          onChangeCapture={(event: any) => {
                            const { value } = event.target;

                            onEditValueToChange({
                              uid: edit.uid,
                              payload_field: value,
                            });
                          }}
                          onChange={(_, value) => {
                            onEditValueToChange({
                              uid: edit.uid,
                              payload_field: value as string,
                            });
                          }}
                          options={onFieldMap(edit.payload_reference)}
                          renderInput={(params: any) => <MUITextFieldStyled {...params} />}
                        />
                      </SectionSelect>

                      <FieldSmall
                        label="Valor"
                        name="value"
                        value={edit.new_value}
                        onChange={(event: any) => {
                          onEditValueToChange({
                            uid: edit.uid,
                            new_value: event.target.value,
                          });
                          return event;
                        }}
                      />

                      <ConfirmDelete
                        key={edit.payload_field}
                        width={18}
                        style={{ marginTop: 40, marginLeft: 10 }}
                        onDelete={() => testCase.onValueToChangeRemove(edit.uid)}
                      />
                    </SectionFields>
                  ))}
                </SectionValuesToChangeAdded>
              </If>

              <SectionFields
                style={{
                  justifyContent: testCase.editing?.testType === "Status Code" ? "space-around" : "space-between",
                }}
              >
                <SectionSelect>
                  <Label>Test Type</Label>

                  <AutocompleteStyled
                    freeSolo
                    disableClearable
                    value={testCase.editing?.testType}
                    onChange={(_, value) => {
                      testCase.onChange({
                        value: "",
                        name: "statusCode",
                      });

                      testCase.onChange({
                        value: {},
                        name: "response",
                      });

                      testCase.onChange({
                        value: value as string,
                        name: "testType",
                      });
                    }}
                    options={TEST_TYPE}
                    renderInput={(params: any) => <MUITextFieldStyled {...params} />}
                  />
                </SectionSelect>

                <If condition={testCase.editing?.testType === "Status Code"}>
                  <SectionSelect>
                    <Label>Status Code</Label>

                    <AutocompleteStyled
                      freeSolo
                      disableClearable
                      disabled={!(testCase.editing?.testType === "Status Code")}
                      value={testCase.editing?.statusCode}
                      onChangeCapture={(event: any) => {
                        const { value } = event.target;

                        testCase.onChange({
                          value: value,
                          name: "statusCode",
                        });
                      }}
                      onChange={(_, value) => {
                        testCase.onChange({
                          value: value as string,
                          name: "statusCode",
                        });
                      }}
                      options={STATUS_CODE}
                      renderInput={(params: any) => <MUITextFieldStyled {...params} />}
                    />
                  </SectionSelect>
                </If>

                <If
                  condition={
                    testCase.editing?.testType === "Response Body" || testCase.editing?.testType === "Response Header"
                  }
                >
                  <FieldSmall
                    label="Chave"
                    name="name"
                    value={formResponse.name}
                    onChange={(event: any) => {
                      const { value } = event.target;

                      setFormResponse({
                        ...formResponse,
                        name: value,
                      });
                      return event;
                    }}
                  />

                  <FieldSmall
                    label="Valor"
                    name="name"
                    value={formResponse.value}
                    onChange={(event: any) => {
                      const { value } = event.target;

                      setFormResponse({
                        ...formResponse,
                        value: value,
                      });
                      return event;
                    }}
                  />

                  <Add style={{ marginTop: 40 }} onPress={onResponse} />
                </If>
              </SectionFields>

              <If
                condition={
                  testCase.editing?.testType === "Response Body" ||
                  (testCase.editing?.testType === "Response Header" && !isEmpty(testCase.editing?.response))
                }
              >
                {Object.keys(testCase.editing?.response || {})
                  .reverse()
                  .map((key, idx) => (
                    <SectionResponseAdded key={key}>
                      <Field label="Chave" name="name" disabled value={key} />

                      <Field
                        label="Valor"
                        name="value"
                        value={testCase.editing?.response[key]}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          setFormResponse({
                            ...formResponse,
                            value: value,
                          });
                          return event;
                        }}
                      />

                      <ConfirmDelete
                        key="12"
                        onDelete={() => testCase.onRemoveResponse(key)}
                        width={18}
                        style={{ marginBottom: 13 }}
                      />
                    </SectionResponseAdded>
                  ))}
              </If>
            </SectionsAdded>
          </If>

          <If condition={!hiddenList}>
            <SectionsAdded>
              {testCase.data
                .slice()
                .reverse()
                .filter(({ referenceMethod }) => referenceMethod === testCase.referenceMethod)
                .map((test) => (
                  <Block key={test.uid}>
                    <SectionText>
                      <Text>{test.uid}</Text>
                      <Paragraph>
                        {test.name.length > 50 ? `${(test.name || "").substring(0, 50)}...` : test.name}
                      </Paragraph>
                    </SectionText>

                    <SectionBtns>
                      <Edit width={18} onPress={() => onEdit(test)} />
                      <ConfirmDelete key="12" onDelete={() => testCase.removeTest(test.uid)} width={18} />
                    </SectionBtns>
                  </Block>
                ))}
            </SectionsAdded>
            <Button onClick={onNewTest}>Novo Caso de Teste</Button>
          </If>
        </Section>
      </Content>
    </If>
  );
};

export default observer(CaseTestView);
