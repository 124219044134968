import React from "react";
import { MobXProviderContext } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import store from "~/stores";

type Routing = {
  routing: RouterStore;
};

export const useStores = (): typeof store & Routing & { methodId: number } => {
  const { root } = React.useContext(MobXProviderContext);
  return root;
};

export const simplifyString = (value = ""): string => {
  const noAccents = value?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const noSpecialChar = noAccents?.replaceAll(/\W/gi, " ");
  const noMultipleSpaces = noSpecialChar?.replaceAll(/\s+/g, "_");
  const parsedText = noMultipleSpaces?.toLowerCase().trim();
  return parsedText.toLowerCase();
};

export const parseNameHeader = (value = ""): string => {
  const noAccents = value
    ?.trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const noSpecialChar = noAccents?.replaceAll(/\W/gi, " ");
  const noMultipleSpaces = noSpecialChar?.replaceAll(/\s+/g, "-");
  const parsedText = noMultipleSpaces?.trim();
  return parsedText;
};
