import { getTheme, ifStyle, pxToRem, styled } from "~/utils";
import TextField from "../TextField";
import Tab from "@mui/material/Tab";

type Props = {
  selected?: boolean;
  color?: string;
};

// Colors
const light = getTheme("colors.light");
const failure = getTheme("failure");
const contrast = getTheme("colors.contrast");
const colorsSecondary = getTheme("colors.secondary");
const primary = getTheme("colors.primary");

// Spacings
const smallSpacing = getTheme("smallSpacing");
const minimalSpacing = getTheme("minimalSpacing");

// Ifs
const isSelected = ifStyle("selected");
const isError = ifStyle("error");

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SectionMethods = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardMethod = styled.button<Props>`
  border: 0;
  display: flex;
  justify-content: center;
  min-width: ${pxToRem(70)};
  margin-right: ${smallSpacing};
  border-radius: ${pxToRem(5)};
  font-size: ${pxToRem(15)};
  padding: ${minimalSpacing};
  background-color: ${(props) => isSelected(props.color, contrast)};
  color: ${light};
  margin-top: ${smallSpacing};
  cursor: pointer;
`;

export const Field = styled(TextField).attrs({
  containerStyle: {
    width: "45%",
  },
})``;

export const Divider = styled.div`
  width: 100%;
  height: ${pxToRem(2)};
  border-bottom: 2px solid ${colorsSecondary}20;
  margin: ${minimalSpacing} 0px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${pxToRem(5)};
  margin-bottom: ${smallSpacing};
`;

export const Title = styled.h4`
  font-weight: 400;
  color: ${light};
  font-size: ${pxToRem(22)};
  width: 95%;
  word-wrap: break-word;
  padding-left: ${pxToRem(10)};
`;

export const SectionTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type PropsTextArea = {
  error?: boolean;
};

export const TextArea = styled.textarea<PropsTextArea>`
  width: 98.5%;
  border: 0;
  background-color: ${contrast};
  font-size: ${pxToRem(18)};
  padding: ${minimalSpacing};
  height: ${pxToRem(200)};
  color: ${light};
  font-weight: 400;
  border-radius: ${pxToRem(10)};
  border: ${isError(1, 0)}px solid ${failure};

  &:focus {
    outline: none;
  }
`;

export const SectionTabs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TabStyled = styled(Tab)`
  width: 50%;
  color: ${light};

  &.MuiTab-textColorPrimary {
    color: ${light};
  }

  &.MuiTab-textColorPrimary.Mui-selected {
    color: ${primary};
  }

  &.MuiTab-textColorPrimary.Mui-disabled {
    color: ${light};
    opacity: 0.5;
  }
`;
