import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { deepObserve } from "mobx-utils";
import { useStores } from "~/utils/helpers/store.helper";
import JsonEditorMenuBar from "../JsonEditorMenuBar";
import JsonEditorPropertyDetail from "../JsonEditorPropertyDetail";
import JsonEditorSelectedCard from "../JsonEditorSelectedCard";
import {
  Content,
  JsonEditorContent,
  JsonWrapper,
  PropertyDetailWrapper,
} from "./styles";

type Props = {
  method: Formulation.Data;
  onEditRules?: (path: string) => void;
  onBodyChange?: (jsonObject: Record<string, string>) => void;
};

const JsonEditor: FC<Props> = ({ method, onEditRules, onBodyChange }) => {
  const { jsonEditor } = useStores();

  const [detailWrapperElement, setDetailWrapperElement] =
    useState<HTMLDivElement | null>(null);
  const [scrollRight, setScrollRight] = useState(0);

  useEffect(() => {
    if (detailWrapperElement) {
      const scrollRight =
        detailWrapperElement.scrollWidth - detailWrapperElement.offsetWidth;

      setScrollRight(Math.max(scrollRight - 18, 0));
    }
  });

  useEffect(() => {
    const disposer = deepObserve(jsonEditor.jsonObject, () => {
      if (onBodyChange) {
        onBodyChange(jsonEditor.jsonObject);
      }
    });

    return disposer;
  });

  const paths = jsonEditor.jsonSearch.split(".");

  const details: string[] = [];

  paths.forEach((_, index, array) => {
    details.push(
      Array.from(array)
        .splice(0, index + 1)
        .join(".")
    );
  });

  return (
    <Content>
      <JsonEditorContent hasSelected={paths.length > 1}>
        <JsonEditorMenuBar />
        <JsonWrapper>
          <PropertyDetailWrapper
            ref={setDetailWrapperElement}
            scrollRight={scrollRight}
          >
            {details.map((path) => (
              <JsonEditorPropertyDetail method={method} path={path} />
            ))}
          </PropertyDetailWrapper>
        </JsonWrapper>
      </JsonEditorContent>
      <JsonEditorSelectedCard onEditRules={onEditRules} />
    </Content>
  );
};

export default observer(JsonEditor);
