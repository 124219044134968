import Axios from 'axios';
import { REACT_APP_BASE_PATH } from '~/utils';
import { interceptorsAuthHeaders } from './interceptors';

const baseURL = process.env.BASE_PATH;

export const request = Axios.create({
  baseURL: REACT_APP_BASE_PATH,
  timeout: 150000, // 150 seconds timeout
});

request.interceptors.request.use(interceptorsAuthHeaders);

export default request;
