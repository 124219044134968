import Select from "react-select";
import styled from "styled-components";
import { getTheme, pxToRem } from "~/utils";

const minimalSpacing = getTheme("minimalSpacing");
const colorsSecondary = getTheme("colors.secondary");

export const Content = styled.div`
  width: 25%;
  border-left: 1px solid black;
  padding: 16px;
  box-sizing: border-box;
`;

export const SelectedTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${minimalSpacing};
  padding-bottom: ${minimalSpacing};
  border-bottom: 1px solid ${colorsSecondary}50;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${pxToRem(60)};
`;

export const PropertyInfo = styled.h2`
  font-weight: 400;
  font-size: ${pxToRem(18)};
  color: ${colorsSecondary};
`;

export const PropertyInfoWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${minimalSpacing};
`;

export const SelectStyled = styled(Select)`
  width: 80%;
`;

export const InputField = styled.input`
  width: 80%;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  min-height: 38px;
  padding: 2px 8px;
  font-size: 16px;
  color: hsl(0, 0%, 20%);
`;

export const InputFieldMargin = styled(InputField)`
  margin-top: 16px;
`;

export const CheckboxField = styled.input`
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${minimalSpacing};
`;

export const CheckBoxSection = styled.div`
  margin-top: ${pxToRem(15)};
  display: flex;
  align-items: center;
  flex-direction: row;
`;
