import { getTheme, ifStyle, pxToRem, styled } from "~/utils";

type PropsTextArea = {
  error?: boolean;
};

// Colors
const secondary = getTheme("colors.secondary");
const contrast = getTheme("colors.contrast");
const dark = getTheme("colors.dark");
const light = getTheme("colors.light");
const failure = getTheme("failure");

// Spacings
const smallSpacing = getTheme("smallSpacing");

// Ifs
const isError = ifStyle("error");

export const Content = styled.div`
  flex: 1;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${secondary};
`;

export const WhiteCard = styled.button`
  display: flex;
  border: 0;
  background-color: ${light};
  width: 80%;
  height: 70%;
  border-radius: ${pxToRem(20)};
  align-items: center;
  justify-content: center;
`;

export const TextArea = styled.textarea<PropsTextArea>`
  border: 0;
  background-color: ${contrast};
  color: ${dark};
  width: 98%;
  height: 95%;
  border-radius: ${pxToRem(20)};
  font-size: ${pxToRem(20)};
  padding: ${smallSpacing};
  border: ${isError(1, 0)}px solid ${failure};

  &:focus {
    outline: none;
  }
`;
