/* eslint-disable react/destructuring-assignment */
import "react-tabs/style/react-tabs.css";
import React, { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Back, Close } from "~/assets/svg";
import { REQUEST_METHOD, colorByMethod, jsonIsValid, observer, useStores } from "~/utils";
import ActionsLine from "../ActionsLine";
import {
  CardMethod,
  Content,
  Field,
  Header,
  Row,
  SectionMethods,
  TabStyled,
  SectionTitle,
  TextArea,
  Title,
  SectionTabs,
} from "./styles";
import { colors } from "~/theme";
import Tabs from "@mui/material/Tabs";
import If from "../If";

type Props = {
  onClose?: () => void;
  goBack?: () => void;
  uid?: string;
  name?: string;
};

const VariablesConfigRequest: FC<Props> = ({ onClose = () => {}, goBack = () => {}, uid = "", name = "" }) => {
  const { variables } = useStores();
  const [form, setForm] = useState<Partial<Variables.Form>>({});
  const [tab, setTab] = useState<number | null>(0);

  const onSetTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const onChange = (event: any) => {
    const { value, name } = event.target;

    variables.onChangeValueConfigRequest({
      uid,
      [name]: value,
    });

    setForm((old) => ({ ...old, [name]: value }));
  };

  const onChangeMethod = (mtd: string) => {
    setForm((old) => ({ ...old, method: mtd }));

    variables.onChangeValueConfigRequest({
      uid,
      method: mtd,
    });
  };

  const onChangeTextArea = (event: any) => {
    const { name, value } = event.target;

    variables.onChangeValueConfigRequest({
      uid,
      [name]: value,
    });

    setForm((old) => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    const configRequest = variables.configRequest.find((config) => config?.uid === uid);
    if (!configRequest) return;

    setForm({
      ...configRequest,
      body: configRequest.body,
      header: configRequest.header,
    });

    onChangeMethod(configRequest.method || "GET");
  }, []);

  const onGoBack = () => {
    if (!form.url || !form.return_key || !form.method) {
      toast("URL, Filtrar e Método são obrigatórios", {
        icon: "🚨",
      });
      return;
    }

    goBack();
  };

  const onCheckJson = (data: string, msg: string) => {
    if (!jsonIsValid(data)) {
      toast(msg, {
        icon: "🚨",
      });
    }
  };

  return (
    <Content>
      <Header>
        <SectionTitle>
          <Back onPress={onGoBack} color={colors.colors.light} />
          <Title onClick={onGoBack}>Configurar Request: {name}</Title>
        </SectionTitle>

        <Close onPress={onClose} color={colors.colors.light} />
      </Header>

      <Row>
        <Field name="url" value={form.url} required placeholder="" label="URL" onChange={onChange} />

        <Field
          name="return_key"
          value={form.return_key}
          required
          placeholder=""
          label="Filtrar Retorno"
          onChange={onChange}
          iconText="faq"
          iconColor={colors.colors.light}
          textDescribe={`
            Filtrar retorno Indique a chave do retorno da requisição que terá seu
            valor extraído para posteriormente ser associado a variável criada. A
            chave pode ser um valor único como também um json path. Exemplos: "token", "user.name", "data.0.id"
            `}
        />
      </Row>

      <SectionMethods>
        {REQUEST_METHOD.map((mtd) => (
          <CardMethod
            selected={form?.method === mtd}
            onClick={() => onChangeMethod(mtd)}
            color={colorByMethod(mtd)}
            key={mtd}
          >
            {mtd}
          </CardMethod>
        ))}
      </SectionMethods>

      <ActionsLine title="" buttonText="" onPress={() => {}} />

      <SectionTabs>
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.colors.primary,
            },
          }}
          value={tab}
          onChange={onSetTab}
          textColor="primary"
          style={{
            width: "80%",
            marginBottom: 10,
          }}
        >
          <TabStyled label="Headers" />
          <TabStyled label="Body" />
        </Tabs>

        <If condition={tab === 0}>
          <TextArea
            name="header"
            onChange={onChangeTextArea}
            value={form.header}
            error={!jsonIsValid(form.header || "{}")}
            onBlur={() => onCheckJson(form.header || "{}", "Header: Formato JSON inválido")}
          />
        </If>

        <If condition={tab === 1}>
          <TextArea
            error={!jsonIsValid(form.body || "{}")}
            onBlur={() => onCheckJson(form.body || "{}", "Body: Formato JSON inválido")}
            name="body"
            onChange={onChangeTextArea}
            value={form.body}
          />
        </If>
      </SectionTabs>
    </Content>
  );
};
export default observer(VariablesConfigRequest);
