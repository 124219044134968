import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { observer, parsedPathParamsToArray, parsedURLToPathParams, useStores } from "~/utils";
import { CardParam, Content, FieldVariables, Field, SectionParams } from "./styles";

type Props = {
  id: number;
  URL: string;
  pathParam: Record<string, string>;
};

const PathParam: FC<Props> = ({ id, URL, pathParam }) => {
  const { formulation } = useStores();

  const {
    register,
    formState: { errors },
  } = useForm<Formulation.PathParam>();

  const onChangeValuePathParam = ({ name, value }: Formulation.Body) => {
    formulation.onChangeMethodsData({
      id: formulation.idMethodSelected,
      path_param: parsedURLToPathParams(URL, { ...pathParam, [name]: value }),
    });
  };

  return (
    <Content>
      <SectionParams>
        {parsedPathParamsToArray(pathParam)?.map(({ name, value }) => (
          <CardParam key={name}>
            <Field
              {...register("name", { required: true })}
              label="Nome do Parâmetro"
              value={name}
              disabled
              onChange={(event) => {
                onChangeValuePathParam({
                  name,
                  value: event.target.value,
                });
              }}
            />

            <FieldVariables
              name={name}
              label="Valor do Parâmetro"
              initialValue={value}
              onChange={({ name, value }) =>
                onChangeValuePathParam({
                  name,
                  value,
                })
              }
              error={!!errors.value?.message || !!errors.value?.type}
            />
          </CardParam>
        ))}
      </SectionParams>
    </Content>
  );
};

export default observer(PathParam);
