import Reactotron from "reactotron-react-js";
import { REACT_APP_ENV } from "~/utils";

declare global {
  interface Console {
    tron: any;
  }
}

if (REACT_APP_ENV === "DEV") {
  const tron = Reactotron.configure({
    name: "React App",
  }).connect();

  (console as any).tron = tron;
}
