import React, { FC } from "react";

type Props = {
  done?: boolean;
  onPress?: () => void;
  width?: number;
  style?: React.CSSProperties;
};

const Icon: FC<Props> = ({
  width = 45,
  style = { cursor: "pointer", alignSelf: "center", margin: 10, marginTop: 25 },
  done,
  onPress,
  ...rest
}) => (
  <svg
    {...rest}
    onClick={onPress}
    style={style}
    width={width}
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 0C8.95458 0 0 8.95513 0 20C0 31.0454 8.95477 40 20 40C31.0452 40 40 31.0452 40 20C40 8.95477 31.0452 0 20 0ZM30.8005 21.5352H21.5352V30.8005C21.5352 31.6483 20.8481 32.3358 19.9999 32.3358C19.1521 32.3358 18.4646 31.6487 18.4646 30.8005V21.5352H9.19931C8.35152 21.5352 7.66399 20.8481 7.66399 19.9999C7.66399 19.1521 8.35114 18.4646 9.19931 18.4646H18.4646V9.19931C18.4646 8.35152 19.1517 7.66399 19.9999 7.66399C20.8477 7.66399 21.5352 8.35114 21.5352 9.19931V18.4646H30.8005C31.6483 18.4646 32.3358 19.1517 32.3358 19.9999C32.3358 20.8477 31.649 21.5352 30.8005 21.5352Z"
      fill="#FD784E"
    />
  </svg>
);

export default Icon;
