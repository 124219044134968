import { action, makeObservable, observable, runInAction } from "mobx";
import Root from "./index";
import { makePersistable } from "mobx-persist-store";
import toast from "react-hot-toast";

export default class Store {
  @observable
  visibleModalTestCase = false;

  @observable
  referenceMethod = "";

  @observable
  data: TestCase.Data[] = [];

  emptyEditing: TestCase.Data = {
    editMode: false,
    referenceMethod: "",
    uid: "",
    name: "",
    description: "",
    payload: "",
    field: "",
    testType: "",
    response: {},
    statusCode: "",
    values_to_change: [],
  };

  @observable
  editing: TestCase.Data | null = this.emptyEditing;

  root: typeof Root;

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);

    makePersistable(this, {
      name: "TestCase",
      properties: ["data"],
      storage: window.localStorage,
    });
  }

  @action
  setVisibleModalTestCase = (visible: boolean): void => {
    this.visibleModalTestCase = visible;
  };

  @action
  setReference = (referenceMethod: string): void => {
    this.referenceMethod = referenceMethod;
  };

  @action
  list = (): TestCase.Data[] => {
    return this.data.filter((item) => item.referenceMethod === this.referenceMethod).reverse();
  };

  @action
  onChange = ({ value, name }: TestCase.ChangeParams) => {
    runInAction(() => {
      if (this.editing) {
        this.editing = {
          ...this.editing,
          [name]: value,
        };
      }
    });
  };

  @action
  onChangeResponse = (value: any, name: string): void => {
    runInAction(() => {
      if (this.editing) {
        this.editing = {
          ...this.editing,
          response: {
            ...this.editing.response,
            [name]: value,
          },
        };
      }
    });
  };

  @action
  onRemoveResponse = (name: string): void => {
    let response = this.editing?.response;
    delete response![name];

    runInAction(() => {
      if (this.editing) {
        this.editing = {
          ...this.editing,
          response: response! || {},
        };
      }
    });
  };

  @action
  onSetEditing = (dt: TestCase.Data | null): void => {
    this.editing = dt ? dt : this.emptyEditing;
  };

  @action
  editTestData = (): void => {
    runInAction(() => {
      this.data = this.data.map((item) =>
        item.uid === this.editing?.uid && item.referenceMethod === this.referenceMethod
          ? {
              ...this.editing!,
              referenceMethod: this.referenceMethod,
            }
          : item
      );
    });
  };

  @action
  addTest = (): void => {
    runInAction(() => {
      this.data = [
        ...this.data,
        {
          ...this.editing!,
          referenceMethod: this.referenceMethod,
        },
      ];
    });
  };

  @action
  removeTest = (uid: string): void => {
    this.data = this.data.filter((item) => item.uid !== uid && item.referenceMethod === this.referenceMethod);
  };

  @action
  onShowData = (dt: any): void => {
    runInAction(() => {
      this.data = dt;
    });
  };

  @action
  onValueToChangeAdd = (data: Omit<TestCase.ValuesToChange, "uid">): boolean => {
    try {
      runInAction(() => {
        if (this.editing) {
          this.editing = {
            ...this.editing,
            values_to_change: [
              ...this.editing.values_to_change,
              {
                ...data,
                uid: Date.now(),
              },
            ],
          };
        }
      });

      return true;
    } catch (error) {
      return false;
    }
  };

  @action
  onValueToChangeRemove = (uid: number): void => {
    runInAction(() => {
      if (this.editing) {
        this.editing = {
          ...this.editing,
          values_to_change: this.editing.values_to_change.filter((item) => item.uid !== uid),
        };
      }
    });
  };

  @action
  onValueToChangeEdit = (data: Partial<TestCase.ValuesToChange>): void => {
    runInAction(() => {
      if (this.editing) {
        this.editing = {
          ...this.editing,
          values_to_change: this.editing.values_to_change.map((item) =>
            item.uid === data?.uid ? { ...item, ...data } : item
          ),
        };
      }
    });
  };
}
