import React, { FC } from "react";

type Props = {
  size?: number;
  color?: string;
};

const Icon: FC<Props> = ({ size = 35, color = "#F0F8FF" }) => (
  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 6L0.602886 0L8.39711 0L4.5 6Z" fill={color} />
  </svg>
);

export default Icon;
