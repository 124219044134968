import "react-tooltip/dist/react-tooltip.css";
import React from "react";
import { createBrowserHistory } from "history";
import { Provider } from "mobx-react";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import ReactDOM from "react-dom";
import toast, { Toaster } from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import { ThemeProvider } from "styled-components";
import Routes, { RouterProvider } from "~/routes";
import stores from "~/stores";
import { breakpoints, colors, device, sizes, spacings } from "~/theme";
import "~/config/reactotron";
import reportWebVitals from "./reportWebVitals";

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();
const routing = syncHistoryWithStore(browserHistory, routingStore);

const theme = {
  ...breakpoints,
  ...device,
  ...sizes,
  ...spacings,
  ...colors,
};

const store = {
  ...stores,
  routing,
};

ReactDOM.render(
  <React.StrictMode>
    <Provider {...store} root={store}>
      <ThemeProvider theme={theme}>
        <Toaster
          toastOptions={{
            iconTheme: {
              primary: colors.colors.light,
              secondary: "#222",
            },
            error: {
              style: {
                backgroundColor: "#DC3F4A",
                borderRadius: 2,
                color: colors.colors.light,
              },
            },
            success: {
              style: {
                backgroundColor: "#0B8B46",
                borderRadius: 2,
                color: colors.colors.light,
              },
            },
          }}
        />
        <RouterProvider routing={routing}>
          <Tooltip id="tooltip-general" />
          <Routes />
        </RouterProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
