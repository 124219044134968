import React, { FC, useState } from "react";
import { Header, Menu, Variables } from "~/components";
import { MENU, useStores } from "~/utils";
import { Content, BodyPage, Children } from "./styles";
import { Path } from "~/routes/routes.path";

type Props = {
  children: React.ReactNode;
  page?: keyof typeof MENU;
};

const Scene: FC<Props> = ({ children, page = "HOME" }) => {
  const [modalVariables, setModalVariables] = useState(false);

  const { routing } = useStores();

  const onHome = () => routing.replace(Path.HOME);
  const onRequest = () => routing.replace(Path.FORMULATION);
  const onVariables = () => setModalVariables(!modalVariables);

  return (
    <>
      <Header title="API Test Generator"/>
      <Content>
        <Menu onVariables={onVariables} onHome={onHome} onRequest={onRequest} optionEnabled={page} />
        <Children>{children}</Children>
      </Content>
      <Variables visible={modalVariables} onClose={onVariables} />
    </>
  );
};

export default Scene;
