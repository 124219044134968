import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: 38px;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
`;

export const PropertyBlock = styled.div<{ isSelected?: boolean }>`
  height: 27px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 4px;
  padding: 0 4px;
  user-select: none;

  background: ${(props) =>
    props.isSelected ? "rgb(0, 0, 0, 0.2)" : "rgb(0, 0, 0, 0)"};

  &:hover {
    background-color: rgb(0, 0, 0, 0.4);
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 96%;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchInput = styled.input`
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const SearchInputWrapper = styled.div`
  border: 1px solid black;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 4px;
  box-sizing: border-box;
  border-radius: 10px;
`;

export const HistoryControls = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  width: 96%;
  overflow-y: hidden;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
