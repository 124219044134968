import { action, makeObservable, observable } from "mobx";
import { persist } from "mobx-persist";
import { Storage } from "~/services";
import Root from "./index";

export default class UserStore {
  @observable
  data: User = {
    email: "",
    password: "",
  };

  root: typeof Root;

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);
  }

  // @action
  // login = async (credentials: User): Promise<void> => {
  //   Storage.setToken(credentials.email);
  // };
}
