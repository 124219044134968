import React, { FC } from "react";
import { Button, Content, Title } from "./styles";

type Props = {
  title?: string;
  onPress?: () => void;
  buttonText?: string;
};

const ActionsLine: FC<Props> = ({ buttonText, onPress, title }) => (
  <Content>
    <Title>{title}</Title>
    <Button onClick={onPress}>{buttonText}</Button>
  </Content>
);

export default ActionsLine;
