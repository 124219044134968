import React, { FC } from "react";

type Props = {
  size?: number;
  color?: string;
};

const Icon: FC<Props> = ({ size = 35, color = "#F0F8FF" }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 256 256">
    <path d="M219.31,108.68l-80-80a16,16,0,0,0-22.62,0l-80,80A15.87,15.87,0,0,0,32,120v96a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V120A15.87,15.87,0,0,0,219.31,108.68ZM208,208H48V120l80-80,80,80Z" />
  </svg>
);

export default Icon;
