import { getTheme, ifStyle, pxToRem, styled } from "~/utils";
import Tab from "@mui/material/Tab";

// Spacings
const minimalSpacing = getTheme("minimalSpacing");
const smallSpacing = getTheme("smallSpacing");

// Colors
const primary = getTheme("colors.primary");
const secondary = getTheme("colors.secondary");
const light = getTheme("colors.light");
const info = getTheme("info");
const contrast = getTheme("colors.contrast");

const isDisabled = ifStyle("disabled");

export const Content = styled.div`
  min-width: 27%;
  max-width: 27%;
  min-height: 100vh;
  display: flex;
  /* box-sizing: border-box; */
  flex-direction: column;
  background-color: ${secondary};
  padding: 0px ${minimalSpacing};
  overflow-x: hidden;
  border-right: 0.5px solid ${light}50;
`;

export const Title = styled.h2`
  color: ${light};
  font-weight: 500;
  font-size: ${pxToRem(24)};
  margin: ${minimalSpacing} 0px;
  margin-bottom: ${smallSpacing};
  padding-bottom: ${minimalSpacing};
  border-bottom: 0.3px solid ${light}50;
`;

export const SectionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

type Props = {
  disabled?: boolean;
};

export const Button = styled.button<Props>`
  border: 0;
  width: 30%;
  height: ${pxToRem(40)};
  border: 1px solid ${info};
  background-color: transparent;
  border-radius: 2px;
  color: ${info};
  font-size: ${pxToRem(18)};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${isDisabled(0.5, 1)};

  &:hover {
    background-color: ${isDisabled("transparent", info)}50;
    color: ${isDisabled(light, info)};
    transition: 0.5s;
  }
`;

export const SectionMethods = styled.section`
  display: flex;
  flex-direction: column;
  scrollbar-color: ${contrast} ${secondary};
  margin-top: ${smallSpacing};
`;

export const SectionBody = styled.section`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  max-height: 100vh;
`;

export const TextTouchable = styled.button`
  text-align: center;
  color: ${info};
  font-size: ${pxToRem(16)};
  cursor: pointer;
  padding: ${minimalSpacing};
  margin-bottom: ${minimalSpacing};
  font-weight: 400;
  display: flex;
  align-items: center;

  background-color: transparent;
  border: 0;

  z-index: 1;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`;

export const SectionTabs = styled.div`
  width: 73%;
  height: 92%;
`;

export const TabStyled = styled(Tab)`
  width: 25%;
  color: ${light};

  &.MuiTab-textColorPrimary {
    color: ${light};
  }

  &.MuiTab-textColorPrimary.Mui-selected {
    color: ${primary};
  }

  &.MuiTab-textColorPrimary.Mui-disabled {
    color: ${light};
    opacity: 0.5;
  }
`;
