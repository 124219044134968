import { getTheme, ifStyle, pxToRem, styled } from "~/utils";

const contrast = getTheme("colors.contrast");
const secondary = getTheme("colors.secondary");
const light = getTheme("colors.light");
const smallSpacing = getTheme("smallSpacing");
const minSpacing = getTheme("minSpacing");
const primeblue = getTheme("colors.primeblue");

// Ifs
const isEnabled = ifStyle("enabled");
const isDisabed = ifStyle("disabled");

export const Content = styled.div`
  background-color: ${primeblue};
  width: 6rem;
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 20px 0px ${secondary};
  z-index: 1;
  box-sizing: border-box;
  padding: ${smallSpacing} 0px;
`;

type PropsItem = {
  enabled?: boolean;
  disabled?: boolean;
};

export const Items = styled.div`
  width: 100%;
`;

export const Item = styled.div<PropsItem>`
  width: 100%;
  min-height: ${pxToRem(120)};
  background-color: ${isEnabled(light)}35;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${isDisabed(0.5, 1)};
  cursor: ${isDisabed("default", "pointer")};

  &:hover {
    background-color: ${isDisabed("transparent", isEnabled("transparent", light))}05;
  }
`;

export const Text = styled.h5`
  font-weight: 400;
  font-size: ${pxToRem(12)};
  color: ${light};
  margin-top: ${minSpacing};
  text-align: center;
`;
