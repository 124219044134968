import React, { FC } from "react";
// import { Wrapper } from './styles';

type Props = {
  onPress: () => void;
  color?: string;
};

const Icon: FC<Props> = ({ onPress, color = "#252948" }) => (
  <svg
    onClick={onPress}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill={color}
    viewBox="0 0 256 256"
    style={{ cursor: "pointer" }}
  >
    <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z" />
  </svg>
);

export default Icon;
