import React, { FC } from "react";

type Props = {
  onPress: () => void;
  width?: number;
  height?: number;
  color?: string;
  isDisabled?: boolean;
};

const Icon: FC<Props> = ({ width = 15, height = 45, onPress, color = "#FD784E", isDisabled = false }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 45"
    fill="none"
    opacity={isDisabled ? 0.5 : 1}
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: isDisabled ? "initial" : "pointer", marginRight: 10 }}
    onClick={() => !isDisabled && onPress()}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.808088 24.4535L20.4787 44.1916C21.6238 45.2695 23.3754 45.2695 24.4534 44.1916C25.5312 43.1138 25.5312 41.295 24.4534 40.217L6.73635 22.5L24.4534 4.78298C25.5312 3.63794 25.5312 1.88635 24.4534 0.808355C23.3756 -0.269452 21.624 -0.269452 20.4787 0.808355L0.808088 20.479C-0.269718 21.624 -0.269718 23.3756 0.808088 24.4536V24.4535Z"
      fill={color}
    />
  </svg>
);

export default Icon;
