import { RouterStore } from "mobx-react-router";
import AppStore from "./app.store";
import FormStore from "./form.store";
import FormulationStore from "./formulation.store";
import JsonEditorStore from "./json-editor.store";
import UserStore from "./user.store";
import HeadersStore from "./headers.store";
import QueryStore from "./query.store";
import BodyStore from "./body.store";
import PathParameterStore from "./path-parameter.store";
import VariablesStore from "./variables.store";
import TestCaseStore from "./test-case.store";

class RootStore {
  user: UserStore;

  app: AppStore;

  form: FormStore;

  formulation: FormulationStore;

  jsonEditor: JsonEditorStore;

  variables: VariablesStore;

  headers: HeadersStore;

  query: QueryStore;

  body: BodyStore;

  pathParameter: PathParameterStore;

  testCase: TestCaseStore;

  constructor() {
    this.user = new UserStore(this);
    this.app = new AppStore(this);
    this.form = new FormStore(this);
    this.formulation = new FormulationStore(this);
    this.jsonEditor = new JsonEditorStore(this);
    this.variables = new VariablesStore(this);
    this.headers = new HeadersStore(this);
    this.query = new QueryStore(this);
    this.body = new BodyStore(this);
    this.pathParameter = new PathParameterStore(this);
    this.testCase = new TestCaseStore(this);
  }
}

const store = new RootStore();

export {
  RouterStore,
  UserStore,
  AppStore,
  FormStore,
  FormulationStore,
  JsonEditorStore,
  VariablesStore,
  HeadersStore,
  QueryStore,
  BodyStore,
  PathParameterStore,
  TestCaseStore,
};

export default store;
