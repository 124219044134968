import { getTheme, pxToRem, styled } from "~/utils";

// Colors
const primary = getTheme("colors.primary");
const secondary = getTheme("colors.secondary");

// Spacings
const smallSpacing = getTheme("smallSpacing");

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${smallSpacing} 0px;
`;

export const Title = styled.h4`
  font-size: ${pxToRem(20)};
  color: ${secondary};
  font-weight: 400;
`;

export const Button = styled.button`
  border: 0;
  background-color: transparent;
  font-size: ${pxToRem(20)};
  color: ${primary};
  font-weight: 400;
  cursor: pointer;
`;
