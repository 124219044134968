import React, { FC, useState } from "react";
import ReactLoading from "react-loading";
import {
  If,
  Method,
  ModalAddRules,
  TabHeader,
  HeadersTabRequest,
  TabBody,
  TabQuery,
  TabPathParam,
  TestCase,
} from "~/components";
import { generateIdCaseTest, observer, useStores } from "~/utils";
import { Content, SectionMethods, Title, Button, SectionButtons, SectionTabs, SectionBody } from "./formulation.styles";
import Scene from "../Scene";
import { TabStyled } from "./formulation.styles";
import Tabs from "@mui/material/Tabs";
import { colors } from "~/theme";
import { isEmpty } from "lodash";

const Formulation: FC = () => {
  const { formulation, testCase } = useStores();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<number>(0);
  const [showSelected, setShowSelected] = useState(false);
  const [tab, setTab] = useState<number | null>(0);

  const onAddMethod = () => {
    const referenceMethod = formulation.onNewMethodBlank();

    testCase.setReference(referenceMethod.toString());

    testCase.onSetEditing({
      uid: generateIdCaseTest(testCase.data.map(({ uid }) => uid)),
      editMode: false,
      name: "Validar Status Code",
      description: "Validar Status code | 200",
      payload: "",
      field: "",
      testType: "Status Code",
      response: {},
      statusCode: "200",
      values_to_change: [],
    });

    testCase.addTest();
  };

  const onSelect = (id: number) => {
    setSelected(id);
    setShowSelected(true);
    formulation.onSetIDMethodSelected(id);
    testCase.setReference(id?.toString());
  };

  const onProcess = async () => {
    try {
      setLoading(true);
      await formulation.onSendJsonAPI();
    } catch (error) {
      // ..
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    const attachment = await formulation.onDownloadFile();

    if (attachment) window.open(attachment);
  };

  const onRemove = (id: number) => {
    formulation.onRemoveMethod(id);
    setTab(null);
    setShowSelected(false);
  };

  const onSetTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const pathParams = formulation.onGetMethod(selected)?.path_param || {};

  const enabledDownloadButton = !!formulation.idAttachment;
  const enabledProcessButton = !!formulation.methods.length;

  return (
    <>
      <Scene page="REQUEST">
        <SectionBody>
          <Content>
            <Title>Requisições</Title>
            <SectionButtons>
              <Button onClick={onAddMethod}>Adicionar</Button>

              <Button onClick={onProcess} disabled={!enabledProcessButton}>
                <If condition={!loading}>Processar</If>

                <If condition={loading}>
                  <ReactLoading type="spinningBubbles" width={20} height={20} />
                </If>
              </Button>

              <Button disabled={!enabledDownloadButton} onClick={onDownload}>
                Download
              </Button>
            </SectionButtons>

            <SectionMethods>
              {formulation.methods.map((data) => (
                <Method
                  selected={selected === data.id}
                  key={data.id}
                  id={data.id}
                  name={data.name}
                  method={data.method}
                  URL={data.URL}
                  headersRequest={data.headers}
                  headersResponse={data.response_params?.header_response}
                  body={data.body}
                  pathParam={data.path_param}
                  query={data.query}
                  formData={data.formData}
                  idAttachment={data.idAttachment}
                  onPress={() => onSelect(data.id)}
                  onRemove={onRemove}
                />
              ))}
            </SectionMethods>
          </Content>

          <If condition={showSelected}>
            <SectionTabs>
              <HeadersTabRequest
                onProcess={onProcess}
                onRemove={() => onRemove(selected)}
                onDownload={onDownload}
                id={selected}
                enableDownload={!!formulation.onGetMethod(selected)?.idAttachment || false}
                name={formulation.onGetMethod(selected)?.name || ""}
                URL={formulation.onGetMethod(selected)?.URL || ""}
                method={formulation.onGetMethod(selected)?.method || ""}
              />

              <Tabs
                TabIndicatorProps={{
                  style: {
                    backgroundColor: colors.colors.primary,
                  },
                }}
                value={tab}
                onChange={onSetTab}
                textColor="primary"
              >
                <TabStyled label="Headers" />
                <TabStyled label="Body" />
                <TabStyled label="Query" />
                <TabStyled label="Path Parameter" disabled={isEmpty(pathParams)} />
              </Tabs>

              <If condition={tab === 0}>
                <TabHeader id={selected} headers={formulation.onGetMethod(selected)?.headers || {}} />
              </If>

              <If condition={tab === 1}>
                <TabBody
                  id={selected}
                  body={formulation.onGetMethod(selected)?.body || []}
                  formData={formulation.onGetMethod(selected)?.formData || []}
                />
              </If>

              <If condition={tab === 2}>
                <TabQuery id={selected} query={formulation.onGetMethod(selected)?.query || []} />
              </If>

              <If condition={tab === 3}>
                <TabPathParam id={selected} URL={formulation.onGetMethod(selected)?.URL || ""} pathParam={pathParams} />
              </If>
            </SectionTabs>
          </If>
        </SectionBody>
      </Scene>

      {/* Modals */}
      <TestCase
        headers={formulation.onGetMethod(selected)?.headers}
        formData={formulation.onGetMethod(selected)?.formData}
        query={formulation.onGetMethod(selected)?.query}
        bodyJson={formulation.onGetMethod(selected)?.bodyJson}
        pathParam={pathParams}
      />
      <ModalAddRules />
    </>
  );
};

export default observer(Formulation);
