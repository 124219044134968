import Select from "react-select";
import { getTheme, pxToRem, styled } from "~/utils";
import TextField from "../TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MUITextField from "@mui/material/TextField";
import Typograph from "../Typograph";

type SelectProps = {
  error?: boolean;
};

// Colors
const greenPool = getTheme("colors.greenPool");
const secondary = getTheme("colors.secondary");
const grayBg = getTheme("colors.grayBg");
const contrast = getTheme("colors.contrast");
const light = getTheme("colors.light");

// Radius
const cardRadius = pxToRem(10);

// Spacing
const minimalSpacing = getTheme("minimalSpacing");
const smallSpacing = getTheme("smallSpacing");

export const Content = styled.div`
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${secondary}98;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3;
`;

export const Title = styled(Typograph).attrs((props) => ({ iconColor: light(props) }))`
  color: ${light};
`;

export const Section = styled.div`
  width: 50%;
  min-height: 10vh;
  max-height: 90vh;
  background-color: ${secondary};
  padding: ${minimalSpacing};
  padding-bottom: ${smallSpacing};
  border-radius: ${cardRadius};
  border: 1px solid ${contrast};
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-color: ${contrast} ${secondary};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${smallSpacing};
`;

export const SectionFields = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SectionResponseAdded = styled.div`
  margin-top: ${smallSpacing};
  padding-bottom: ${smallSpacing};
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const FieldFullWidth = styled(TextField)`
  width: 100%;
  margin-top: ${minimalSpacing};
`;

export const Field = styled(TextField)`
  margin-top: ${minimalSpacing};
  width: 45%;
`;

export const FieldSmall = styled(TextField)`
  margin-top: ${minimalSpacing};
  width: 30%;
`;

export const SectionSelect = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-top: ${minimalSpacing};
`;

export const AutocompleteStyled = styled(Autocomplete)`
  width: 100%;
  border-radius: ${pxToRem(4)};
  margin-top: ${minimalSpacing};

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
`;

export const MUITextFieldStyled = styled(MUITextField)`
  background-color: ${contrast};
  border-radius: ${pxToRem(4)};

  & .MuiInputBase-root {
    color: ${light};
    height: ${pxToRem(52)};
  }
`;

export const Button = styled.button`
  border: 0;
  width: ${pxToRem(180)};
  height: ${pxToRem(40)};
  background-color: ${greenPool};
  color: ${light};
  cursor: pointer;

  &:hover {
    background-color: ${light}50;
    color: ${light};
    transition: 0.3s;
  }
`;

export const Label = styled.h4`
  font-size: ${pxToRem(16)};
  color: ${light};
  font-weight: 400;
`;

export const SectionsAdded = styled.div`
  width: 100%;
  max-height: 80vh;
  border-radius: ${cardRadius};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

export const Block = styled.div`
  width: 100%;
  height: ${pxToRem(80)};
  margin-bottom: ${smallSpacing};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${contrast};
  border-radius: ${pxToRem(5)};
  padding: ${pxToRem(5)};
  box-sizing: border-box;
  padding: ${minimalSpacing};
`;

export const SectionText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SectionBtns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 3.5rem;
`;

export const Text = styled.h3`
  font-weight: 400;
  font-size: ${pxToRem(18)};
  color: ${light};
  padding-right: ${pxToRem(15)};
  border-right-color: ${contrast};
  border-right-width: 2px;
  border-right-style: solid;
`;

export const Paragraph = styled.h3`
  font-weight: 400;
  padding-left: ${pxToRem(15)};
  font-size: ${pxToRem(18)};
  color: ${light};
`;

export const SectionValuesToChangeAdded = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-top: 10px;
  border-top: 2px solid ${contrast};
`;
