/* eslint-disable no-param-reassign */
import React, { FC, useCallback, useEffect, useState } from "react";
import { first } from "lodash";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";
import ReactLoading from "react-loading";
import { ClipboardIcon, StorageIcon, UploadIcon } from "~/assets/svg";
import { AppInfo, Dynamic, If } from "~/components";
import { Path } from "~/routes/routes.path";
import { colors } from "~/theme";
import { observer, useStores } from "~/utils";
import { Content, SectionCard, Divider, Paragraph, ContentRow, TextCard, Card, Title, Text } from "./home.styles";
import Scene from "../Scene";

const Home: FC = () => {
  const { routing, formulation } = useStores();
  const [loading, setLoading] = useState(false);
  const [dynamic, setDynamic] = useState(false);
  const [content, setContent] = useState("");

  const onStepable = () => routing.push(Path.FORMULATION);

  const onDynamic = () => setDynamic(!dynamic);

  const onSendContentFile = async () => {
    setLoading(true);
    const success = await formulation.onSendContentFile(content);
    if (success) routing.push(Path.FORMULATION);
    setLoading(false);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return;

    const file = first(acceptedFiles);

    const reader = new FileReader();

    reader.onloadend = () => {
      try {
        if (!JSON.parse(reader.result as string)) return;
        setContent(reader.result as string);
      } catch (error) {
        toast("Arquivo JSON inválido, revise o arquivo e tente novamente.", {
          position: "top-center",
          icon: "⚠️",
        });
      }
    };

    reader.readAsText(file!);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (!content) return;
    onSendContentFile();
  }, [content]);

  return (
    <>
      <Scene>
        <Content>
          <div>
            <Title>Olá,</Title>
            <Text>
              Você está no ambiente <b>API Test Generator</b>
            </Text>
            <Text>Selecione uma opção abaixo para iniciar a configuração dos cenários de requisição:</Text>
          </div>

          <ContentRow>
            <SectionCard>
              <Card onClick={onDynamic}>
                <TextCard>
                  <ClipboardIcon /> &nbsp; Inserir Arquivo
                </TextCard>

                <Divider />
                <Paragraph>
                  Cole o texto em formato JSON, cURL, Open API, Swagger e Postman, num campo aberto.
                </Paragraph>
              </Card>

              <Card {...getRootProps()}>
                <If condition={loading}>
                  <ReactLoading type="spinningBubbles" width={60} height={60} color={colors.colors.primary} />
                </If>

                <If condition={!loading}>
                  <TextCard>
                    <UploadIcon /> &nbsp; Importar Arquivo
                  </TextCard>

                  <Divider />
                  <Paragraph>Selecione um arquivo para ser importado a partir do seu computador.</Paragraph>
                </If>

                <input {...getInputProps()} accept=".json" />
              </Card>

              <Card onClick={onStepable}>
                <TextCard>
                  <StorageIcon /> &nbsp; Cadastrar Manualmente
                </TextCard>
                <Divider />
                <Paragraph>
                  Clique para incluir dados manualmente e configure a requisição com os parâmetros desejados.{" "}
                </Paragraph>
              </Card>
            </SectionCard>
          </ContentRow>
        </Content>
      </Scene>

      <Dynamic visible={dynamic} onClose={() => setDynamic(false)} />
      <AppInfo />
    </>
  );
};

export default observer(Home);
