import React, { FC, useEffect, useState } from "react";
import { CloseSecondary, Trash } from "~/assets/svg";
import { colors } from "~/theme";
import If from "../If";

type Props = {
  key: string;
  width?: number;
  onDelete: () => void;
  style?: any;
};

const ConfirmDelete: FC<Props> = ({ key, width = 25, onDelete, ...rest }) => {
  const [selected, setSelected] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setSelected("");
    }, 5000);
  }, [selected]);

  return (
    <div {...rest}>
      <If condition={selected === key}>
        <Trash
          onPress={onDelete}
          data-tooltip-id="tooltip-general"
          data-tooltip-content="Confirmar remoção"
          width={width}
        />
      </If>

      <If condition={selected !== key}>
        <CloseSecondary
          onPress={() => setSelected(key)}
          color={colors.failure}
          width={width}
          data-tooltip-id="tooltip-general"
          data-tooltip-content="Remover"
        />
      </If>
    </div>
  );
};

export default ConfirmDelete;
