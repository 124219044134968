import React, { FC, useState } from "react";
import { Tooltip } from "react-tooltip";
import { icons } from "~/assets/svg";
import { observer, useStores } from "~/utils";
import If from "../If";
import { Span, Text } from "./styles";

type Props = {
  children: string | number;
  iconText?: keyof typeof icons;
  textDescribe?: string;
  iconColor?: string;
};

const Typograph: FC<Props> = ({ children, iconText = "", textDescribe, iconColor, ...rest }) => {
  const [showDescribe, setShowDescribe] = useState(false);
  const { variables } = useStores();

  const content = children.toString();

  const parts = content.split(/(\$\w+)/);

  const Icon = (icons as any)[iconText] ?? null;

  return (
    <>
      <Tooltip id="tooltip-icons" style={{ width: 300, zIndex: 1 }} />

      <Text {...rest}>
        {parts.map((part) =>
          part.startsWith("$") ? (
            <Span notExist={!variables.list.some((e) => e === part.replace("$", ""))} key={part}>
              {part.replace("$", "")}
            </Span>
          ) : (
            part
          )
        )}
        &nbsp;
        <If condition={!!Icon}>
          <Icon
            onMouseOver={() => setShowDescribe(true)}
            onMouseOut={() => setShowDescribe(false)}
            tooltip={textDescribe}
            color={iconColor}
          />
        </If>
      </Text>
    </>
  );
};

export default observer(Typograph);
