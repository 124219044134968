import styled from "styled-components";
import { getTheme, pxToRem } from "~/utils";

const light = getTheme("text.light");
const mediumSpacing = getTheme("mediumSpacing");

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ${mediumSpacing};
`

export const Text = styled.span`
    font-weight: 400;
    font-size: ${pxToRem(20)};
    color: ${light};
    padding-right: ${pxToRem(12)};
`