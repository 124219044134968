import Select from "react-select";
import { getTheme, pxToRem, styled } from "~/utils";
import TextField from "../TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MUITextField from "@mui/material/TextField";

type SelectProps = {
  error?: boolean;
};
// Colors
const light = getTheme("colors.light");
const secondary = getTheme("colors.secondary");
const contrast = getTheme("colors.contrast");
const grayBg = getTheme("colors.grayBg");

// Radius
const cardRadius = pxToRem(10);

// Spacing
const minimalSpacing = getTheme("minimalSpacing");
const smallSpacing = getTheme("smallSpacing");

export const Content = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${secondary}90;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
`;

export const Section = styled.div`
  width: 70%;
  min-height: 20vh;
  max-height: 90vh;
  background-color: ${secondary};
  padding: ${minimalSpacing};
  border-radius: ${cardRadius};
  border: 2px solid ${contrast};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${smallSpacing};
`;

export const SectionFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Field = styled(TextField).attrs({
  containerStyle: {
    width: "33%",
  },
  styleField: {
    width: "90%",
  },
})``;

export const Title = styled.h4`
  font-weight: 400;
  color: ${light};
  font-size: ${pxToRem(25)};
  width: 95%;
  word-wrap: break-word;
`;

export const Blocks = styled.div`
  width: 100%;
  margin: ${smallSpacing} 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 50vh;
`;

export const SectionSelect = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`;

export const SelectStyled = styled(Select).attrs((props: SelectProps) => ({
  styles: {
    height: pxToRem(50),
    control: (base: any) => ({
      ...base,
      borderColor: props.error ? "#F1948A" : "transparent",
      height: "100%",
      backgroundColor: "#F0F3F4",
    }),
  },
}))<SelectProps>`
  width: 100%;
  height: ${pxToRem(52)};
  margin-top: ${minimalSpacing};
`;

export const Label = styled.h4`
  font-size: ${pxToRem(16)};
  color: ${light};
  font-weight: 400;
  margin-bottom: ${minimalSpacing};
`;

export const SectionRulesAdded = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${minimalSpacing};
`;

export const AutocompleteStyled = styled(Autocomplete)`
  width: 90%;
  border: 2px solid ${grayBg};
  border-radius: ${pxToRem(4)};

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
`;

export const MUITextFieldStyled = styled(MUITextField)`
  background-color: ${contrast};

  & .MuiInputBase-root {
    color: ${light};
    height: ${pxToRem(50.5)};
    border: 2px solid ${contrast};
  }
`;
