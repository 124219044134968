import React, { FC, useState } from "react";
import FadeIn from "react-fade-in";
import { observer, useStores } from "~/utils";
import JsonEditor from "../JsonEditor";
import { Content } from "./styles";

type Props = {
  id: number;
  body: Formulation.Body[];
};

const BodyMethod: FC<Props> = ({ id, body = [] }) => {
  const { formulation, app, jsonEditor } = useStores();

  const [initialized, setInitialized] = useState(false);

  const onEditRulesParams = (data: Formulation.ParameterInEditionParams) => {
    formulation.onSetIDMethodSelected(id);
    formulation.onSetParameterInEdition(data);
    app.onModalRules("Body");
  };

  const onBodyChange = (jsonObject: Record<string, string>) => {
    formulation.setJsonObject(id, jsonObject);
  };

  const method = formulation.methods.find((method) => method.id === id) as Formulation.Data;

  if (!initialized) {
    // jsonEditor.setJsonObject(method.bodyJson || {});
    jsonEditor.search("$");
    setInitialized(true);
  }

  return (
    <FadeIn delay={200} transitionDuration={500}>
      <Content>
        <JsonEditor
          method={method}
          onEditRules={(path) => onEditRulesParams({ name: path })}
          onBodyChange={(jsonObject) => onBodyChange(jsonObject)}
        />
      </Content>
    </FadeIn>
  );
};

export default observer(BodyMethod);
