import React, { FC, useState } from "react";
import { Tooltip } from "react-tooltip";
import { icons } from "~/assets/svg";
import If from "../If";
import TextFieldVariable from "../TextFieldVariable";
import { Content, Field, Label } from "./styles";

type Props = React.InputHTMLAttributes<any> &
  Partial<Variables.FieldProps> & {
    name?: string;
    label?: string;
    containerStyle?: object;
    error?: boolean;
    multiline?: boolean;
    fullWidth?: boolean;
    ref?: any;
    enableVariables?: boolean;
    onChange?: (event: any) => void;
    onMouseOverText?: () => void;
    onMouseOutText?: () => void;
    iconText?: keyof typeof icons;
    iconColor?: string;
    textDescribe?: string;
  };

const TextField: FC<Props> = ({
  label,
  containerStyle,
  ref,
  enableVariables = false,
  name = "",
  onChange = () => {},
  value = "",
  multiline = false,
  fullWidth = false,
  small = false,
  medium = false,
  onMouseOverText = () => {},
  onMouseOutText = () => {},
  iconText = "",
  iconColor,
  textDescribe = "",
  error,
  disabled,
  ...rest
}) => {
  const onChangeTextVariables = (event: any) => {
    const { value = "", name = "" } = enableVariables ? event : event.target;

    onChange({
      target: {
        value,
        name,
      },
    });
  };

  const Icon = (icons as any)[iconText] ?? null;

  return (
    <>
      <Tooltip id="tooltip-icons" style={{ width: 300, zIndex: 1 }} />

      <If condition={enableVariables}>
        <TextFieldVariable
          label={label}
          name={name || "test-name"}
          onChange={onChangeTextVariables}
          initialValue={value.toString()}
          multiline={multiline}
          fullWidth={fullWidth}
          error={error}
          small={small}
          medium={medium}
          {...rest}
        />
      </If>

      <If condition={!enableVariables}>
        <Content style={containerStyle} {...rest}>
          <If condition={!!label}>
            <Label>
              {label}&nbsp;
              <If condition={!!Icon}>
                <Icon size={20} tooltip={textDescribe} color={iconColor} />
              </If>
            </Label>
          </If>

          <Field disabled={disabled} ref={ref} error={error} name={name} value={value} onChange={onChange} />
        </Content>
      </If>
    </>
  );
};

export default TextField;
