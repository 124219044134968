import Select from "react-select";
import { getTheme, pxToRem, styled } from "~/utils";
import TextField from "../TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MUITextField from "@mui/material/TextField";
import Typograph from "../Typograph";

type SelectProps = {
  error?: boolean;
};

// Colors
const greenPool = getTheme("colors.greenPool");
const secondary = getTheme("colors.secondary");
const grayBg = getTheme("colors.grayBg");
const contrast = getTheme("colors.contrast");
const light = getTheme("colors.light");

// Radius
const cardRadius = pxToRem(10);

// Spacing
const minimalSpacing = getTheme("minimalSpacing");
const smallSpacing = getTheme("smallSpacing");

export const Content = styled.div`
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${secondary}98;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3;
`;

export const Title = styled(Typograph).attrs((props) => ({ iconColor: light(props) }))`
  color: ${light};
`;

export const Section = styled.div`
  width: 70%;
  min-height: 25vh;
  max-height: 90vh;
  background-color: ${secondary};
  padding: ${minimalSpacing};
  border-radius: ${cardRadius};
  border: 1px solid ${contrast};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${smallSpacing};
`;

export const SectionFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Field = styled(TextField).attrs({
  containerStyle: {
    width: "25%",
  },
  styleField: {
    width: "90%",
  },
})``;

export const AutocompleteStyled = styled(Autocomplete)`
  width: 100%;
  border-radius: ${pxToRem(4)};

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
`;

export const MUITextFieldStyled = styled(MUITextField)`
  background-color: ${contrast};
  border-radius: ${pxToRem(4)};

  & .MuiInputBase-root {
    color: ${light};
    height: ${pxToRem(52)};
  }
`;

export const SectionSelect = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  margin-left: ${pxToRem(25)};
`;

export const SelectStyled = styled(Select).attrs((props: SelectProps) => ({
  styles: {
    height: pxToRem(50),
    control: (base: any) => ({
      ...base,
      borderColor: props.error ? "#F1948A" : "transparent",
      height: "100%",
      backgroundColor: "#F0F3F4",
    }),
  },
}))<SelectProps>`
  width: 100%;
  height: ${pxToRem(52)};
`;

export const Label = styled.h4`
  font-size: ${pxToRem(16)};
  color: ${light};
  font-weight: 400;
  margin-bottom: ${pxToRem(10)};
`;

export const SectionTypeRequest = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 32%;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;

export const Text = styled.h3`
  font-weight: 400;
  font-size: ${pxToRem(18)};
  color: ${greenPool};
  padding: ${pxToRem(5)};
  cursor: pointer;
`;

export const SectionsAdded = styled.div`
  width: 100%;
  max-height: 50vh;
  margin-top: ${smallSpacing};
  border-radius: ${cardRadius};
  overflow: auto;
`;

export const Blocks = styled.div`
  max-width: 100%;
  margin-bottom: ${smallSpacing};
  display: flex;
  flex-direction: column;
  border: 1px solid ${contrast};
  border-radius: ${pxToRem(5)};
  padding: ${pxToRem(10)};
`;
