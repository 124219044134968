import React, { FC } from "react";
import FadeIn from "react-fade-in";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { observer } from "~/utils";
import HeadersTabRequest from "../HeadersTabRequest";
import HeadersTabResponse from "../HeadersTabResponse";

type Props = {
  id: number;
  name: string;
  method: string;
  URL: string;
  headersRequest: Record<string, string>;
  headersResponse: Record<string, string>;
};

const HeaderMethod: FC<Props> = ({ id, URL, method, name, headersRequest, headersResponse }) => (
  <Tabs>
    <TabList>
      <Tab>Request</Tab>
      <Tab>Response</Tab>
    </TabList>

    <TabPanel>
      <HeadersTabRequest
        onProcess={() => {}}
        onRemove={() => {}}
        onDownload={() => {}}
        id={id}
        URL={URL}
        method={method}
        name={name}
      />
    </TabPanel>

    <TabPanel>
      <HeadersTabResponse id={id} URL={URL} method={method} name={name} headers={headersResponse} />
    </TabPanel>
  </Tabs>
);
export default observer(HeaderMethod);
