import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Add, Edit } from "~/assets/svg";
import { observer, useStores } from "~/utils";
import ConfirmDelete from "../../ConfirmDelete";
import { Action, CardParam, Content, Field, FieldVariables, SectionFields, SectionParams } from "./styles";

const TYPE = {
  TEXT: "string",
  NUMBER: "inteiro",
};

type Props = {
  id: number;
  query: Formulation.Query[];
};

const QueryMethod: FC<Props> = ({ id, query = [] }) => {
  const { formulation, app } = useStores();
  const [field, setField] = useState({
    name: "",
    value: "",
  });

  const {
    register,
    setError,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Formulation.Query>();

  const onEditRulesParams = (data: Formulation.ParameterInEditionParams) => {
    formulation.onSetIDMethodSelected(id);
    formulation.onSetParameterInEdition(data);
    app.onModalRules("Query");
  };

  const clearFields = () => {
    reset({
      name: "",
      value: "",
    });

    setField({
      name: "",
      value: "",
    });
  };

  const onChange = (event: any) => {
    const { value, name } = event.target;
    setField({ ...field, [name]: value });
    setValue(name, value);
  };

  const onSubmit = handleSubmit(({ name, value }: Formulation.Query) => {
    if (!name) {
      setError("name", { type: "required", message: "Campo obrigatório" });
      return;
    }

    if (query.find((b) => b.name === name)) {
      toast("Parâmetro já foi adicionado anteriormente", {
        position: "top-center",
        icon: "⚠️",
      });

      return;
    }

    formulation.onChangeMethodsData({
      id,
      query: [{ name, value }, ...query],
    });

    clearFields();
  });

  const onChangeValueQuery = ({ name, value }: Formulation.Query) => {
    formulation.onChangeMethodsData({
      id,
      query: query.map((b) => {
        if (b.name === name) {
          return {
            ...b,
            value,
            type: !Number(value) ? TYPE.TEXT : TYPE.NUMBER,
          };
        }

        return b;
      }),
    });
  };

  const onRemoveQuery = ({ name }: Formulation.Query) => {
    formulation.onRemoveQuery({ name });
  };

  return (
    <Content>
      <SectionFields>
        <Field
          {...register("name", { required: true })}
          required
          error={!!errors.name?.message || !!errors.name?.type}
          label="Nome do Parâmetro"
          onChange={onChange}
          value={field.name}
        />

        <FieldVariables
          name="value"
          label="Valor do Parâmetro"
          onChange={({ name, value }) => onChange({ target: { name, value } })}
          initialValue={field.value}
        />

        <Add onPress={onSubmit} style={{ marginTop: 35, cursor: "pointer" }} />
      </SectionFields>

      <SectionParams>
        {query.map((query) => (
          <CardParam key={query.name}>
            <Field value={query.name} disabled />

            <FieldVariables
              name="value"
              initialValue={query.value}
              onChange={({ value }) =>
                onChangeValueQuery({
                  name: query.name,
                  type: query.type,
                  value: value,
                })
              }
            />

            <Action>
              <ConfirmDelete key={JSON.stringify(query)} onDelete={() => onRemoveQuery(query)} />
            </Action>
          </CardParam>
        ))}
      </SectionParams>
    </Content>
  );
};

export default observer(QueryMethod);
