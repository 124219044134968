import { getTheme, pxToRem, styled } from "~/utils";
import TextField from "../TextField";

// Colors
const grayLight = getTheme("colors.grayLight");
const disabled = getTheme("colors.disabled");

// Spacings
const smallSpacing = getTheme("smallSpacing");
const minimalSpacing = getTheme("minimalSpacing");

export const Content = styled.div`
  width: 95%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export const SectionFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${pxToRem(40)} 0px;
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  border-bottom-color: ${disabled};
`;

export const SectionParams = styled.div`
  padding-top: ${pxToRem(40)};
  overflow-y: auto;
  max-height: ${pxToRem(500)};
`;

export const Field = styled(TextField).attrs({
  containerStyle: {
    width: "45%",
  },
})`
  width: 100%;
`;

export const CardParam = styled.div`
  width: 100%;
  height: ${pxToRem(150)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${pxToRem(5)};
  padding: ${smallSpacing} ${minimalSpacing};
  border: 0.5px solid ${grayLight};
  margin-bottom: ${minimalSpacing};
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: ${minimalSpacing};
`;
