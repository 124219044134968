import { getTheme, pxToRem, styled } from "~/utils";

// colors
const light = getTheme("colors.light");

export const Content = styled.div`
  width: ${pxToRem(300)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Paragraph = styled.h4`
  font-family: Nunito;
  font-size: ${pxToRem(12)};
  color: ${light};
  font-weight: 400;
`;

export const Row = styled.div`
  width: ${pxToRem(100)};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: ${pxToRem(10)};
  margin-top: ${pxToRem(15)};
  margin-bottom: ${pxToRem(5)};
  align-items: center;
  border-bottom: 1px solid ${light};
`;
