/* eslint-disable react/function-component-definition */
import React, { FC } from "react";
import { REACT_APP_ENV } from "~/utils";
import pkg from "../../../package.json";
import { Content, Text } from "./styles";

type Props = {};

const AppInfo: FC<Props> = () => (
  <Content>
    <Text>
      v{pkg.version} - {REACT_APP_ENV}
    </Text>
  </Content>
);

export default AppInfo;
