import { getTheme, pxToRem, styled } from "~/utils";

type Props = {
  selected?: boolean;
  color?: string;
};

// Colors
const secondary = getTheme("colors.secondary");
const contrast = getTheme("colors.contrast");
const grayLight = getTheme("colors.grayLight");

// Spacings
const minimalSpacing = getTheme("minimalSpacing");
const smallSpacing = getTheme("smallSpacing");
export const Content = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export const TextAreaHeaders = styled.textarea<Props>`
  width: 98%;
  align-self: center;
  border: 0;
  background-color: ${contrast};
  border-radius: ${pxToRem(2)};
  font-size: ${pxToRem(18)};
  padding: ${minimalSpacing};
  height: ${pxToRem(200)};
  color: ${secondary};
  font-weight: 400;
  background-color: ${grayLight};
  border-radius: ${pxToRem(10)};
  &:focus {
    outline: none;
  }
`;
