import React, { FC, useState } from "react";
import ReactLoading from "react-loading";
import { ArrowDownIcon, DeleteIcon, DownloadIcon, RefreshIcon } from "~/assets/svg";
import { REQUEST_METHOD, colorByMethod, observer, useStores } from "~/utils";
import BodyMethod from "../BodyMethod";
import FormDataMethod from "../FormDataMethod";
import HeaderMethod from "../HeaderMethod";
import If from "../If";
import PathParamMethod from "../PathParamMethod";
import QueryMethod from "../QueryMethod";
import { Menu, MenuItem } from "@material-ui/core";

import {
  Action,
  CardMethod,
  Check,
  Collapse,
  Column,
  Content,
  Name,
  Row,
  SectionActions,
  SectionButtons,
  SectionDetails,
  Url,
  TextTouchable,
  SectionHeader,
  MethodText,
} from "./styles";
import ConfirmDelete from "../ConfirmDelete";

type Props = {
  id: number;
  name: string;
  method: string;
  URL: string;
  headersResponse: Record<string, string>;
  headersRequest: Record<string, string>;
  body: Formulation.Body[];
  query: Formulation.Query[];
  formData: Formulation.FormData[];
  pathParam: Record<string, string>;
  idAttachment?: string;
  onPress: () => void;
  selected?: boolean;
  onRemove: (id: number) => void;
};

const Method: FC<Props> = ({
  headersResponse,
  headersRequest,
  id,
  URL,
  method,
  name,
  body,
  pathParam,
  query,
  idAttachment,
  formData,
  selected,
  onPress,
  onRemove,
}) => {
  const { formulation } = useStores();
  const [toolbarOpened, setToolbar] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (e: any) => {
    setAnchorElUser(null);
  };

  const handleToolbar = () => {
    setToolbar(!toolbarOpened);
  };

  const onCheck = () => formulation.onSelect(id);

  const onSetHeaderView = (view: string) => {
    formulation.onSetIDMethodSelected(id);

    const idActive = (formulation.methodActive as any)[view];

    formulation.onSetTabsMethod({
      [view]: idActive === id ? 0 : id,
    });
  };

  const onMethodSelected = (method: string) => {
    formulation.onChangeMethodsData({ id, method });
  };

  return (
    <>
      <Content selected={selected}>
        <SectionHeader onClick={onPress}>
          <Name>{name.length > 25 ? `${name.substring(0, 25)}...` : name}</Name>
          <CardMethod onClick={handleOpenUserMenu} color={colorByMethod(method)}>
            {method}
            &nbsp;
            <ArrowDownIcon />
          </CardMethod>

          <Menu id="menu-appbar" anchorEl={anchorElUser} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
            {REQUEST_METHOD.map((method) => (
              <MenuItem key={method} onClick={handleCloseUserMenu}>
                <MethodText onClick={() => onMethodSelected(method)}>{method}</MethodText>
              </MenuItem>
            ))}
          </Menu>
        </SectionHeader>

        <Row>
          <Url onClick={onPress}>{`${URL.length > 30 ? URL.substring(0, 30) + "..." : URL}`}</Url>
          <TextTouchable onClick={() => onRemove(id)}>
            <DeleteIcon />
          </TextTouchable>
        </Row>

        {/* <SectionActions> */}
        {/* <SectionButtons>
            <HeaderIcon
              color={formulation.methodActive.header === id ? colors.colors.primary : colors.colors.blacks}
              onPress={() => onSetHeaderView(VIEWS.HEADER)}
              width={40}
            />
            <BodyIcon
              color={formulation.methodActive.body === id ? colors.colors.primary : colors.colors.blacks}
              onPress={() => onSetHeaderView(VIEWS.BODY)}
              width={40}
            />

            <QueryIcon
              color={formulation.methodActive.query === id ? colors.colors.primary : colors.colors.blacks}
              onPress={() => onSetHeaderView(VIEWS.QUERY)}
              width={40}
            />

            <FormDataIcon
              color={formulation.methodActive.form_data === id ? colors.colors.primary : colors.colors.blacks}
              onPress={() => onSetHeaderView(VIEWS.FORM_DATA)}
            />

            <PathParamIcon
              color={formulation.methodActive.path_param === id ? colors.colors.primary : colors.colors.blacks}
              onPress={() => onSetHeaderView(VIEWS.PATH_PARAM)}
              width={40}
              disabled={isEmpty(pathParam)}
            />
          </SectionButtons> */}

        {/* <Action color={colorByMethod(method)}>
            <If condition={formulation.methodsLoading.includes(id)}>
              <ReactLoading type="spinningBubbles" width={25} height={25} color={colors.colors.light} />
            </If>

            <If condition={!formulation.methodsLoading.includes(id)}>
              <CloseSecondary width={25} onPress={() => onRemoveMethod(id)} />
              <SendSecondary width={25} onPress={onSendData} />

              <If condition={!!idAttachment}>
                <Download onPress={() => onDownload(idAttachment!)} />
              </If>
            </If>
          </Action> */}
        {/* </SectionActions> */}
      </Content>

      <If condition={formulation.methodActive.body === id}>
        <Collapse>
          <BodyMethod id={id} body={body} />
        </Collapse>
      </If>

      <If condition={formulation.methodActive.header === id}>
        <Collapse>
          <HeaderMethod
            id={id}
            name={name}
            URL={URL}
            method={method}
            headersRequest={headersRequest}
            headersResponse={headersResponse}
          />
        </Collapse>
      </If>

      <If condition={formulation.methodActive.form_data === id}>
        <Collapse>
          <FormDataMethod id={id} data={formData} />
        </Collapse>
      </If>

      <If condition={formulation.methodActive.query === id}>
        <Collapse>
          <QueryMethod id={id} query={query} />
        </Collapse>
      </If>

      <If condition={formulation.methodActive.path_param === id}>
        <Collapse>
          <PathParamMethod id={id} URL={URL} pathParam={pathParam} />
        </Collapse>
      </If>
    </>
  );
};

export default observer(Method);
