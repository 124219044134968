import React, { FC } from "react";

type Props = {
  onPress?: () => void;
  width?: number;
  color?: string;
};

const Icon: FC<Props> = ({ onPress, width = 26, color = "#FAFAFA", ...rest }) => (
  <svg
    width={width}
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onPress}
    style={{ cursor: "pointer", outline: "none", zIndex: 1 }}
    {...rest}
  >
    <path
      d="M24.8254 0.261932C24.4761 -0.0873108 23.9523 -0.0873108 23.603 0.261932L12.5437 11.3213L1.54249 0.261932C1.19325 -0.0873108 0.669383 -0.0873108 0.32014 0.261932C-0.0291034 0.611176 -0.0291034 1.13504 0.32014 1.48428L11.3213 12.5437L0.261932 23.5448C-0.0873108 23.8941 -0.0873108 24.4179 0.261932 24.7672C0.436554 24.9418 0.669383 25 0.902212 25C1.13504 25 1.36787 24.9418 1.54249 24.7672L24.8254 1.48428C25.1164 1.19325 25.1164 0.611176 24.8254 0.261932Z"
      fill={color}
    />
    <path
      d="M17.8417 16.5599C17.4925 16.2107 16.9686 16.2107 16.6194 16.5599C16.2701 16.9091 16.2701 17.433 16.6194 17.7823L23.6042 24.7671C23.7788 24.9417 24.0117 25 24.2445 25C24.4773 25 24.7102 24.9417 24.8848 24.7671C25.234 24.4179 25.234 23.894 24.8848 23.5448L17.8417 16.5599Z"
      fill={color}
    />
  </svg>
);

export default Icon;
