import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { ArrowDownIcon, DeleteIcon } from "~/assets/svg";
import { Menu, MenuItem } from "@material-ui/core";
import { REGEX_VALIDATE_URL, REQUEST_METHOD, colorByMethod, observer, useStores } from "~/utils";
import {
  CardMethod,
  Content,
  Row,
  ButtonCaseTest,
  FieldName,
  MethodText,
  SectionURL,
  FieldURL,
  Button,
} from "./styles";

type Props = {
  id: number;
  name: string;
  method: string;
  URL: string;
  enableDownload?: boolean;
  onProcess: () => void;
  onDownload: () => void;
  onRemove: () => void;
};

const HeadersTabRequest: FC<Props> = ({ id, URL, enableDownload, name, method, onDownload, onProcess, onRemove }) => {
  const { formulation, testCase } = useStores();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [testCasesCount, setTestCasesCount] = React.useState(testCase.list().length);

  React.useEffect(() => {
    setTestCasesCount(testCase.list().length);

  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (e: any) => {
    setAnchorElUser(null);
  };

  const {
    register,
    setError,
    formState: { errors },
  } = useForm<Formulation.MethodHeader>();

  const onBlur = (event: any) => {
    const { value, name, required } = event.target;

    if (required && !value) {
      setError(name, { type: "required", message: "Campo obrigatório" });
      return;
    }

    setError(name, { type: "required", message: "" });
  };

  const onChange = (event: any) => {
    const { value, name } = event.target;

    formulation.onChangeMethodsData({
      id,
      [name]: value,
    } as any);
  };

  const onMethodSelected = (method: string) => {
    formulation.onChangeMethodsData({ id, method });
  };

  const onTestCase = () => {
    testCase.setVisibleModalTestCase(true);
    testCase.setReference(id?.toString());
  };

  return (
    <Content>
      <Row>
        <FieldName
          {...register("name")}
          name="name"
          value={name}
          required
          error={!!errors.name?.message}
          label=" "
          onBlur={onBlur}
          onChange={onChange}
        />

        <ButtonCaseTest onClick={onTestCase}>Casos de teste ({testCasesCount})</ButtonCaseTest>
      </Row>

      <SectionURL>
        <CardMethod onClick={handleOpenUserMenu} color={colorByMethod(method)}>
          {method}
          &nbsp;
          <ArrowDownIcon />
        </CardMethod>

        <Menu id="menu-appbar" anchorEl={anchorElUser} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
          {REQUEST_METHOD.map((method) => (
            <MenuItem key={method} onClick={handleCloseUserMenu}>
              <MethodText onClick={() => onMethodSelected(method)}>{method}</MethodText>
            </MenuItem>
          ))}
        </Menu>

        <FieldURL
          name="URL"
          onChange={({ name, value }) => onChange({ target: { name, value } })}
          initialValue={URL}
          error={!!errors.URL?.message || !REGEX_VALIDATE_URL.test(URL!)}
        />

        <Button onClick={onRemove}>
          Apagar&nbsp; <DeleteIcon />
        </Button>
      </SectionURL>
    </Content>
  );
};
export default observer(HeadersTabRequest);
