import React, { FC } from "react";

type Props = {
  size?: number;
  color?: string;
};

const Icon: FC<Props> = ({ size = 35, color = "#F0F8FF" }) => (
  <svg width={size} height={size} fill={color} viewBox="10 0 80 80">
<path d="M48.8,75.6c0-3.7,1.8-6.5,4.2-8.1v-3.7c-1.3-0.9-2.3-2.2-2.7-3.7c-0.5-2.1,0.2-4.5,1.5-6.2
	c0.3-0.4,0.7-0.8,1.1-1.2c0.4-0.4,0.9-0.7,1.4-0.9c2.6-1.4,5.6-2.4,8.6-2.4c2.7,0,5.1,1.1,6.8,2.7c2.4-2.4,4.9-2.9,6.4-2.9
	c1.4,0,2.7,0.3,3.9,0.9c0-0.1,0-0.1,0-0.2c0-16.5-13.5-30-30-30S20,33.5,20,50c0,16.4,13.4,29.9,29.8,30
	C49.1,78.7,48.8,77.3,48.8,75.6z M73.8,47h-7.9c-0.3-7.1-1.9-13.3-4.3-18C68.2,32.7,72.8,39.3,73.8,47z M53,27.3
	c3.4,2.9,6.4,10.3,6.9,19.7H53V27.3z M26.2,53h7.9c0.3,7.1,1.9,13.3,4.3,18C31.8,67.3,27.2,60.7,26.2,53z M34.1,47h-7.9
	c1-7.7,5.6-14.3,12.2-18C36,33.7,34.5,39.9,34.1,47z M47,72.7c-3.4-2.9-6.4-10.3-6.9-19.7H47V72.7z M47,47h-6.9
	c0.5-9.4,3.5-16.8,6.9-19.7V47z"/>
<g>
	<path d="M75.5,62.4c1.3,0,3.9-1.1,3.9-4.5c0-3.4-2.5-3.6-3.2-3.6c-1.5,0-3,1.1-4.3,3.4C70.5,60,69,62.6,69,62.6
		l-0.1,0c-0.3-1.6-0.6-3-0.7-3.6c-0.3-1.4-1.9-4.5-5.3-4.5c-3.4,0-6.5,2-6.5,2l0,0c-0.6,0.4-1,1-1,1.8c0,1.1,0.9,2.1,2.1,2.1
		c0.3,0,0.6-0.1,0.9-0.2l0,0c0,0,2.6-1.4,3.1,0c0.2,0.4,0.3,0.9,0.5,1.4c0.7,2.2,1.3,4.9,1.8,7.3L61.5,72c0,0-2.5-0.9-3.8-0.9
		s-3.9,1.1-3.9,4.5s2.5,3.6,3.2,3.6c1.5,0,3-1.1,4.3-3.4c1.3-2.3,2.8-4.9,2.8-4.9c0.4,2.1,0.8,3.8,1,4.5c0.9,2.4,2.8,3.9,5.4,3.9
		c0,0,2.7,0,5.8-1.8c0.8-0.3,1.3-1.1,1.3-1.9c0-1.1-0.9-2.1-2.1-2.1c-0.3,0-0.6,0.1-0.9,0.2l0,0c0,0-2.3,1.3-3,0.3
		c-0.6-1-1-2.4-1.4-4.2c-0.3-1.5-0.7-3.3-1.1-5.1l2.3-3.3C71.7,61.5,74.2,62.4,75.5,62.4z"/>
</g>
</svg>
);

export default Icon;
