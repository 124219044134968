export const REQUEST_METHOD = [
  "GET",
  "POST",
  "PUT",
  "DELETE",
  "PATCH",
  "HEAD",
  "OPTIONS",
];

export const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
  HEAD: "HEAD",
  OPTIONS: "OPTIONS",
};

export const METHOD_HIDE_BODY = ["GET", "DELETE"];

export const TAB = {
  BODY: "Body",
  QUERY: "Query",
};

export const DEFAULT_DATA = {
  name: "",
  method: "GET",
  URL: "",
  headers: {},
  body: {},
  query: {},
  formData: {},
  rules: [],
};

export const HEADERS_MOCK = [
  {
    name: "Content-Type",
    value: "application/json",
  },
  {
    name: "Access-Control-Allow-Origin",
    value: "*",
  },
  {
    name: "Access-Control-Allow-Headers",
    value: "Content-Type, Authorization",
  },
];

export const OPTIONS_HEADERS_MOCK = [
  {
    label: "Content-Type",
    value: "application/json",
  },
  {
    label: "Access-Control-Allow-Origin",
    value: "*",
  },
  {
    label: "Access-Control-Allow-Headers",
    value: "Content-Type, Authorization",
  },
];

export const RULES_DEFAULT = [
  {
    label: "Caractere Especial",
    value: " ",
    editable: false,
  },
  {
    label: "Intervalo Válido",
    value: "12, 120",
    editable: true,
  },
  {
    label: "Tamanho do Campo",
    value: "30",
    editable: true,
  },
  {
    label: "Valor Negativo",
    value: "-10",
    editable: true,
  },
  {
    label: "Campo Vazio",
    value: " ",
    editable: false,
  },
  {
    label: "Valor Inválido",
    value: "!#",
    editable: true,
  },
];

export const RULES_HEADERS = [
  {
    label: "Campo Vazio",
    value: " ",
    editable: false,
  },
  {
    label: "Valor Inválido",
    value: "!#",
    editable: true,
  },
];

export const RULES = {
  DEFAULT: RULES_DEFAULT,
  HEADERS: RULES_HEADERS,
};

export const MODAL = {
  NULL: "null",
  HEADERS: "headers",
  RULES: "rules",
  RULES_PARAMS: "rules_params",
};

export const STEP = {
  DETAILS: "details",
  PARAMS: "params",
};

export const STATUS_ADDED_SUCCESS = {
  success: true,
  message: "",
};

export const STATUS_ADDED_EXIST = {
  success: false,
  message: "Campo já adicionado, verifique os campos.",
};

export const STATUS_ADDED_REQUIRED = {
  success: false,
  message: "Campos obrigatórios não preenchidos.",
};

export const REGEX_VALIDATE_URL = /^(http|https):\/\/[^ "]+$/;

// NEW ENUMS BELOW

export const REGEX_VALIDATE_PATH_PARAM = /\/:\w+|{\w+}/g; // Buscar por :param ou {param}

export const MOCK_DATA: Formulation.Data = {
  id: 0,
  name: "API Example",
  method: METHODS.GET,
  URL: "https://example.com",
  headers: {},
  body: [],
  query: [],
  formData: [],
  path_param: {},
  rules: [],
  response_params: {
    header_response: {},
  },
};
