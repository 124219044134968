import styled from "styled-components";
import { getTheme, ifStyle, pxToRem } from "~/utils";

const textColor = getTheme("colors.secondary");
const primaryColor = getTheme("colors.primary");
const bgSecondary = getTheme("background.secondary");
const isSelected = ifStyle("isSelected");
const minimalSpacing = getTheme("minimalSpacing");

export const Content = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  min-height: ${pxToRem(70)};
  max-width: ${pxToRem(240)};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${isSelected(primaryColor)}30;
  padding: 5px;
  border-radius: 5px;

  margin-top: 5px;
  &:hover {
    background: ${isSelected("#fff", bgSecondary)}30;
  }
`;

export const ItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
  padding-bottom: ${minimalSpacing};
`;

export const PropertyItemTitle = styled.h3`
  font-weight: 400;
  font-size: ${pxToRem(16)};
  color: ${textColor};
  max-width: 100%;
  word-wrap: break-word;
  padding-bottom: ${minimalSpacing};
  border-bottom: 1px solid ${textColor}30;
`;

export const ItemInfo = styled.p`
  font-size: 12px;
  color: ${textColor};
  text-transform: uppercase;
`;
