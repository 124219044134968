import { getTheme, ifStyle, pxToRem, styled } from "~/utils";
import TextField from "../../TextField";
import Select from "@mui/material/Select";
import MUITextField from "@mui/material/TextField";

type PropsTextArea = {
  error?: boolean;
};

// Colors
const grayLight = getTheme("colors.grayLight");
const disabled = getTheme("colors.disabled");
const light = getTheme("colors.light");
const contrast = getTheme("colors.contrast");
const grayBg = getTheme("colors.grayBg");
const failure = getTheme("failure");
const dark = getTheme("colors.dark");
const sceneSpacing = getTheme("sceneSpacing");

// Spacings
const smallSpacing = getTheme("smallSpacing");
const minimalSpacing = getTheme("minimalSpacing");

// Ifs
const isError = ifStyle("error");

export const Content = styled.div`
  width: 95%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  margin-top: ${smallSpacing};
`;

export const SelectStyled = styled(Select)`
  width: 30%;
  border: 2px solid ${grayBg};
  border-radius: ${pxToRem(4)};
  color: ${light};

  & .MuiSvgIcon-root {
    color: ${light};
  }

  & .MuiSelect-select {
    color: ${light};
  }
`;

export const SectionFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${pxToRem(40)} 0px;
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  border-bottom-color: ${disabled};
`;

export const SectionParams = styled.div`
  padding-top: ${pxToRem(40)};
  overflow-y: auto;
  max-height: ${pxToRem(500)};
`;

export const Field = styled(TextField).attrs({
  containerStyle: {
    width: "45%",
  },
})`
  width: 100%;
`;

export const CardParam = styled.div`
  width: 100%;
  height: ${pxToRem(150)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${pxToRem(5)};
  padding: ${smallSpacing} ${minimalSpacing};
  border: 0.5px solid ${grayLight};
  margin-bottom: ${minimalSpacing};
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: ${minimalSpacing};
`;

export const TextArea = styled.textarea<PropsTextArea>`
  z-index: 1;
  border: 0;
  background-color: ${contrast};
  color: ${dark};
  width: 96%;
  border-radius: ${pxToRem(10)};
  font-size: ${pxToRem(18)};
  padding: ${smallSpacing};
  border: ${isError(1, 0)}px solid ${failure};
  color: ${light};
  margin: ${minimalSpacing} 0px;

  &:focus {
    outline: none;
  }
`;

export const Card = styled.div`
  margin-top: ${sceneSpacing};
  display: flex;
  background-color: ${contrast};
  width: ${pxToRem(400)};
  height: ${pxToRem(150)};
  border-radius: ${pxToRem(10)};
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    background-color: ${contrast}99;
    transition: 0.3s;
  }
`;

export const TextCard = styled.h2`
  color: ${light};
  font-weight: 300;
  font-size: ${pxToRem(20)};
  margin: ${minimalSpacing} 0px;
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.h2`
  color: ${light};
  font-weight: 350;
  font-size: ${pxToRem(14)};
  margin-top: ${minimalSpacing};
  text-align: center;
  padding: 0px ${minimalSpacing};
`;

export const Divider = styled.div`
  width: 90%;
  border-top: 1px solid ${light}50;
`;
