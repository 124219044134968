import React, { FC, useEffect, useRef, useState } from "react";
import { REGEX_VARIABLE, clearTag, debounce, insertTag, useStores } from "~/utils";
import { Container, Field, Label } from "./styles";
import If from "../If";

const TextFieldVariable: FC<Variables.FieldProps> = ({
  onChange,
  initialValue = "",
  name,
  label = "",
  multiline = false,
  fullWidth = false,
  error = false,
  small = false,
  medium = false,
  ...rest
}) => {
  const { variables } = useStores();
  const ref = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(`${initialValue}`);
  const [valueToShow, setValueToShow] = useState("");

  const onParseToShow = (value: string) => {
    const parsedText = value
      .replaceAll(REGEX_VARIABLE, (match) => insertTag(match, variables.list.includes(clearTag(match))))
      .replaceAll("\n", () => (multiline ? "<br>" : "\n"));

    return parsedText;
  };

  const onInput = debounce(
    () => {
      const value = ref.current?.innerText || "";

      if (ref.current) {
        setValueToShow(onParseToShow(value));
      }

      setValue(value);
    },
    multiline ? 2500 : 0
  );

  useEffect(() => {
    if (ref.current) {
      const range = document.createRange();
      const sel = window.getSelection();

      range.selectNodeContents(ref.current);
      range.collapse(false);
      sel?.removeAllRanges();
      sel?.addRange(range);
    }
  }, [valueToShow]);

  useEffect(() => {
    if (value === initialValue) return;
    if (!name.trim() || !value.trim()) return;

    onChange({ name, value });
  }, [value]);

  useEffect(() => {
    if (ref.current) {
      setValueToShow(onParseToShow(initialValue));
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      setValueToShow(onParseToShow(initialValue));
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <Container fullWidth={fullWidth} small={small} medium={medium} {...rest}>
      <If condition={!!label}>
        <Label>{label}</Label>
      </If>
      <Field
        multiline={multiline}
        ref={ref}
        contentEditable
        onInput={onInput}
        dangerouslySetInnerHTML={{ __html: valueToShow }}
        error={error}
      />
    </Container>
  );
};

export default React.memo(TextFieldVariable);
