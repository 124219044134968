import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid black;
  display: flex;
`;

export const PropertyDetailWrapper = styled.div<{ scrollRight: number }>`
  display: flex;
  max-width: 80%;
  position: relative;
  right: ${(props) => props.scrollRight}px;
`;

export const JsonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

export const JsonEditorContent = styled.div<{ hasSelected: boolean }>`
  width: ${(props) => (props.hasSelected ? 75 : 100)}%;
`;
