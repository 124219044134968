import React, { FC, PropsWithChildren } from "react";
import { observer } from "mobx-react";
import { useStores } from "~/utils/helpers/store.helper";
import {
  Content,
  ItemInfo,
  ItemInfoWrapper,
  PropertyItemTitle,
} from "./styles";

type Props = {
  path: string;
  property: string;
  object: any;
  method: Formulation.Data;
  onClick: (path: string) => void;
};

const JsonEditorPropertyItem: FC<PropsWithChildren<Props>> = ({
  path,
  property,
  object,
  method,
  onClick,
}) => {
  const { jsonEditor } = useStores();

  const paths = path.split(".");
  paths.splice(0, 1);
  const currentParameter = paths.join(".");

  let rulesCount = 0;
  let rulesInfo = "";

  method?.rules.forEach((rule) =>
    rule.parameters.forEach((parameter) => {
      const formatedParameter = parameter.replace(/\[(\d+)\]/, "$1");
      if (formatedParameter === currentParameter) {
        rulesCount += 1;
      }
    })
  );

  if (rulesCount === 1) {
    rulesInfo = `${rulesCount} regra`;
  } else {
    rulesInfo = `${rulesCount} regras`;
  }

  let propertyType: string = typeof object;

  if (
    propertyType === "object" &&
    object &&
    typeof object.length === "number"
  ) {
    propertyType = "array";
  }

  let childrenLength = "";
  if (object && (propertyType === "object" || propertyType === "array")) {
    let length = 0;
    if (propertyType === "object") {
      length = Object.keys(object).length;
    } else {
      length = object.length;
    }
    if (length === 1) {
      childrenLength = `${length} campo`;
    } else {
      childrenLength = `${length} campos`;
    }
  }

  return (
    <Content
      isSelected={jsonEditor.jsonSearch === path}
      onClick={() => onClick(path)}
    >
      <PropertyItemTitle>{property}</PropertyItemTitle>

      <ItemInfoWrapper>
        <ItemInfo>{childrenLength}</ItemInfo>
        <ItemInfo>{rulesInfo}</ItemInfo>
      </ItemInfoWrapper>
    </Content>
  );
};

export default observer(JsonEditorPropertyItem);
