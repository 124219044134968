import CloseSecondary from "./CloseSecondary";
import Download from "./Download";
import FAQ from "./FAQ";
import List from "./List";
import SendSecondary from "./SendSecondary";

export { default as Icon } from "./Icon";
export { default as Finish } from "./Finish";
export { default as Close } from "./Close";
export { default as Back } from "./Back";
export { default as Add } from "./Add";
export { default as Edit } from "./Edit";
export { default as Clear } from "./Clear";
export { default as Send } from "./Send";
export { default as ArrowOpen } from "./ArrowOpen";
export { default as ArrowClose } from "./ArrowClose";
export { default as Document } from "./Document";
export { default as Cube } from "./Cube";
export { default as Upload } from "./Upload";
export { default as HeaderIcon } from "./HeaderIcon";
export { default as BodyIcon } from "./BodyIcon";
export { default as PathParamIcon } from "./PathParamIcon";
export { default as QueryIcon } from "./QueryIcon";
export { default as Trash } from "./Trash";
export { default as Instagram } from "./Instagram";
export { default as Linkedin } from "./Linkedin";
export { default as PrimeIcon } from "./PrimeIcon";
export { default as PrimeNameIcon } from "./PrimeNameIcon";
export { default as Done } from "./Done";
export { default as FormDataIcon } from "./FormDataIcon";

export { Download, SendSecondary, CloseSecondary, List, FAQ };

export const icons = {
  download: Download,
  closeSecondary: CloseSecondary,
  sendSecondary: SendSecondary,
  list: List,
  faq: FAQ,
};

export { default as Montain } from "./New/Montain";
export { default as Grid } from "./New/Grid";
export { default as Home } from "./New/Home";
export { default as Phone } from "./New/Phone";
export { default as Settings } from "./New/Settings";
export { default as World } from "./New/World";
export { default as MenuIcon } from "./New/Menu";
export { default as ClipboardIcon } from "./New/Clipboard";
export { default as StorageIcon } from "./New/Storage";
export { default as UploadIcon } from "./New/Upload";
export { default as RefreshIcon } from "./New/Refresh";
export { default as DeleteIcon } from "./New/Delete";
export { default as DownloadIcon } from "./New/Download";
export { default as ArrowDownIcon } from "./New/ArrowDown";
export { default as Expand } from './New/Expand';
