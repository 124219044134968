/* eslint-disable react/require-default-props */
import React, { FC } from "react";

type Props = {
  width?: string;
  color?: string;
  onPress?: () => void;
};

const Icon: FC<Props> = ({ width = 25, color = "#FAFAFA", onPress }) => (
  <svg
    width={width}
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
    onClick={onPress}
  >
    <path
      d="M19.7739 0C19.0435 0 18.4696 0.573913 18.4696 1.30435C18.4696 2.03478 19.0435 2.6087 19.7739 2.6087C24.1043 2.6087 27.3391 6.10435 27.3391 10.6957V19.3043C27.3391 23.7391 23.687 27.3913 19.2522 27.3913H10.6957C6.26087 27.3913 2.6087 23.7391 2.6087 19.3043V10.6957C2.6087 6.10435 5.84348 2.6087 10.1739 2.6087C10.9043 2.6087 11.4783 2.03478 11.4783 1.30435C11.4783 0.573913 10.9043 0 10.1739 0C4.48696 0 0 4.69565 0 10.6957V19.3043C0 25.2 4.8 30 10.6957 30H19.3043C25.2 30 30 25.2 30 19.3043V10.6957C29.9478 4.69565 25.513 0 19.7739 0Z"
      fill={color}
    />
    <path
      d="M14.9735 0C14.243 0 13.6691 0.573913 13.6691 1.30435V18.6783L9.49522 14.5043C8.97348 13.9826 8.1387 13.9826 7.66914 14.5043C7.1474 15.0261 7.1474 15.8609 7.66914 16.3304L14.0344 22.6957C14.2952 22.9565 14.6083 23.0609 14.9735 23.0609C15.3387 23.0609 15.6517 22.9565 15.9126 22.6957L22.2778 16.3304C22.7996 15.8087 22.7996 14.9739 22.2778 14.5043C21.7561 13.9826 20.9213 13.9826 20.4517 14.5043L16.2778 18.6783V1.30435C16.2778 0.573913 15.7039 0 14.9735 0Z"
      fill={color}
    />
  </svg>
);

export default Icon;
