import { getTheme, ifStyle, pxToRem, styled } from "~/utils";

type Props = {
  error?: boolean;
};

// Colors
const secondary = getTheme("colors.secondary");
const contrast = getTheme("colors.contrast");
const dark = getTheme("colors.dark");
const failure = getTheme("failure");
const light = getTheme("colors.light");

// Spacings
const minimalSpacing = getTheme("minimalSpacing");

const inMobile = getTheme("inMobile");

// Ifs
const isError = ifStyle("error");

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Label = styled.h4`
  font-size: ${pxToRem(16)};
  color: ${light};
  font-weight: 400;
  align-items: center;
  display: flex;
  margin-bottom: ${minimalSpacing};
`;

export const Field = styled.input<Props>`
  border: 0;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  border-radius: ${pxToRem(3)};
  background-color: ${contrast};
  height: ${pxToRem(51)};
  color: ${(props) => (props.disabled ? `#636274` : light)};
  padding-left: ${minimalSpacing};
  font-size: ${pxToRem(18)};

  border: 1px solid ${isError(failure, contrast)};

  &:focus {
    outline: none;
  }

  @media ${inMobile} {
    font-size: ${pxToRem(12)};
    border-radius: ${pxToRem(2)};
  }
`;
