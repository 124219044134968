import request from "../request";

// SEND FILE UPLOADED FROM HOME AND DYNAMIC FORM

export const onSendCurl = async (model = ""): Promise<string> => {
  try {
    const { data } = await request.post("/apiSpecImporter/", {
      model: model.trim(),
    });
    return data.id;
  } catch (error) {
    return "";
  }
};

export const onSendContentFile = async (model: string): Promise<string> => {
  try {
    const { data } = await request.post("/apiSpecImporter/", {
      model: JSON.parse(model),
    });
    return data.id;
  } catch (error) {
    return "";
  }
};

// SEND CONTENT FROM FORMULATION METHOD

export const onSendJSON = async ({ wizard_requests, setup }: Formulation.SendToAPIParams): Promise<string | null> => {
  try {
    const { data } = await request.post("/testGenerator/", {
      wizard_requests,
      setup,
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const onFetchRequests = async (id: string) => {
  const { data } = await request.get<FormFile.ResponseFromAPI>(`/apiSpecImporter/${id}`);
  return data;
};

export const onFetchRobotFile = async ({ id }: Form.FetchFileParams) => {
  const { data } = await request.get<Form.FetchFileResponse>(`/testGenerator/${id}`);
  return data;
};
