import React, { FC } from "react";

type Props = {
  onPress?: () => void;
  width?: number;
  color?: string;
};

const Icon: FC<Props> = ({ onPress, width = 25, color = "#FAFAFA" }) => (
  <svg
    width={width}
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
    onClick={onPress}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.334734 6.16559C-1.19667 2.33711 2.81568 -1.35474 6.50375 0.48929L20.486 7.48042C20.9291 7.70195 21.1086 8.24066 20.8871 8.68369C20.6656 9.12672 20.1269 9.30629 19.6839 9.08478L5.70156 2.09363C3.48872 0.987209 1.08132 3.20235 2.00015 5.49942L5.67071 14.6757C5.75624 14.8895 5.75624 15.1281 5.67071 15.3419L2.00015 24.5184C1.08132 26.8155 3.48872 29.0305 5.70156 27.9242L26.719 17.4155C28.7022 16.4239 28.7022 13.5939 26.719 12.6024L24.1682 11.3269C23.7251 11.1054 23.5456 10.5667 23.7671 10.1237C23.9886 9.68064 24.5273 9.50107 24.9703 9.72257L27.5212 10.998C30.8263 12.6506 30.8263 17.3672 27.5212 19.0198L6.50373 29.5285C2.81566 31.3726 -1.19666 27.6807 0.334734 23.8522L3.87204 15.0088L0.334734 6.16559Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.94092 15.0088C3.94092 14.5136 4.34246 14.1119 4.83778 14.1119H11.1158C11.6111 14.1119 12.0127 14.5136 12.0127 15.0088C12.0127 15.504 11.6111 15.9057 11.1158 15.9057H4.83778C4.34246 15.9057 3.94092 15.504 3.94092 15.0088Z"
      fill={color}
    />
  </svg>
);

export default Icon;
