import React, { FC, useEffect, useState } from "react";
import { observe } from "mobx";
import { observer } from "mobx-react";
import { ArrowClose, ArrowOpen, Edit, Finish } from "~/assets/svg";
import { useStores } from "~/utils/helpers/store.helper";
import If from "../If";
import {
  Content,
  FlexDiv,
  HistoryControls,
  PropertyBlock,
  SearchBlock,
  SearchInput,
  SearchInputWrapper,
  SearchWrapper,
} from "./styles";

const JsonEditorMenuBar: FC = () => {
  const { jsonEditor } = useStores();

  const [input, setInput] = useState(jsonEditor.jsonSearch);
  const [isEditMode, setEditMode] = useState(false);

  const paths = jsonEditor.jsonSearch.split(".");
  const searchPaths: { name: string; searchPath: string }[] = [];
  paths.forEach((path) => {
    let name = path;
    if (path === "$") {
      name = "root";
    }
    let searchPath = path;
    if (searchPaths.length) {
      searchPath = `${searchPaths[searchPaths.length - 1].searchPath}.${path}`;
    }

    searchPaths.push({ name, searchPath });
  });

  useEffect(() => {
    const disposer = observe(jsonEditor, "jsonSearch", (change) => {
      setInput(change.newValue as string);
    });

    return disposer;
  });

  return (
    <Content>
      <HistoryControls>
        <ArrowClose
          isDisabled={!jsonEditor.hasBackHistory}
          height={15}
          onPress={() => jsonEditor.goBackHistory()}
        />
        <ArrowOpen
          isDisabled={!jsonEditor.hasFowardHistory}
          height={15}
          onPress={() => jsonEditor.goFowardHistory()}
        />
      </HistoryControls>

      <If condition={!isEditMode}>
        <SearchWrapper>
          <SearchBlock>
            {searchPaths.map(({ name, searchPath }, i, { length }) => (
              <FlexDiv key={searchPath}>
                <PropertyBlock
                  onClick={() => jsonEditor.search(searchPath)}
                  isSelected={i + 1 === length}
                >
                  {name}
                </PropertyBlock>
                <If condition={!(i + 1 === length)}>
                  <ArrowOpen height={15} />
                </If>
              </FlexDiv>
            ))}
          </SearchBlock>
          <Edit
            width={15}
            onPress={() => {
              setEditMode(true);
            }}
          />
        </SearchWrapper>
      </If>

      <If condition={isEditMode}>
        <SearchInputWrapper>
          <SearchInput
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <Finish
            width={15}
            onPress={() => {
              jsonEditor.search(input);
              setEditMode(false);
            }}
          />
        </SearchInputWrapper>
      </If>
    </Content>
  );
};

export default observer(JsonEditorMenuBar);
