import { Amplify } from "aws-amplify";
import { signOut, getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import {
  REACT_APP_COGNITO_POOL_CLIENT_ID,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_DOMAIN,
} from "~/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";

class AmplifyAuth {
  constructor() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: REACT_APP_COGNITO_POOL_ID || "",
          userPoolClientId: REACT_APP_COGNITO_POOL_CLIENT_ID || "",
        },
      },
    });
    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ domain: REACT_APP_DOMAIN, secure: true }));
  }

  // logged
  verifyUser = async (): Promise<boolean> => {
    try {
      const user = await getCurrentUser();
      return true;
    } catch (error) {
      return false;
    }
  };

  getUsername = async (): Promise<User.UserInfOutput> => {
    const { username } = await getCurrentUser();

    return { username };
  };

  logout = async (): Promise<boolean> => {
    try {
      await signOut({ global: true });
      return true;
    } catch (error) {
      return false;
    }
  };

  getToken = async (): Promise<string> => {
    try {
      const session = await fetchAuthSession();   // Fetch the authentication session
      return session.tokens?.accessToken.toString() || '';
    }
    catch (e) { return '' }
  };
}

export default AmplifyAuth;
