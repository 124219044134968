/* eslint-disable no-param-reassign */
import { action, computed, makeObservable, observable } from "mobx";
import Root from "./index";

export default class JsonEditorStore {
  @observable
  jsonObject: JsonEditor.DynamicJson = {};

  @observable
  jsonSearch: string = "$";

  @observable
  history: string[] = [];

  @observable
  fowardHistory: string[] = [];

  root?: typeof Root;

  constructor(root?: typeof Root) {
    this.root = root;
    makeObservable(this);
  }

  @computed
  get hasBackHistory(): boolean {
    return this.history.length !== 0;
  }

  @computed
  get hasFowardHistory(): boolean {
    return this.fowardHistory.length !== 0;
  }

  @action
  search = (path: string): void => {
    if (path !== this.jsonSearch) {
      this.history.push(this.jsonSearch);
      this.jsonSearch = path;
      this.fowardHistory = [];
    }
  };

  @action
  goBackHistory = (skipFoward = false): void => {
    if (this.history.length) {
      if (!skipFoward) {
        this.fowardHistory.push(this.jsonSearch);
      }

      const lastPath = this.history.pop() as string;
      this.jsonSearch = lastPath;
    }
  };

  @action
  goFowardHistory = () => {
    if (this.fowardHistory.length) {
      this.history.push(this.jsonSearch);
      const lastPath = this.fowardHistory.pop() as string;
      this.jsonSearch = lastPath;
    }
  };

  @action
  addProperty = (currentJson: JsonEditor.DynamicJson) => {
    if (typeof currentJson.length === "number") {
      /*
        TODO: quando e adicionado um novo item no array o frontend nao esta atualizada, precisa ir para outra propriedade e depois voltar.
      */
      currentJson.push(
        JSON.parse(JSON.stringify(currentJson[currentJson.length - 1] || {}))
      );
    } else {
      currentJson[`novaPropriedade${Object.keys(currentJson).length}`] = {};
    }
  };

  @action
  removeProperty = (
    currentJson: JsonEditor.DynamicJson,
    key: string,
    isArray = false
  ) => {
    if (isArray) {
      currentJson.splice(key, 1);
    } else {
      delete currentJson[key];
    }
  };

  @action
  renameProperty = (
    currentJson: JsonEditor.DynamicJson,
    oldKey: string,
    newKey: string
  ) => {
    if (currentJson[newKey]) {
      throw new Error("Propriedade já existe");
    }

    const temp = currentJson[oldKey];
    delete currentJson[oldKey];
    currentJson[newKey] = temp;
  };

  updateHistoryMap = (curPath: string, oldPath: string, newPath: string) => {
    if (curPath.includes(oldPath)) {
      return curPath.replace(oldPath, newPath);
    }
    return curPath;
  };

  removeHistoryFilter = (curPath: string, path: string) => {
    if (curPath.startsWith(path)) {
      return false;
    }
    return true;
  };

  @action
  updateHistory = (oldPath: string, newPath: string) => {
    this.history = this.history.map((x) =>
      this.updateHistoryMap(x, oldPath, newPath)
    );
    this.fowardHistory = this.fowardHistory.map((x) =>
      this.updateHistoryMap(x, oldPath, newPath)
    );
  };

  @action
  removeHistory = (path: string) => {
    this.history = this.history.filter((curPath) =>
      this.removeHistoryFilter(curPath, path)
    );
    this.fowardHistory = this.fowardHistory.filter((curPath) =>
      this.removeHistoryFilter(curPath, path)
    );
  };

  @action
  setPropertyValue = (
    currentJson: JsonEditor.DynamicJson,
    key: string,
    value: any
  ) => {
    currentJson[key] = value;
  };

  @action
  setJsonObject = (jsonObject: JsonEditor.DynamicJson) => {
    this.jsonObject = jsonObject;
  };

  getJsonByPath = (path: string) => {
    const paths = path.split(".");

    paths.splice(0, 1);

    let currentJson = this.jsonObject;

    paths.forEach((currentPath) => {
      currentJson = currentJson[currentPath];
    });
    return currentJson;
  };
}
