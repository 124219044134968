import React, { FC } from "react";

type Props = {
  done?: boolean;
  onPress: () => void;
  width?: number;
};

const Icon: FC<Props> = ({ width = 45, done, onPress }) => (
  <svg
    onClick={onPress}
    style={{ cursor: "pointer" }}
    width={width}
    height={width}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35 17.5C35 7.84737 27.1526 0 17.5 0C7.84737 0 0 7.84737 0 17.5C0 27.1526 7.84737 35 17.5 35C27.1526 35 35 27.1526 35 17.5ZM16.3211 23.5421L9.57895 16.7632C9.24743 16.4316 9.09992 16.0264 9.09992 15.5841C9.09992 15.1421 9.24718 14.7368 9.57895 14.405C10.2052 13.7787 11.2738 13.7787 11.9001 14.405L17.4999 20.0421L23.0999 14.442C23.7262 13.8158 24.7948 13.8158 25.4211 14.442C26.0473 15.0683 26.0473 16.1369 25.4211 16.7632L18.6789 23.5421C18.3842 23.8369 17.9422 24.0211 17.4999 24.0211C17.0578 24.0211 16.6526 23.8369 16.3208 23.5421H16.3211Z"
      fill="#FD784E"
      fillOpacity={done ? 1 : 0.5}
    />
  </svg>
);

export default Icon;
