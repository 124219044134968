import { getTheme, ifStyle, pxToRem, styled } from "~/utils";
import TextField from "../TextField";
import TextFieldVariable from "../TextFieldVariable";

type Props = {
  color?: string;
};

// Colors
const info = getTheme("info");
const light = getTheme("colors.light");
const secondary = getTheme("colors.secondary");
const contrast = getTheme("colors.contrast");
const grayLight = getTheme("colors.grayLight");

// Spacings
const smallSpacing = getTheme("smallSpacing");
const minimalSpacing = getTheme("minimalSpacing");

// Ifs
const isDisabed = ifStyle("disabled");

export const Content = styled.div`
  max-width: 99%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: ${minimalSpacing};
`;

export const FieldName = styled(TextField)`
  width: 68%;
  background-color: ${secondary};
  color: ${light};
  font-size: ${pxToRem(20)};
`;

export const ButtonCaseTest = styled.button`
  border: 0;
  background-color: #c3e2ff;
  width: ${pxToRem(250)};
  height: ${pxToRem(50)};
  font-size: 0.9rem;
  font-weight: 500;
  color: ${secondary};
  margin-top: ${pxToRem(30)};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
`;

export const MethodText = styled.h4`
  text-align: center;
  width: 100%;
  font-weight: 400;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SectionURL = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${minimalSpacing};
  border-bottom: 1px solid ${grayLight}50;
  padding-bottom: ${smallSpacing};
`;

export const CardMethod = styled.button<Props>`
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${pxToRem(90)};
  height: ${pxToRem(53)};
  font-size: 0.7rem;
  background-color: ${(props) => props.color};
  padding: ${minimalSpacing};
  color: ${light};
  cursor: pointer;
  border: 1px solid ${contrast}50;
`;

export const FieldURL = styled(TextFieldVariable)`
  border-radius: 0px;
  color: ${light};
  width: 75%;
`;

type ProsButton = {
  disabled?: boolean;
};

export const Button = styled.button<ProsButton>`
  border: 0;
  width: 15%;
  height: ${pxToRem(51)};
  border: 2px solid ${info};
  background-color: ${secondary};
  color: ${info};
  font-size: 0.9rem;
  margin-left: 30px;
  opacity: ${isDisabed(0.5)};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${info}20;
    border: 2px solid transparent;
    color: ${light};
    transition: 0.3s;
  }
`;
