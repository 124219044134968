import { action, makeObservable, observable, runInAction } from "mobx";
import Root from "./index";

export default class Store {
  @observable
  data = {};

  root: typeof Root;

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);
  }

  @action
  onShowData = (dt: any): void => {
    runInAction(() => {
      this.data = dt;
    });
  };
}
