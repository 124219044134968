import React, { FC } from "react";
import FadeIn from "react-fade-in";
import { useForm } from "react-hook-form";
import { Edit } from "~/assets/svg";
import {
  observer,
  parsedPathParamsToArray,
  parsedURLToPathParams,
  useStores,
} from "~/utils";
import { Action, CardParam, Content, Field, SectionParams } from "./styles";

type Props = {
  id: number;
  URL: string;
  pathParam: Record<string, string>;
};

const PathParamMethod: FC<Props> = ({ id, URL, pathParam }) => {
  const { formulation, app } = useStores();

  const {
    register,
    formState: { errors },
  } = useForm<Formulation.PathParam>();

  const onChangeValuePathParam = ({ name, value }: Formulation.Body) => {
    formulation.onChangeMethodsData({
      id: formulation.idMethodSelected,
      path_param: parsedURLToPathParams(URL, { ...pathParam, [name]: value }),
    });
  };

  const onEditRulesParams = (data: Formulation.ParameterInEditionParams) => {
    formulation.onSetIDMethodSelected(id);
    formulation.onSetParameterInEdition(data);
    app.onModalRules("Path_Param");
  };

  return (
    <FadeIn delay={200} transitionDuration={500}>
      <Content>
        <SectionParams>
          {parsedPathParamsToArray(pathParam)?.map(({ name, value }) => (
            <CardParam key={name}>
              <Field
                {...register("name", { required: true })}
                label="Nome do Parâmetro"
                value={name}
                disabled
                onChange={(event) => {
                  onChangeValuePathParam({
                    name,
                    value: event.target.value,
                  });
                }}
              />

              <Field
                enableVariables
                label="Valor do Parâmetro"
                value={value}
                onChange={(event) =>
                  onChangeValuePathParam({
                    name,
                    value: event.target.value,
                  })
                }
                maxLength={250}
                required
                error={!!errors.value?.message || !!errors.value?.type}
              />
              <Action>
                <Edit
                  data-tooltip-id="tooltip-general"
                  data-tooltip-content="Editar"
                  onPress={() =>
                    onEditRulesParams({
                      name,
                    })
                  }
                />
              </Action>
            </CardParam>
          ))}
        </SectionParams>
      </Content>
    </FadeIn>
  );
};

export default observer(PathParamMethod);
