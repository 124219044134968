const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;

export const sizes = {
  xs: "320px",
  tablet: "768px",
  sm: "480px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  xxl: "1920px",
  desktop: "1280px",
};

export const breakpoints = {
  inMobile: `(max-width: ${sizes.tablet})`,
  inLargeMobile: `only screen and (min-width: ${sizes.sm}) and (max-width: ${sizes.md})`,
  inTablet: `only screen and (min-width: ${sizes.md}) and (max-width: ${sizes.lg})`,
  inLargeTablet: `only screen and (min-width: ${sizes.lg}) and (max-width: ${sizes.xl})`,
  inDesktop: `only screen and (min-width: ${sizes.lg}) and (max-width: ${sizes.xxl})`,
  inMobileAndTablet: `only screen and (max-width: ${sizes.desktop})`,
  inSmallMobile: `only screen and (min-width: ${sizes.xs}) and (max-width: ${sizes.md})`,
  inExtraSmallMobile: `only screen and (max-width: ${sizes.xs})`,
};

const toInt = (value: string): number => value.replace(/\D/g, "") as unknown as number;

export const device = {
  isMobile: toInt(sizes.tablet) >= screenWidth,
  isTablet: toInt(sizes.tablet) > screenWidth && toInt(sizes.desktop) < screenWidth,
  isDesktop: toInt(sizes.desktop) >= screenWidth,
};

export { screenHeight, screenWidth };
