import React, { FC } from "react";

type Props = {
  onPress?: () => void;
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
  isDisabled?: boolean;
};

const Icon: FC<Props> = ({
  width = 15,
  height = 45,
  onPress,
  color = "#FD784E",
  style = { cursor: "pointer" },
  isDisabled = false,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 45"
    fill="none"
    opacity={isDisabled ? 0.5 : 1}
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    onClick={() => !isDisabled && onPress && onPress()}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4536 20.5465L4.78298 0.808356C3.63794 -0.269451 1.88635 -0.269451 0.808355 0.808356C-0.269452 1.88616 -0.269452 3.70499 0.808355 4.78298L18.5254 22.5L0.808355 40.217C-0.269452 41.3621 -0.269452 43.1136 0.808355 44.1916C1.88616 45.2695 3.63768 45.2695 4.78298 44.1916L24.4536 24.521C25.5314 23.376 25.5314 21.6244 24.4536 20.5464V20.5465Z"
      fill={color}
    />
  </svg>
);

export default Icon;
