/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import { get } from "lodash";
import { colors } from "~/theme";
import { METHODS } from "~/utils";

export const ifStyle =
  (prop: any) =>
  (truthy: any, falsy: any = null) =>
  (props: { [x: string]: any }): any =>
    props[prop] ? truthy : falsy;

export const getTheme =
  (themeProp: any) =>
  ({ theme }: any): string | number | null => {
    return get(theme, themeProp) || null;
  };

export const pxToRem = (pixels: number, baseline = 16): string =>
  `${pixels / baseline}rem`;

export const colorByMethod = (method: string): string => {
  switch (method) {
    case METHODS.GET:
      return colors.success;

    case METHODS.POST:
      return colors.info;

    case METHODS.PUT:
      return colors.colors.greenPool;

    case METHODS.DELETE:
      return colors.failure;

    case METHODS.PATCH:
      return colors.colors.purple;

    default:
      return colors.colors.greenPool;
  }
};
