import React, { FC } from "react";
import { Content, Text } from "./styles";

type Props = {
  text?: string | number;
  onPress?: () => void;
  enabled?: boolean;
  disabled?: boolean;
  medium?: boolean;
  reverse?: boolean;
};

const BlockText: FC<Props> = ({
  text = "⠀",
  enabled,
  medium,
  reverse,
  onPress,
  disabled = false,
}) => (
  <Content
    reverse={reverse}
    enabled={enabled}
    medium={medium}
    disabled={disabled}
    onClick={onPress}
  >
    <Text enabled={enabled} reverse={reverse} disabled={disabled}>
      {text}
    </Text>
  </Content>
);

export default BlockText;
