import { getTheme, ifStyle, pxToRem, styled } from "~/utils";

type Props = {
  disabled?: boolean;
  light?: boolean;
  primary?: boolean;
  outline?: boolean;
  borderOutline?: boolean;
  customColor?: string;
};

// Colors

const secondary = getTheme("colors.secondary");
const primary = getTheme("colors.primary");
const light = getTheme("text.light");

// Spacing
const minimalSpacing = getTheme("minimalSpacing");

// Radius
const boxRadius = pxToRem(5);

// ifs
const isDisabled = ifStyle("disabled");

const isLight = ifStyle("light");
const isPrimary = ifStyle("primary");
const isOutline = ifStyle("outline");
const isBorderOutline = ifStyle("borderOutline");

export const Content = styled.button<Props>`
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${pxToRem(5)};
  background-color: ${({ customColor }) =>
    customColor ||
    isOutline(
      "transparent",
      isPrimary(primary, isLight(light, "transparent"))
    )};

  padding: ${minimalSpacing};
  border-radius: ${boxRadius};
  min-width: ${pxToRem(180)};
  height: ${pxToRem(50)};

  border: ${isBorderOutline("2px", "0px")} solid
    ${isOutline(isPrimary(primary, isLight(light, secondary)), "transparent")};

  :hover {
    cursor: ${isDisabled("default", "pointer")};
  }
`;

export const Text = styled.h4<Props>`
  font-size: ${pxToRem(16)};
  color: ${isPrimary(isOutline(primary, light), isOutline(light, secondary))};
  font-weight: 400;
  padding: 0px ${minimalSpacing};
`;
