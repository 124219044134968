import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { makePersistable } from "mobx-persist-store";
import Root from "./index";

export default class VariablesStore {
  @observable
  data: Variables.Data[] = [];

  @observable
  configRequest: Variables.ConfigRequest[] = [];

  root: typeof Root;

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);

    makePersistable(this, {
      name: "Variables",
      properties: ["data", "configRequest"],
      storage: window.localStorage,
    });
  }

  @computed get list() {
    return this.data.map(({ name }) => name);
  }

  @action
  onNewVariable = (data: Variables.Data): void => {
    runInAction(() => {
      this.data.push(data);
    });
  };

  @action
  onRemoveVariable = (uid: string): void => {
    this.data = this.data.filter((variable) => variable.uid !== uid);
    this.onRemoveConfigRequest(uid);
  };

  @action
  onChangeVariable = (data: Partial<Variables.Data>): void => {
    runInAction(() => {
      const index = this.data.findIndex(
        (variable) => variable.uid === data.uid
      );

      if (index !== -1) {
        const { name, type, value } = this.data[index];
        this.data[index] = {
          ...this.data[index],
          name: data?.name ?? name,
          type: data?.type ?? type,
          value: data?.value ?? value,
        };
      }
    });
  };

  // ###############################
  // ####### CONFIG REQUEST ########
  // ###############################

  @action
  onChangeValueConfigRequest = (
    data: Partial<Variables.ConfigRequest>
  ): void => {
    const { uid } = data as Variables.ConfigRequest;

    const copy = data as Variables.ConfigRequest;

    runInAction(() => {
      const index = this.configRequest.findIndex(
        (config) => config?.uid === uid
      );

      if (index !== -1) {
        this.configRequest[index] = {
          ...this.configRequest[index],
          ...copy,
        };
      } else {
        this.configRequest.push(copy);
      }
    });
  };

  @action
  onRemoveConfigRequest = (uid: string): boolean => {
    try {
      this.configRequest = this.configRequest.filter(
        (config) => config?.uid !== uid
      );
      return true;
    } catch (error) {
      return false;
    }
  };
}
