import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Formulation, Home } from "~/scenes";
import Dynamic from "~/scenes/Dynamic";
import RouterProvider from "./provider";
import PrivateRoute from "./private.route";
import { Path } from "./routes.path";
import { REACT_APP_PRIME_SSO_URL } from "~/utils";

const SSO_LOGIN_URL = REACT_APP_PRIME_SSO_URL;

const NavigationApp: FC = () => {
  const LoginRedirect = () => {
    // Configurar página de erro se variável não estiver configurada
    window.location.href = SSO_LOGIN_URL!;
    return null;
  };

  return (
    <Routes>
      <Route path={Path.HOME} element={<PrivateRoute render={<Home />} />} />

      <Route
        path={Path.FORMULATION}
        element={<PrivateRoute render={<Formulation />} />}
      />

      <Route
        path={Path.DYNAMIC}
        element={<PrivateRoute render={<Dynamic />} />}
      />
      <Route path={Path.LOGIN} Component={LoginRedirect} />
      <Route path={Path.SSOHOME} Component={LoginRedirect} />
    </Routes>
  );
};

export { RouterProvider };

export default NavigationApp;
