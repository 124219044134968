import { getTheme, ifStyle, pxToRem, styled } from "~/utils";

type Props = {
  enabled?: boolean;
  medium?: boolean;
  reverse?: boolean;
  disabled?: boolean;
};

// Colors
const backgroundPrimary = getTheme("background.primary");
const backgroundSecondary = getTheme("background.secondary");
const secondary = getTheme("colors.secondary");
const primary = getTheme("colors.primary");
const light = getTheme("text.light");
const contrast = getTheme("colors.contrast");

// Spacing
const minimalSpacing = getTheme("minimalSpacing");

// Radius
const boxRadius = pxToRem(5);

// ifs
const isReverse = ifStyle("reverse");
const isEnabled = ifStyle("enabled");
const isMedium = ifStyle("medium");
const isDisabled = ifStyle("disabled");

// Breakpoints
const inMobile = getTheme("inMobile");

export const Content = styled.button<Props>`
  border: 0;
  background-color: ${isReverse("transparent", isEnabled(backgroundPrimary, backgroundSecondary))};
  padding: ${minimalSpacing};
  margin-right: ${minimalSpacing};
  border-radius: ${boxRadius};
  width: ${isMedium("50%", "auto")};
  height: ${pxToRem(52)};
  margin: ${pxToRem(2)};

  :hover {
    cursor: ${(props) => (props.enabled ? "default" : "pointer")};
    background-color: ${isReverse("transparent", isEnabled(backgroundPrimary, `${backgroundPrimary}95`))};
    transition: ${isEnabled("0.5", "0")}s;
  }

  @media ${inMobile} {
    height: ${pxToRem(40)};
    margin-right: ${pxToRem(5)};
  }
`;

export const Text = styled.h4<Props>`
  font-size: ${pxToRem(15)};
  color: ${light};
  font-weight: 400;

  @media ${inMobile} {
    font-size: ${pxToRem(10)};
  }

  &:hover {
    transition: 0.5s;
    color: ${isDisabled(contrast, isReverse(primary, light))};
  }
`;
