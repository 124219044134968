import React from "react";
import ReactLoading from "react-loading";
import { Content, Text } from "./styles";
import { colors } from "~/theme/colors";

const Loading: React.FC = () => {
  return (
    <Content>
      <Text>Loading</Text>
      <ReactLoading
        type="spin"
        width={20}
        height={20}
        color={colors.colors.light}
      />
    </Content>
  );
};

export default Loading;
