import styled from "styled-components";
import { getTheme, pxToRem } from "~/utils";

const minimalSpacing = getTheme("minimalSpacing");
const textColor = getTheme("colors.secondary");

export const Content = styled.div`
  min-width: ${pxToRem(260)};
  height: 100%;
  min-height: 300px;
  border-right: 1px solid black;
  box-sizing: border-box;
  padding: ${minimalSpacing};
`;

export const PropertyTitle = styled.div`
  min-height: ${pxToRem(30)};
  font-size: 20px;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${pxToRem(200)};
  word-wrap: break-word;
  color: ${textColor};
  font-weight: 500;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
