import { getTheme, pxToRem, styled } from "~/utils";

// Colors
const light = getTheme("colors.light");
const contrast = getTheme("colors.contrast");
const primeblue = getTheme("colors.primeblue")

// Spacings
const minimalSpacing = getTheme("minimalSpacing");
const sceneSpacing = getTheme("sceneSpacing");
const smallSpacing = getTheme("smallSpacing");

// Break
const inDesktop = getTheme("inDesktop");

export const Content = styled.div`
  flex: 1;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: ${sceneSpacing};
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid ${light}50;
  margin-top: ${smallSpacing};
  padding-top: ${smallSpacing};
`;

export const Title = styled.h2`
  color: ${light};
  font-weight: 500;
  font-size: ${pxToRem(35)};
  margin: ${minimalSpacing} 0px;
`;

export const Text = styled.h2`
  color: ${light};
  font-weight: 300;
  font-size: ${pxToRem(20)};
  margin: ${minimalSpacing} 0px;
`;

export const SectionCard = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Card = styled.div`
  margin-top: ${sceneSpacing};
  display: flex;
  background-color: ${primeblue};
  width: ${pxToRem(400)};
  height: ${pxToRem(150)};
  border-radius: ${pxToRem(10)};
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    background-color: ${contrast}99;
    transition: 0.3s;
  }
`;

export const Divider = styled.div`
  width: 90%;
  border-top: 1px solid ${light}50;
`;

export const TextCard = styled.h2`
  color: ${light};
  font-weight: 300;
  font-size: ${pxToRem(20)};
  margin: ${minimalSpacing} 0px;
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.h2`
  color: ${light};
  font-weight: 350;
  font-size: ${pxToRem(14)};
  margin-top: ${minimalSpacing};
  text-align: center;
  padding: 0px ${minimalSpacing};
`;
