import { getTheme, pxToRem, styled } from "~/utils";
import TextField from "../../TextField";
import TextFieldVariable from "~/components/TextFieldVariable";

// Colors
const secondary = getTheme("colors.secondary");
const contrast = getTheme("colors.contrast");
const grayLight = getTheme("colors.grayLight");
const disabled = getTheme("colors.disabled");

// Spacings
const smallSpacing = getTheme("smallSpacing");
const minimalSpacing = getTheme("minimalSpacing");

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export const SectionParams = styled.div`
  max-width: 100%;
  padding-top: ${pxToRem(20)};
  overflow-y: auto;
  scrollbar-color: ${contrast} ${secondary};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionFields = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${minimalSpacing};
`;

export const Field = styled(TextField)`
  width: 40%;
`;

export const FieldVariables = styled(TextFieldVariable)`
  width: 40%;
  margin: 0px 1rem;
`;

export const CardParam = styled.div`
  width: 95%;
  height: ${pxToRem(70)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${pxToRem(5)};
  padding: ${smallSpacing} ${minimalSpacing};
  border: 1px solid ${contrast};
  margin-bottom: ${minimalSpacing};
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${minimalSpacing};
`;
