import React, { FC } from "react";

type Props = {
  size?: number;
  color?: string;
};

const Icon: FC<Props> = ({ size = 35, color = "#F0F8FF" }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 256 256">
    <path d="M224,144v64a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V144a8,8,0,0,1,16,0v56H208V144a8,8,0,0,1,16,0ZM93.66,77.66,120,51.31V144a8,8,0,0,0,16,0V51.31l26.34,26.35a8,8,0,0,0,11.32-11.32l-40-40a8,8,0,0,0-11.32,0l-40,40A8,8,0,0,0,93.66,77.66Z"></path>
  </svg>
);

export default Icon;
