import React, { FC } from "react";

type Props = {
  onPress: () => void;
  size?: number;
  color?: string;
};

const Icon: FC<Props> = ({ size = 45, color = "#5DADE2", onPress }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 256 256"
    style={{ cursor: "pointer" }}
    onClick={onPress}
  >
    <path d="M243.28,68.24l-24-23.56a16,16,0,0,0-22.58,0L104,136h0l-.11-.11L67.25,100.62a16,16,0,0,0-22.57.06l-24,24a16,16,0,0,0,0,22.61l71.62,72a16,16,0,0,0,22.63,0L243.33,90.91A16,16,0,0,0,243.28,68.24ZM103.62,208,32,136l24-24,.11.11,36.64,35.27a16,16,0,0,0,22.52,0L208.06,56,232,79.6Z" />
  </svg>
);

export default Icon;
