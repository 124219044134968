import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Header } from "~/components";
import { Path } from "~/routes/routes.path";
import { observer, useStores } from "~/utils";
import { Content, TextArea, WhiteCard } from "./dynamic.styles";

const Dynamic: FC = () => {
  const { formulation, routing } = useStores();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Formulation.Dynamic>();

  const onChange = async (event: any) => {
    const { value } = event.target;

    if (!value) {
      setError("dynamic", { message: "error" });
      toast.error("Preencha o campo corretamente");
      return;
    }

    setError("dynamic", { message: "" });
  };

  const onSubmit = handleSubmit(async (values: Formulation.Dynamic) => {
    try {
      if (!values.dynamic) {
        toast.error("Preencha o campo corretamente");
        return;
      }

      if (values.dynamic.toLowerCase().includes("curl")) {
        const finished = await formulation.onSendCurl(values.dynamic);
        if (finished) routing.push(Path.FORMULATION);
        return;
      }

      const isValid = JSON.parse(values.dynamic);

      if (!isValid) {
        toast.error("Preencha o campo corretamente");
        return;
      }

      const finished = await formulation.onSendContentFile(values.dynamic);
      if (finished) routing.push(Path.FORMULATION);
    } catch (error: any) {
      toast.error("JSON Inválido");
    }
  });

  return (
    <>
      <Header hideNewMethod processingText="Processar" onProcess={onSubmit} />
      <Content>
        <WhiteCard>
          <TextArea
            {...register("dynamic", { required: true })}
            required
            name="dynamic"
            placeholder="Formatos suportados: cURL, Open API, Swagger e Postman"
            onChange={onChange}
            error={!!errors.dynamic?.message || !!errors.dynamic?.type}
          />
        </WhiteCard>
      </Content>
    </>
  );
};

export default observer(Dynamic);
