import { styled } from "~/utils";

export const Content = styled.div`
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
`;

export const BodyPage = styled.div`
  width: 100%;
  height: 100%;
`;

export const Children = styled.div`
  width: 100%;
  max-height: 100%;
`;
