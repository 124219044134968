import { action, makeObservable, observable, runInAction } from "mobx";
import Root from "./index";
import { makePersistable } from "mobx-persist-store";

export default class Store {
  @observable
  data = {};

  @observable
  type: Record<string, string> = {
    "": "",
  };

  root: typeof Root;

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);
    makePersistable(this, {
      name: "Body",
      properties: ["data", "type"],
      storage: window.localStorage,
    });
  }

  @action
  onSetType = (type: Record<string, string>): void => {
    runInAction(() => {
      this.type = {
        ...this.type,
        ...type,
      };
    });
  };

  @action
  onShowData = (dt: any): void => {
    runInAction(() => {
      this.data = dt;
    });
  };
}
