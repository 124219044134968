import React, { FC, useCallback, useState } from "react";
import { first, isEmpty } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import { useDropzone } from "react-dropzone";
import If from "~/components/If";
import TabFormData from "~/components/Tabs/FormData";
import { jsonIsValid, observer, useStores } from "~/utils";
import { colors } from "~/theme/colors";
import { UploadIcon } from "~/assets/svg";
import { Content, SelectStyled, TextArea, Card, Divider, Paragraph, TextCard } from "./styles";

const OPTIONS = ["Sem Body", "Form-data", "JSON", "Binary"];

type Props = {
  id: number;
  body: Formulation.Body[];
  formData: Formulation.FormData[];
};

const Body: FC<Props> = ({ id, formData = [] }) => {
  const { formulation, body } = useStores();
  const [loading, setLoading] = useState(false);

  const {
    setError,
    formState: { errors },
  } = useForm<{ json: string }>();

  const onChangeJSON = async (event: any) => {
    const { value } = event.target;

    formulation.onChangeMethodsData({
      id: formulation.idMethodSelected,
      bodyJson: value,
    });

    if (!jsonIsValid(value)) {
      setError("json", { message: "error" });
      return;
    }

    setError("json", { message: "" });
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setLoading(true);
    if (!acceptedFiles.length) return;

    const file = first(acceptedFiles);

    const reader = new FileReader();

    reader.onloadend = () => {
      try {
        if (!JSON.parse(reader.result as string)) return;
      } catch (error) {
        toast("Arquivo JSON inválido, revise o arquivo e tente novamente.", {
          position: "top-center",
          icon: "⚠️",
        });
      } finally {
        setLoading(false);
      }
    };

    reader.readAsText(file!);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Content>
      <SelectStyled
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={body.type[id] || "Sem Body"}
        onChange={({ target }) =>
          body.onSetType({
            [id]: target.value as string,
          })
        }
      >
        {OPTIONS.map((option) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </SelectStyled>

      <If condition={body.type[id] === "Form-data"}>
        <TabFormData data={formData} id={id} />
      </If>

      <If condition={body.type[id] === "JSON"}>
        <TextArea
          required
          rows={15}
          placeholder="Insira o JSON"
          onChange={onChangeJSON}
          error={!!errors.json?.message || !!errors.json?.type}
          value={formulation.onGetMethod(formulation.idMethodSelected)?.bodyJson as any}
        />
      </If>

      <If condition={body.type[id] === "Binary"}>
        <Card {...getRootProps()}>
          <If condition={loading}>
            <ReactLoading type="spinningBubbles" width={60} height={60} color={colors.colors.primary} />
          </If>

          <If condition={!loading}>
            <TextCard>
              <UploadIcon /> &nbsp; Importar Arquivo
            </TextCard>

            <Divider />
            <Paragraph>Selecione um arquivo para ser importado a partir do seu computador.</Paragraph>
          </If>

          <input {...getInputProps()} accept=".json" />
        </Card>
      </If>
    </Content>
  );
};

export default observer(Body);
