import React, { FC, useEffect, useState } from "react";
import { Amplify } from "~/services";
import { Path } from "./routes.path";
import { Loading } from "~/components";
import { useStores } from "~/utils";

type Props = {
  render: React.ReactElement;
};

const amplify = new Amplify();

const PrivateRoute: FC<Props> = ({ render }) => {
  const { routing } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyAuthSession = async () => {
      const verify = await amplify.verifyUser();
      if (verify) {
        setIsLoading(false);
      } else {
        routing.replace(Path.LOGIN);
      }
    };

    verifyAuthSession();
  }, []);

  return isLoading ? <Loading /> : render;
};

export default PrivateRoute;
