export const VIEWS = {
  HEADER: "header",
  QUERY: "query",
  BODY: "body",
  PATH_PARAM: "path_param",
  FORM_DATA: "form_data",
};

export const RULE_REF = {
  QUERY: "Query",
  BODY: "Body",
  HEADER: "Header",
  PATH_PARAM: "Path_Param",
  FORM_DATA: "Form_Data",
};

export const TYPE_VARIABLES = ["Constante", "Request"];

export const REGEX_VARIABLE = /\$([\w\-_]+)/g;

export const MENU = {
  HOME: "HOME",
  REQUEST: "REQUEST",
  GLOBAL: "GLOBAL",
  HELP: "HELP",
  SETTINGS: "SETTINGS",
};

export const STATUS_CODE = [
  "100",
  "101",
  "102",
  "103",
  "200",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "226",
  "300",
  "301",
  "302",
  "303",
  "304",
  "305",
  "307",
  "308",
  "400",
  "401",
  "402",
  "403",
  "404",
  "405",
  "406",
  "407",
  "408",
  "409",
  "410",
  "411",
  "412",
  "413",
  "414",
  "415",
  "416",
  "417",
  "418",
  "421",
  "422",
  "423",
  "424",
  "425",
  "426",
  "428",
  "429",
  "431",
  "451",
  "500",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
  "508",
  "510",
  "511",
];

export const TEST_TYPE = ["Status Code", "Response Body", "Response Header"];
