import React, { FC, useState } from "react";
import FadeIn from "react-fade-in";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Add, Edit } from "~/assets/svg";
import { observer, useStores } from "~/utils";
import ConfirmDelete from "../ConfirmDelete";
import { Action, CardParam, Content, Field, SectionFields, SectionParams } from "./styles";

const TYPE = {
  TEXT: "string",
  NUMBER: "inteiro",
};

type Props = {
  id: number;
  data: Formulation.FormData[];
};

const FormDataMethod: FC<Props> = ({ id, data = [] }) => {
  const { formulation, app } = useStores();
  const [field, setField] = useState({
    name: "",
    value: "",
  });

  const {
    register,
    setError,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Formulation.Query>();

  const onEditRules = (data: Formulation.ParameterInEditionParams) => {
    formulation.onSetIDMethodSelected(id);
    formulation.onSetParameterInEdition(data);
    app.onModalRules("Form_Data");
  };

  const clearFields = () => {
    reset({
      name: "",
      value: "",
    });

    setField({
      name: "",
      value: "",
    });
  };

  const onChange = (event: any) => {
    const { value, name } = event.target;
    setField({ ...field, [name]: value });
    setValue(name, value);
  };

  const onSubmit = handleSubmit(({ name, value }: Formulation.Query) => {
    if (!name) {
      setError("name", { type: "required", message: "Campo obrigatório" });
      return;
    }

    if (data.find((b) => b.name === name)) {
      toast("Parâmetro já foi adicionado anteriormente", {
        position: "top-center",
        icon: "⚠️",
      });

      return;
    }

    formulation.onChangeMethodsData({
      id: formulation.idMethodSelected,
      formData: [{ name, value }, ...data],
    });

    clearFields();
  });

  const onChangeValue = ({ name, value }: Formulation.Query) => {
    formulation.onChangeMethodsData({
      id: formulation.idMethodSelected,
      formData: data.map((b) => {
        if (b.name === name) {
          return {
            ...b,
            value,
            type: !Number(value) ? TYPE.TEXT : TYPE.NUMBER,
          };
        }

        return b;
      }),
    });
  };

  const onRemoveParameter = ({ name }: Formulation.FormData) => {
    formulation.onRemoveFormDataField({ name, id: 0 });
  };

  return (
    <FadeIn delay={200} transitionDuration={500}>
      <Content>
        <SectionFields>
          <Field
            {...register("name", { required: true })}
            required
            error={!!errors.name?.message || !!errors.name?.type}
            label="Nome do Parâmetro"
            onChange={onChange}
            value={field.name}
          />

          <Field
            enableVariables
            name="value"
            required
            label="Valor do Parâmetro"
            onChange={onChange}
            value={field.value}
          />

          <Add onPress={onSubmit} />
        </SectionFields>

        <SectionParams>
          {data.map((query) => (
            <CardParam key={query.name}>
              <Field value={query.name} disabled />

              <Field
                enableVariables
                value={query.value}
                onChange={(event) =>
                  onChangeValue({
                    name: query.name,
                    type: query.type,
                    value: event.target.value,
                  })
                }
              />

              <Action>
                <ConfirmDelete key={JSON.stringify(query)} onDelete={() => onRemoveParameter(query)} />

                <Edit
                  data-tooltip-id="tooltip-general"
                  data-tooltip-content="Editar"
                  onPress={() =>
                    onEditRules({
                      name: query.name,
                    })
                  }
                />
              </Action>
            </CardParam>
          ))}
        </SectionParams>
      </Content>
    </FadeIn>
  );
};

export default observer(FormDataMethod);
