import { OPTIONS_HEADERS_MOCK } from "../enums";

export const parseFilename = (file: string) => {
  const extension = file.split(".").pop();
  const filename = file.split(".").shift();

  const length = filename?.length || 0;

  return `${length > 15 ? `${filename?.substring(0, 15)}[...]` : filename}.${extension}`;
};

export const parsedHeaderToTextArea = (headers: Record<string, string> = {}) => {
  let content = "";

  const all = Object.keys(headers).reverse();

  all.forEach((key) => {
    if (!!key.trim() && !!headers[key]) {
      content += `${key.trim()}: ${headers[key] && headers[key].trim()}\n`;
    }
  });

  return content;
};

export const parseTextAreaToHeader = (value: string): Record<string, string> => {
  const result: Record<string, string> = {};

  const parsedValue = value.trim();

  if (parsedValue) {
    parsedValue.split("\n").forEach((header: any) => {
      const [key, value] = header.split(":");
      result[key?.trim()] = value?.trim();
    });

    return result;
  }

  return {};
};

export const parseHeadersMockToSelectOptions = (data: Record<string, string> = {}): any[] => {
  const obj: any[] = [];

  Object.keys(data).forEach((key) => {
    obj.push({ label: key, value: data[key] });
  });

  const copy = [...obj, ...OPTIONS_HEADERS_MOCK];

  const unique = copy.filter((obj, index, self) => index === self.findIndex((t) => t.label === obj.label));

  return unique;
};

export const formParseHeaders = (headers: Record<string, string>[]) =>
  headers.reduce((acc, curr) => {
    acc[curr.label] = curr.value;
    return acc;
  }, {});

export const onRedirect = (url: string) => window.open(url, "_blank");

export const onGenerateUID = () => Math.random().toString(36).substring(10);

export const parseString = (name = "") =>
  name
    .replaceAll(" ", "_")
    .toLowerCase()
    .replace(/[^a-z0-9_]/g, "");

export const debounce = (fn: (a: any) => any, timeout = 500) => {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn({ ...args });
    }, timeout);
  };
};

export const insertTag = (text: string, exist?: boolean) =>
  exist
    ? `<span style="color: #252948; background: #FD784E; padding: 4px; border-radius: 2px; font-size: 14px;">${text}</span>`
    : `<span style="color: #252948; background: #F1948A; padding: 4px; border-radius: 2px; font-size: 14px;">${text}</span>`;

export const clearTag = (text: string) => text.replaceAll("$", "");

export const jsonIsValid = (data: string) => {
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
};

export const sanitizeJSON = (json = "") => {
  json = json.replace(/,\s*([}\]])/g, "$1");

  try {
    return JSON.parse(json);
  } catch (error) {
    return json || {};
  }
};

export const generateIdCaseTest = (ids = [""]) => {
  const numbers = ids.map((id) => parseInt(id.replace("TC", ""), 10)).sort((a, b) => a - b);

  let nextNumber = 1;
  if (numbers.length > 0) {
    // Pega o maior número e adiciona 1 para o próximo ID
    nextNumber = numbers[numbers.length - 1] + 1;
  }

  return `TC${nextNumber.toString().padStart(4, "0")}`;
};

export const toOnlyNumbers = (value: string): number => {
  return Number(value.replace(/\D/g, ""));
};

export const transformData = (obj: Record<string, any>): Record<string, string> => {
  const transformedObj: Record<string, string> = {};

  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      transformedObj[key] = obj[key]?.name || "";
    } else {
      transformedObj[key] = obj[key];
    }
  }

  return transformedObj;
};
