import { action, makeObservable, observable, runInAction } from "mobx";
import { RULE_REF } from "~/utils";
import Root from "./index";

export default class AppStore {
  @observable
  reference = RULE_REF.BODY;

  @observable
  modalRules = false;

  @observable
  link = "";

  root: typeof Root;

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);
  }

  @action
  onModalRules = (
    reference: "Body" | "Query" | "Header" | "Path_Param" | "Form_Data" | ""
  ): void => {
    this.modalRules = !this.modalRules;
    this.reference = reference;
  };
}
