import { getTheme, pxToRem, styled } from "~/utils";
import TextField from "../../TextField";
import TextFieldVariable from "~/components/TextFieldVariable";

// Colors
const contrast = getTheme("colors.contrast");
const light = getTheme("colors.light");

// Spacings
const smallSpacing = getTheme("smallSpacing");
const minimalSpacing = getTheme("minimalSpacing");

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export const SectionFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${smallSpacing};
`;

export const Field = styled(TextField)`
  width: 40%;
`;

export const FieldVariables = styled(TextFieldVariable)`
  width: 42%;
`;

export const SectionParams = styled.div`
  width: 100%;
  padding-top: ${pxToRem(40)};
  overflow-y: auto;
  max-height: ${pxToRem(500)};
`;

export const CardParam = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${pxToRem(5)};
  padding: ${smallSpacing} ${minimalSpacing};
  border: 1px solid ${contrast};
  margin-bottom: ${minimalSpacing};
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-right: ${minimalSpacing};
`;
