import { getTheme, ifStyle, pxToRem, styled } from "~/utils";

type Props = {
  checkEnabled?: boolean;
  color?: string;
  hide?: boolean;
};

// Colors
const info = getTheme("info");
const light = getTheme("colors.light");
const secondary = getTheme("colors.secondary");
const primary = getTheme("colors.primary");
const contrast = getTheme("colors.contrast");
const grayBg = getTheme("colors.grayBg");

// Spacings
const smallSpacing = getTheme("smallSpacing");
const minimalSpacing = getTheme("minimalSpacing");
const mediumSpacing = getTheme("mediumSpacing");

// Ifs
const isCheckEnabled = ifStyle("checkEnabled");
const isDisabed = ifStyle("disabled");
const isSelected = ifStyle("selected");

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

type PropsContext = {
  selected?: boolean;
};

export const Content = styled.div<PropsContext>`
  z-index: 0;
  width: 99%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${pxToRem(10)};
  margin-bottom: ${smallSpacing};
  background-color: ${contrast};
  cursor: pointer;

  border: 1px solid ${isSelected(primary, secondary)};
`;

export const SectionHeader = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${minimalSpacing};
  background-color: ${grayBg};
  min-height: ${pxToRem(40)};
  border-radius: ${pxToRem(10)} ${pxToRem(10)} 0 0;
`;

type PropsText = {
  disabled?: boolean;
};

export const TextTouchable = styled.button<PropsText>`
  text-align: center;
  color: ${info};
  font-size: 0.9rem;
  cursor: pointer;
  padding: ${minimalSpacing};
  margin-bottom: ${minimalSpacing};
  font-weight: 400;
  display: flex;
  align-items: center;
  opacity: ${isDisabed(0.5)};

  background-color: transparent;
  border: 0;

  z-index: 10;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`;

export const MethodText = styled.h4`
  text-align: center;
  width: 100%;
  font-weight: 400;
`;

export const Collapse = styled.div<Props>`
  padding: ${smallSpacing};
  min-height: ${(props) => (props.hide ? "0" : "intial")};
  transition: height 500ms;
  width: 100%;
  background-color: ${light};
  padding: ${smallSpacing} 5px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SectionDetails = styled.div`
  padding: ${smallSpacing};
`;

export const Name = styled.h4`
  font-size: 1.1rem;
  color: ${light};
  font-weight: 400;
`;

export const Url = styled.h4`
  font-size: 1rem;
  color: ${light};
  font-weight: 300;
  margin: ${minimalSpacing};
`;

export const Check = styled.button<Props>`
  cursor: pointer;
  border: 0;
  background-color: ${isCheckEnabled(primary, "transparent")};
  border-radius: ${pxToRem(5)};
  width: ${pxToRem(28)};
  height: ${pxToRem(28)};
  border: 3px solid ${secondary};
  margin-right: ${smallSpacing};
`;

export const CardMethod = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${pxToRem(70)};
  border-radius: ${pxToRem(5)};
  font-size: 0.8rem;
  background-color: ${(props) => props.color};
  padding: ${minimalSpacing};
  color: ${light};
  cursor: pointer;
`;

export const SectionActions = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SectionButtons = styled.div`
  width: 80%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: ${mediumSpacing};

  border-left-width: 5px;
  border-left-color: ${contrast};
  border-left-style: solid;
  border-radius: ${pxToRem(5)};
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: ${pxToRem(80)};
  background-color: ${(props) => props.color};
`;
