import React, { FC } from "react";
import { Grid, Home, MenuIcon, Phone, PrimeIcon, Settings, World } from "~/assets/svg";
import { Content, Item, Items, Text } from "./styles";
import { MENU } from "~/utils";

type Props = {
  optionEnabled?: keyof typeof MENU;
  onHome: () => void;
  onRequest: () => void;
  onVariables: () => void;
};

const Menu: FC<Props> = ({ optionEnabled = "", onHome, onRequest, onVariables }) => {
  const onPrimeWebsite = () => window.open("https://primecontrol.com.br", "_blank");

  return (
    <Content>
      {/* <MenuIcon onPress={onHome} /> */}

      <Items>
        <Item enabled={optionEnabled === MENU.HOME} onClick={onHome}>
          <Home />
          <Text>Painel Inícial</Text>
        </Item>

        <Item enabled={optionEnabled === MENU.REQUEST} onClick={onRequest}>
          <Grid />
          <Text>Requisições</Text>
        </Item>

        <Item onClick={onVariables}>
          <World />
          <Text>Globais</Text>
        </Item>

        <Item disabled enabled={optionEnabled === MENU.HELP}>
          <Phone />
          <Text>Ajuda</Text>
        </Item>

        <Item disabled enabled={optionEnabled === MENU.SETTINGS}>
          <Settings />
          <Text>Configurações</Text>
        </Item>
      </Items>

      {/* <PrimeIcon size={50} onPress={onPrimeWebsite} /> */}
    </Content>
  );
};

export default Menu;
