import React, { FC } from "react";
import { observer } from "mobx-react";
import { Add } from "~/assets/svg";
import { useStores } from "~/utils/helpers/store.helper";
import JsonEditorPropertyItem from "../JsonEditorPropertyItem";
import { Content, PropertyTitle, Row } from "./styles";

type Props = {
  path: string;
  method: Formulation.Data;
};

const JsonEditorPropertyDetail: FC<Props> = ({ path, method }) => {
  const { jsonEditor } = useStores();

  const currentJson = jsonEditor.getJsonByPath(path);

  let currentKey = path.split(".").slice(-1)[0];
  if (currentKey === "$") {
    currentKey = "root";
  }

  if (typeof currentJson !== "object" || currentJson === null) {
    return null;
  }

  return (
    <Content>
      <Row>
        <PropertyTitle>{currentKey}</PropertyTitle>

        <Add
          width={20}
          style={{ cursor: "pointer" }}
          onPress={() => jsonEditor.addProperty(currentJson)}
        />
      </Row>

      <div>
        {Object.keys(currentJson).map((keyName) => (
          <JsonEditorPropertyItem
            key={keyName}
            path={`${path}.${keyName}`}
            property={keyName}
            object={currentJson[keyName]}
            method={method}
            onClick={jsonEditor.search}
          />
        ))}
      </div>
    </Content>
  );
};

export default observer(JsonEditorPropertyDetail);
