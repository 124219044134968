import React, { FC } from "react";

type Props = {
  size?: number;
  color?: string;
};

const Icon: FC<Props> = ({ size = 35, color = "#F0F8FF" }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 256 256">
    <path d="M208,136H48a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136Zm0,64H48V152H208v48Zm0-160H48A16,16,0,0,0,32,56v48a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V56A16,16,0,0,0,208,40Zm0,64H48V56H208v48ZM192,80a12,12,0,1,1-12-12A12,12,0,0,1,192,80Zm0,96a12,12,0,1,1-12-12A12,12,0,0,1,192,176Z"></path>
  </svg>
);

export default Icon;
