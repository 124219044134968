import React, { FC, useState } from "react";
import { Add, Close } from "~/assets/svg";
import { RULES, observer, useStores } from "~/utils";
import BlockText from "../BlockText";
import {
  Blocks,
  Content,
  Field,
  Header,
  Label,
  Section,
  SectionFields,
  SectionRulesAdded,
  SectionSelect,
  SelectStyled,
  Title,
  AutocompleteStyled,
  MUITextFieldStyled,
} from "./styles";
import If from "../If";
import { colors } from "~/theme";

const ModalAddRules: FC = () => {
  const { formulation, app } = useStores();

  const rules = app.reference === "Header" ? RULES.HEADERS : RULES.DEFAULT;

  const [errors, setErrors] = useState({
    name: false,
    value: false,
    code: false,
  });

  const [selected, setSelected] = useState<Formulation.RulesParams>({
    name: "",
    value: "",
    editable: false,
    code: "",
  });

  const onClearErros = () => {
    setErrors({
      name: false,
      value: false,
      code: false,
    });
  };

  const onCheckErrors = () => {
    setErrors({
      name: !selected.name.trim(),
      value: !selected.value,
      code: !selected.code.trim(),
    });
  };

  const onSubmit = () => {
    onCheckErrors();

    const { name, value, code } = errors;

    if (name || value || code) {
      return;
    }

    formulation.onChangeRulesInMethod(selected);

    setSelected({
      name: "",
      value: "",
      editable: false,
      code: "",
    });
  };

  const onChangeSelectRule = (label: string) => {
    onClearErros();

    const current = rules.find((r) => r.label === label)!;

    if (!current?.value.trim()) onCheckErrors();

    setSelected({
      name: current?.label,
      value: current?.value,
      editable: current?.editable,
      code: "200",
    });
  };

  const onChangeField = (event: any) => {
    const { name, value } = event.target;

    setSelected({ ...selected, [name]: value });

    onCheckErrors();
  };

  return (
    <If condition={app.modalRules}>
      <Content>
        <Section>
          <Header>
            <Title>
              {app.reference} - {formulation.parameterInEdition.name}
            </Title>
            <Close onPress={() => app.onModalRules("")} color={colors.colors.light} />
          </Header>

          <SectionFields>
            <SectionSelect>
              <Label>Nome da regra</Label>
              {/* <SelectStyled
                options={rules.map(({ label }) => ({
                  label,
                  value: label,
                }))}
                placeholder="Selecione uma regra"
                onChange={onChangeSelectRule}
                error={errors.name}
              /> */}

              <AutocompleteStyled
                freeSolo
                disableClearable
                value={selected.name}
                onChangeCapture={(e: any) => onChangeSelectRule(e.target?.value)}
                onChange={(_, value) => {
                  onChangeSelectRule(value as string);
                }}
                options={rules.map(({ label }) => label)}
                renderInput={(params: any) => <MUITextFieldStyled {...params} />}
              />
            </SectionSelect>

            <Field
              enableVariables
              label="Valor da regra"
              name="value"
              value={selected.value}
              onChange={onChangeField}
              error={errors.value}
              disabled={!selected.editable}
              maxLength={25}
              small
            />

            <Field
              label="Status code esperado"
              placeholder="200"
              value={selected.code}
              name="code"
              onChange={onChangeField}
              error={errors.code}
            />
            <Add onPress={onSubmit} />
          </SectionFields>

          <Blocks>
            {formulation.parsedRules.map(({ code, name, value }: Formulation.RulesParams) => (
              <SectionRulesAdded key={`${name}-${value}`}>
                <BlockText medium enabled key={name} text={name} />
                <BlockText medium key={value} text={value} reverse />
                <BlockText medium key={code} text={code} reverse />

                <Close
                  color={colors.colors.light}
                  width={50}
                  onPress={() =>
                    formulation.onRemoveRulesInParameter({
                      parameter: formulation.parameterInEdition.name!,
                      rule: name,
                      value,
                    })
                  }
                />
              </SectionRulesAdded>
            ))}
          </Blocks>
        </Section>
      </Content>
    </If>
  );
};

export default observer(ModalAddRules);
