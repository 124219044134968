import React, { FC } from "react";

type Props = {
  onMouseOut?: () => void;
  onMouseOver?: () => void;
  size?: number;
  tooltip?: string;
  color?: string;
};

const Icon: FC<Props> = ({ size = 25, color = "#000", onMouseOut, onMouseOver, tooltip }) => (
  <svg
    data-tooltip-id="tooltip-icons"
    data-tooltip-content={tooltip}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 256 256"
    onMouseOut={onMouseOut}
    onMouseOver={onMouseOver}
  >
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z" />
  </svg>
);

export default Icon;
