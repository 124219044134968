import styled from "styled-components";
import { pxToRem } from "~/utils";

export const Content = styled.section`
  position: fixed;
  bottom: ${pxToRem(10)};
  right: ${pxToRem(10)};
`;

export const Text = styled.h4`
  font-weight: 300;
  color: white;
  font-size: 0.8rem;
`;
