import React, { FC, useState } from "react";
import toast from "react-hot-toast";
import { Add, Close, Edit } from "~/assets/svg";
import { TYPE_VARIABLES, observer, onGenerateUID, parseString, useStores } from "~/utils";
import ConfirmDelete from "../ConfirmDelete";
import If from "../If";
import Typograph from "../Typograph";
import VariablesConfigRequest from "../VariablesConfigRequest";
import {
  Blocks,
  Content,
  Field,
  Header,
  Label,
  Section,
  SectionFields,
  SectionSelect,
  SectionTypeRequest,
  SectionsAdded,
  SelectStyled,
  Text,
  AutocompleteStyled,
  MUITextFieldStyled,
  Title,
} from "./styles";
import { colors } from "~/theme";

type Props = {
  visible?: boolean;
  onClose?: () => void;
};

const Variables: FC<Props> = ({ visible = false, onClose = () => {} }) => {
  const { variables } = useStores();

  const [form, setForm] = useState({
    uid: "",
    name: "",
    type: "",
    value: "",
  });

  const [configRequest, setConfigRequest] = useState(false);

  const [selected, setSelected] = useState<Variables.Data>({
    uid: "",
    name: "",
    value: "",
    type: "",
  });

  const onCloseModal = () => {
    setConfigRequest(false);
    onClose();
  };

  const onConfigRequestAdded = (uid = "") => {
    setConfigRequest((old) => !old);

    const item = variables.data.find((v) => v.uid === uid);

    if (item?.name) {
      setSelected(item);
    }
  };

  const onConfigRequestForm = () => {
    setConfigRequest(!configRequest);
    setSelected(form);
  };

  const onRemove = (uid: string) => {
    variables.onRemoveVariable(uid);
  };

  const onSubmit = () => {
    if (!form.name || !form.type || (form.type === "Request" ? false : !form.value)) {
      toast("Preencha todos os campos.", {
        icon: "🔥",
        position: "bottom-center",
      });
      return;
    }

    const exists = variables.data?.find((variable) => variable.name === form.name);

    if (exists) {
      toast("Uma variável com este nome já existe.", { icon: "🔥" });
      return;
    }

    variables.onNewVariable(form);

    setForm({
      uid: "",
      name: "",
      value: "",
      type: "",
    });
  };

  const onChange = (event: any) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const onChangeVariableAdded = ({ name, type, uid, value }: Partial<Variables.Data>) => {
    variables.onChangeVariable({
      name: parseString(name),
      type,
      uid,
      value,
    });
  };

  return (
    <If condition={visible}>
      <Content>
        <Section>
          <If condition={!configRequest}>
            <Header>
              <Title
                iconText="faq"
                textDescribe={`
                   Cadastre variáveis globais que podem ser utilizadas em diversos campos das requisições e posteriormente no projeto de automação gerado.
                   Variável constante: variável com valor estático, seu valor sempre será o mesmo.
                   Variável de request: variável que terá seu valor associado a partir de uma chave do corpo da reposta da requisição indicada.
              `}
              >
                Variáveis Globais
              </Title>
              <Close onPress={onClose} color={colors.colors.light} />
            </Header>

            <SectionFields>
              <Field
                label="Nome"
                name="name"
                value={form.name}
                onChange={(event: any) => {
                  setForm({
                    ...form,
                    name: parseString(event.target?.value),
                  });

                  return event;
                }}
              />

              <SectionSelect>
                <Label>Tipo</Label>

                <AutocompleteStyled
                  freeSolo
                  disableClearable
                  value={form.type}
                  onChangeCapture={(event: any) => {
                    const { value } = event.target;

                    setForm({
                      ...form,
                      uid: onGenerateUID(),
                      type: value as string,
                    });
                  }}
                  onChange={(_, value) => {
                    setForm({
                      ...form,
                      uid: onGenerateUID(),
                      type: value as string,
                    });
                  }}
                  options={TYPE_VARIABLES}
                  renderInput={(params: any) => <MUITextFieldStyled {...params} />}
                />
              </SectionSelect>

              <If condition={form.type !== "Request"}>
                <Field enableVariables small label="Valor" value={form.value} name="value" onChange={onChange} />
              </If>

              <If condition={form.type === "Request"}>
                <SectionTypeRequest onClick={onConfigRequestForm}>
                  <Text>Configurar Request</Text>

                  <Edit width={20} />
                </SectionTypeRequest>
              </If>

              <Add onPress={onSubmit} style={{ marginTop: 25, cursor: "pointer" }} />
            </SectionFields>

            <SectionsAdded>
              {variables.data
                ?.slice()
                .reverse()
                .map((param) => (
                  <Blocks>
                    <SectionFields>
                      <Field
                        name="name"
                        value={param.name}
                        onChange={(event: any) => {
                          onChangeVariableAdded({
                            ...param,
                            name: event.target?.value,
                          });
                          return event;
                        }}
                      />

                      <SectionSelect>
                        {/* <SelectStyled
                          placeholder=""
                          value={{ label: param.type, value: param.type }}
                          options={TYPE_VARIABLES.map((label) => ({
                            label,
                            value: label,
                          }))}
                          onChange={(event: any) => {
                            onChangeVariableAdded({
                              ...param,
                              type: event.value,
                            });
                            return event;
                          }}
                        /> */}

                        <AutocompleteStyled
                          freeSolo
                          disableClearable
                          value={param.type}
                          onChangeCapture={(event: any) => {
                            const { value } = event.target;

                            onChangeVariableAdded({
                              ...param,
                              type: value,
                            });
                          }}
                          onChange={(_, value) => {
                            onChangeVariableAdded({
                              ...param,
                              type: value as string,
                            });
                          }}
                          options={TYPE_VARIABLES}
                          renderInput={(params: any) => <MUITextFieldStyled {...params} />}
                        />
                      </SectionSelect>

                      <If condition={param.type !== "Request"}>
                        <Field
                          enableVariables
                          small
                          placeholder=""
                          value={param.value}
                          onChange={(event: any) => {
                            onChangeVariableAdded({
                              ...param,
                              value: event.target?.value,
                            });
                            return event;
                          }}
                        />
                      </If>

                      <If condition={param.type === "Request"}>
                        <SectionTypeRequest onClick={() => onConfigRequestAdded(param.uid)}>
                          <Text>Configurar Request</Text>

                          <Edit width={20} />
                        </SectionTypeRequest>
                      </If>

                      <ConfirmDelete key="delete" onDelete={() => onRemove(param.uid)} />
                    </SectionFields>
                  </Blocks>
                ))}
            </SectionsAdded>
          </If>

          <If condition={configRequest}>
            <VariablesConfigRequest
              uid={selected.uid}
              name={selected.name}
              goBack={() => setConfigRequest(!configRequest)}
              onClose={onCloseModal}
            />
          </If>
        </Section>
      </Content>
    </If>
  );
};

export default observer(Variables);
